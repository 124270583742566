export const LOCAL_STORAGE = {
  AUTH_TOKEN: 'auth-token',
  HAS_CONNECTED_PREV: 'has-connected-prev',
  HAS_RESET_RANDOMNESS_TYPE: 'has-reset-randomness-type',
  TERMS_CONDITIONS: 'terms-conditions',
  RANDOMNESS_TYPE: 'randomness-type',
  IS_USING_QUICKPLAY: 'is-using-quickplay',
  ZERO_DEV_SESSION_KEY: 'zero-dev-session-key',
  TRIALS: 'trials',
  // QK_HASH_TO_TRIALS: 'qk-hash-to-trials',
  TX_HASH_TO_TRIAL_DATA: 'tx-hash-to-trial-data',
  TRIAL_ID_TO_TX_HASH: 'trial-id-to-tx-hash',
  MAX_VALUES: 'max-values',
} as const
