import React from 'react'
import { mobileRouteLinks, externalLinks } from '@/constants/links'
import {
  DirectoryTitle,
  ExternalLinkWrapper,
  ExternalMenuLink,
  ImageWrapper,
  MenuLink,
  MobileMenuContentWrapper,
} from './style'
import { MenuParticles } from '../ParticlesBackground/MenuParticles'
import { useMobileVisibility } from '@/hooks/common/useMobileVisibility'
import { AnimatedWrapper } from '../MobileMenuModals/animatedWrapper'

interface IMobileMenuProps {
  isShowMenu: boolean
  onHide?: () => void
}

export const MobileMenu = ({ isShowMenu, onHide }: IMobileMenuProps) => {
  const currentPath = window.location.pathname.split('/')[1]
  const isMobileScreen = useMobileVisibility({ onHide })

  const isActive = useCallback((path: string) => `/${currentPath}` === path, [currentPath])

  const delayedOnHide = useCallback(() => {
    setTimeout(() => {
      onHide?.()
    }, 500)
  }, [onHide])

  if (!isMobileScreen) return null

  return (
    <AnimatedWrapper
      isVisible={isShowMenu}
    >
      <MobileMenuContentWrapper>
        <div>
          <DirectoryTitle>Directory</DirectoryTitle>
          {mobileRouteLinks.map(route => (
            <MenuLink
              isActive={isActive(route.to)}
              key={route.title}
              to={route.to}
              onClick={delayedOnHide}
              target={route.to.startsWith('https') ? '_blank' : undefined}
            >
              {isActive(route.to) ?
                <MenuParticles particleColors={route.particleColors} />
              : null}
              <ImageWrapper isActive={isActive(route.to)}>
                <img src={route.img} alt={`${route.img}-icon`} />
              </ImageWrapper>
              <span>{route.title}</span>
            </MenuLink>
          ))}
        </div>

        <div>
          <DirectoryTitle>External Links</DirectoryTitle>
          <ExternalLinkWrapper>
            {externalLinks.map(route => (
              <ExternalMenuLink key={route.title} to={route.to} onClick={delayedOnHide}>
                <img src={route.img} alt={`${route.alt}-icon`} />
                <span>{route.title}</span>
              </ExternalMenuLink>
            ))}
          </ExternalLinkWrapper>
        </div>
      </MobileMenuContentWrapper>
    </AnimatedWrapper>
  )
}
