import { type HelperFunctions } from './contracts'

export enum CoinFlipSelection {
  Heads = 0,
  Tails = 1,
}

export const isValidCoinFlipSide = (side: number) => {
  return side === 0 || side === 1
}

export const getCoinFlipMultiplierWithoutPPV = () => {
  return 2
}

export const getCoinFlipMultiplierWithPPV = () => {
  return 1.98
}

// @NOTE: https://en.wikipedia.org/wiki/Kelly_criterion
export const getCoinFlipKellyFraction = () => {
  return 0.01020407272
}

export const getCoinFlipPotentialProfitCoefficient = () => {
  return 0.98
}

export const coinFlipHelperFunctions: HelperFunctions = {
  isValidSide: isValidCoinFlipSide,
  getMultiplierWithoutPPV: getCoinFlipMultiplierWithoutPPV,
  getMultiplierWithPPV: getCoinFlipMultiplierWithPPV,
  getKellyFraction: getCoinFlipKellyFraction,
  getPotentialProfitCoefficient: getCoinFlipPotentialProfitCoefficient,
}
