import { inviteCodesApi } from '@/lib/http/inviteCodes'
import useInviteCodeStore from '@/store/useInviteCodeStore'
import { useDebouncedCallback } from 'use-debounce'

export const useFetchInviteCodes = () =>
  useDebouncedCallback(
    async (addr: string) => {
      try {
        const inviteCodes = await inviteCodesApi.getInviteCodes(addr)
        useInviteCodeStore.getState().setInviteCodes({
          usedInviteCodes: inviteCodes.usedInviteCodes,
          activeInviteCodes: inviteCodes.activeInviteCodes,
        })
      } catch (err) {
        // @TODO: Add better error handling
        console.error(err)
      }
    },
    250,
    { leading: true }
  )
