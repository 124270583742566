import { skulls } from '@/assets/svg/deathmatch-skulls'
import { type Variants, motion } from 'framer-motion'
import {
  SHiddenQuestContentTask,
  SQuestBox,
  SQuestDetailHeader,
  SQuestDetails,
  SQuestTask,
  SQuestTasks,
} from './style'
import useInviteCodeStore from '@/store/useInviteCodeStore'
import { SVGS, TASK_ICONS } from '@/assets'
import { LEVEL_COLORS } from '@/design'
import { MAX_LEVEL } from '@/pages/PointsPage'
import useAuthStore from '@/store/useAuthStore'
import { type DebouncedState } from 'use-debounce'
import BonusAchievement from './BonusAchievement'
import { IFarePointsDetail } from './questDetails.types'

const skullImgVariants: Variants = {
  initial: {
    rotateZ: 0,
    scale: 1,
  },
  animate: {
    scale: [0.9, 1.06],
    rotateZ: [0, 360],
    transition: {
      scale: {
        type: 'tween',
        ease: 'easeOut',
        repeat: Infinity,
        repeatType: 'mirror',
        duration: 1.4,
      },
      rotateZ: {
        type: 'tween',
        ease: 'linear',
        repeat: Infinity,
        repeatType: 'loop',
        duration: 12,
      },
    },
  },
}

export interface IQuestTask {
  index: number
  text: string
  isCompleted: boolean
}

export interface IQuestDetail {
  multiplier: number
  level: number
  title: string
  icon: string
  color: string
  tasks: IQuestTask[]
  isCompleted: boolean
  isInProgress?: boolean
}

export interface IBonusTask {
  id: number
  description: string
  bonusLevelId: number
  actionName?: string
  href?: string
  specificData?: string
  isCompleted: boolean
}

export interface IBonusQuestDetail {
  level: number
  isCompleted: boolean
  isInProgress: boolean
  tasks: IBonusTask[]
}

const QuestBox = ({
  quest,
  isMobile,
  bonusQuestContent,
  pointsDetail,
}: {
  quest: IQuestDetail
  isMobile: boolean
  bonusQuestContent: any
  pointsDetail: IFarePointsDetail
}) => {
  const skullImg = (skulls as any)[
    `skull${quest.level}${quest.isCompleted ? 'Complete' : 'Incomplete'}`
  ]
  const isHidden = useMemo(() => !quest.isCompleted && !quest.isInProgress, [quest])

  const questTaskContent = useMemo(() => {
    if (isHidden) {
      return (
        <SHiddenQuestContentTask className={`level-${quest.level}`}>
          <div className='hidden-circle' />
          <div className='hidden-line-wrapper'>
            <div className='hidden-line' />
            <div className='hidden-line' />
          </div>
        </SHiddenQuestContentTask>
      )
    }

    if (bonusQuestContent && pointsDetail?.bonusLevelId + 1 === quest.level)
      return bonusQuestContent

    const taskElems = quest.tasks.map((task, idx) => {
      const isTaskCompleted = quest.level === 1 && idx === 0 ? true : task.isCompleted
      return (
        <SQuestTask key={idx} $isCompleted={isTaskCompleted}>
          {/* <div className='quest-task-img'> */}
          <img
            className='quest-task-img-skull'
            src={(skulls as any)[`skull${quest.level}Complete`]}
          />
          {/* </div> */}
          <span>{task.text}</span>
        </SQuestTask>
      )
    })

    return (
      <>
        <div
          style={{
            color: quest.isCompleted ? 'rgba(255,255,255,0.6)' : 'white',
          }}
        >
          {!quest.isCompleted ? 'ACTIVE QUEST' : 'COMPLETED QUEST'}
        </div>
        {taskElems}
      </>
    )
  }, [quest, isHidden, isMobile, bonusQuestContent])

  /* @NOTE: Max level's multiplier is unknown, it has a value on backend but we should show '?' to the user */
  const multiplier = useMemo(
    () =>
      !isHidden ?
        quest.level === MAX_LEVEL ?
          '?'
        : quest.multiplier
      : '?',
    [isHidden, quest]
  )
  const title = useMemo(() => (!isHidden ? quest.title : '???'), [isHidden, quest])

  const displayBadge = useMemo(() => {
    if (isHidden) {
      return (
        <div className='level-img-wrapper'>
          <img src={skullImg} className='quest-jollyroger' />
          <motion.img
            variants={skullImgVariants}
            animate={quest.isInProgress ? 'animate' : ''}
            initial={'initial'}
            src={quest.icon}
            className='quest-border'
          />
        </div>
      )
    }

    return (
      <div className='level-img-wrapper'>
        <img src={skullImg} className='quest-jollyroger' />
        <motion.img
          variants={skullImgVariants}
          animate={quest.isInProgress ? 'animate' : ''}
          initial={'initial'}
          src={quest.icon}
          className='quest-border'
        />
      </div>
    )
  }, [isHidden, quest, skullImg])

  return (
    <SQuestBox $isCompleted={quest.isCompleted} $isInProgress={Boolean(quest.isInProgress)}>
      <SQuestDetailHeader
        $borderColor={quest.color}
        $isCompleted={quest.isCompleted}
        $isInProgress={Boolean(quest.isInProgress)}
      >
        {displayBadge}
        <div className='level-title-wrapper'>
          <div className={`quest-level-title`}>LEVEL {quest.level}</div>
          <span>{title}</span>

          <span>{multiplier}X MULTIPLIER</span>
        </div>
      </SQuestDetailHeader>
      <SQuestTasks>{questTaskContent}</SQuestTasks>
      {/* <STaskProgress>{bottomRightContent}</STaskProgress> */}
    </SQuestBox>
  )
}

// const SCompletedBonusQuestBox = styled.div<{ $isCompleted: boolean }>`
//   display: flex;
//   flex-direction: column;
//   gap: 6px;
//   position: relative;
//   h3 {
//     color: ${props => (props.$isCompleted ? 'rgba(255,255,255,0.6)' : 'white')};
//     margin: 0px;

//     ${props =>
//       !props.$isCompleted &&
//       css`
//         text-shadow:
//           1px 1px 5.5px rgba(255, 255, 255, 0.5),
//           1px 1px 5.5px rgba(255, 255, 255, 0.5);
//       `}
//     text-transform: uppercase;
//     margin-bottom: 6px;
//   }

//   span {
//     color: rgba(255, 255, 255, 0.8);
//     text-transform: uppercase;
//   }

//   a {
//     color: rgba(255, 255, 255, 0.8);
//     cursor: pointer;
//   }

//   .task-wrapper {
//     display: flex;
//     align-items: center;
//     img {
//       height: 12px;
//       margin-right: 8px;
//     }
//   }

//   .bonus-msg {
//     position: absolute;
//     top: 0px;
//     right: -6px;
//     color: rgba(255, 255, 255, 0.8);
//     font-size: 10px;
//   }
// `
// const BonusQuestBox = ({
//   bonusQuest,
//   pointsDetails,
//   screenshot,
//   checkCompletion,
//   fetchPointsDetail,
//   authPublicAddress,
// }: {
//   bonusQuest: IBonusQuestDetail
//   pointsDetails: IFarePointsDetail
//   screenshot: DebouncedState<() => Promise<{ twitterImageLink: string }>>
//   checkCompletion: DebouncedState<(idx: number) => Promise<void>>
//   fetchPointsDetail: DebouncedState<(addr: string) => Promise<void>>
//   authPublicAddress: string
// }) => {
//   const bonusTaskElems = useMemo(() => {
//     return bonusQuest.tasks.map((bonusTask, key) => {
//       if (bonusTask.actionName === 'Share') {
//         return (
//           <div key={key}>
//             <button
//               // @TODO: This button probably should have a loading state. It takes couple seconds, user should receive some feedback that it is working
//               // @TODO: I am not doing it right now because when we replace these bonusAchievment with some cool looking ones, it will be lost
//               onClick={async () => {
//                 const returnedData = await screenshot()
//                 window.open(
//                   bonusTask.href?.replace(
//                     bonusTask.specificData || '',
//                     (returnedData as any).twitterImageLink
//                   ),
//                   '_blank'
//                 )
//               }}
//               style={{
//                 textDecoration: 'underline',
//                 color: 'white',
//                 background: 'none',
//                 border: 'none',
//                 margin: 0,
//                 padding: 0,
//                 cursor: 'pointer',
//               }}
//             >
//               {bonusTask.description}
//             </button>
//             <button onClick={() => checkCompletion(bonusTask.id)}>check completion</button>
//           </div>
//         )
//       } else if (bonusTask.actionName === 'Discord') {
//         return (
//           <div>
//             {pointsDetails.discordId ?
//               bonusTask?.actionName && bonusTask?.href && bonusTask?.specificData ?
//                 <div>
//                   <a target='_blank' rel='noreferrer' href={bonusTask.specificData}>
//                     Join to our Discord Server
//                   </a>
//                   <button onClick={() => checkCompletion(bonusTask.id)}>check completion</button>
//                 </div>
//               : null
//             : <a target='_blank' rel='noreferrer' href={bonusTask.href}>
//                 Connect Discord
//               </a>
//             }
//           </div>
//         )
//       }

//       return (
//         <div>
//           {bonusTask.isCompleted ?
//             <span>Completed {bonusTask.description}</span>
//           : <>
//               {bonusTask.actionName && bonusTask.href ?
//                 <a target='_blank' rel='noreferrer' href={bonusTask.href}>
//                   {bonusTask.description}
//                 </a>
//               : null}
//               {(
//                 bonusTask.actionName && bonusTask.actionName === 'Twitter' && !bonusTask.isCompleted
//               ) ?
//                 <a rel='noreferrer' href={`${HTTP_URL}/auth/twitter/callback`}>
//                   {bonusTask.description}
//                 </a>
//               : <button onClick={() => checkCompletion(bonusTask.id)}>check completion</button>}
//             </>
//           }
//         </div>
//       )
//     })
//   }, [bonusQuest])

//   return (
//     <div>
//       <h1>Bonus Level {bonusQuest.level}</h1>
//       <span>{bonusTaskElems}</span>
//     </div>
//   )
// }

const QuestDetails = ({
  isMobile,
  screenshot,
}: {
  isMobile: boolean
  screenshot: DebouncedState<() => Promise<{ linkToRedirect: string }>>
}) => {
  const { authPublicAddress } = useAuthStore()
  const { quests, bonusQuests, pointsDetails } = useInviteCodeStore()

  const contentElems = useMemo(() => {
    let bonusQuestContent: any = null

    return quests?.map((quest, idx) => {
      if (quest.level === 0) return null

      if (pointsDetails && quest.level <= pointsDetails?.levelId && quest.isCompleted) {
        bonusQuestContent = (
          <BonusAchievement
            pointsDetails={pointsDetails}
            bonusQuest={{ ...bonusQuests[idx] }}
            screenshot={screenshot}
            authPublicAddress={authPublicAddress}
          />
        )
      } else {
        bonusQuestContent = null
      }

      return (
        <QuestBox
          quest={{
            ...quest,
            icon: (SVGS as any)[`level${quest.level}Icon`],
            color: LEVEL_COLORS[quest.level],
          }}
          pointsDetail={pointsDetails as any}
          isMobile={isMobile}
          key={quest.level}
          bonusQuestContent={bonusQuestContent}
        />
      )
    })
  }, [bonusQuests, pointsDetails, quests])

  return <SQuestDetails>{contentElems}</SQuestDetails>
}

export default QuestDetails
