import { motion } from 'framer-motion'
import { BREAKPOINTS, SPACING } from '@/design/spacing'

export const PageWrapper = styled(motion.div)`
  height: 100%;
  width: 100%;
  max-width: 1000px;
  overflow-x: hidden;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box;

  @media only screen and (max-width: ${BREAKPOINTS.lg}px) {
    grid-column: 1 / span 2;
  }
`

export const FloatingContainer = styled(motion.div)`
  margin: ${SPACING.md}px;
  display: grid;
  gap: ${SPACING.md}px;
  border-radius: 6px;

  grid-template-rows: 1fr;
  grid-template-columns: 4fr 2fr;

  height: calc(100% - ${SPACING.md * 2}px);
  width: 100%;
`

export const GamePageSpinner = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;

  > * {
    width: fit-content;
  }
`
export const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
`

export const ScrollItem = styled.div`
  flex: 0 0 100%;
  scroll-snap-align: start;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: ${SPACING.md}px;
  
`