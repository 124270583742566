import { HTTP_URL } from '@/constants/http'
import { LOCAL_STORAGE } from '@/constants/utils'
import useInviteCodeStore from '@/store/useInviteCodeStore'
import { type IBonusQuestDetail, type IQuestDetail } from '.'
import { type IFarePointsDetail } from '..'
import { faxios } from '@/lib/http/auth'
import { addAppNoti } from '@/store/useNotiStore'

export const fetchQuests = async (addr: string) => {
  try {
    const { data } = await faxios({
      method: 'GET',
      headers: {
        token: JSON.parse((window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || '')[
          addr
        ],
      },
      url: `${HTTP_URL}/auth/quests`,
    })
    useInviteCodeStore.getState().setQuests(data.quests)
    useInviteCodeStore.getState().setBonusQuests(data.bonusQuests)
    return data as { quests: IQuestDetail[]; bonusQuests: IBonusQuestDetail[] }
    // @TODO: maybe also fetch the new details? So that we would not that user has bonusLeveled up and it would re render?
  } catch (err) {
    // @TODO: Add better error handling
    console.error(err)
  }
}

export const fetchPointsDetail = async (authPublicAddress: string) => {
  try {
    const { data } = await faxios({
      method: 'GET',
      headers: {
        token: JSON.parse((window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || '')[
          authPublicAddress
        ],
      },
      url: `${HTTP_URL}/auth/fare-points/detail`,
    })
    useInviteCodeStore.getState().setPointsDetails(data)
    return data as IFarePointsDetail
  } catch (err) {
    // @TODO: Add better error handling
    console.error(err)
  }
}

export const checkCompletion = async (idx: number, authPublicAddress: string) => {
  try {
    const { data } = await faxios({
      method: 'POST',
      headers: {
        token: JSON.parse((window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || '')[
          authPublicAddress
        ],
      },
      url: `${HTTP_URL}/auth/bonus-achievements/${idx}`,
    })
    if (data.isCompleted) {
      const pointDetail = await fetchPointsDetail(authPublicAddress)
      addAppNoti({
        msg: `Level ${pointDetail?.bonusLevelId || 1 - 1} bonuses completed`,
        type: 'success',
      })
    } else {
      addAppNoti({
        msg: 'Bonus not completed yet',
        type: 'error',
      })
      // throw new Error('Error check completion')
    }
  } catch (err) {
    addAppNoti({
      msg: (err as any).response.data.message,
      type: 'error',
    })
    throw new Error('Error check completion')
  }
}
