import { Button, ButtonEnum } from '.'
import {
  BankrollContractInterface,
  type GameNames,
  MAX_APPROVAL_AMOUNT,
  VITE_USDC_VAULT_ADDRESS,
  fareBankrollAddress,
  formatUsdc,
  getGameAddressFromRoute,
  // getKellyFraction,
  getPotentialProfitCoefficient,
  routeGameTypeMap,
} from '@/lib/crypto'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useShallow } from 'zustand/react/shallow'
import { useCurrencyContracts } from '../Singleton/useCurrencyContracts'
import ConnectWallet from '../Wallet/ConnectWallet'
import useSUContractStore from '@/store/useSUContractStore'
import { useGameContract } from '../Singleton/useGameContract'
import { type Timeout } from 'react-number-format/types/types'
import { DEFAULT_CHAIN_ID } from '@/constants/web3'
import { useWeb3 } from '@/hooks/useWeb3'
import { useWeb3Modal } from '@web3modal/ethers5/react'
import { addAndSwitchNetwork } from '@/lib/crypto/network'
import { decodeError } from 'ethers-decode-error'
import { addAppNoti } from '@/store/useNotiStore'
import numeral from 'numeral'
import { fsocket } from '@/lib/fsocket'
import { useIsAuthed } from '@/hooks/useIsAuthed'
import useBankrollStore from '@/store/useBankrollStore'
import useMaxValuesStore, { applyBufferToValue } from '@/store/useMaxValuesStore'
import useMaxValues from '@/hooks/useMaxValues'
import { useLocalStorage } from 'usehooks-ts'
import { SettingsPageEnum } from '@/pages/SettingsPage'
import { useSound } from '../../shared/SoundSystem/SoundContext'
import click11Audio from '@/assets/audio/Click 11.wav'
import click12Audio from '@/assets/audio/Click 12.wav'
import mouseOverSliderAudio from '@/assets/audio/mouse-over-slider.wav'
import device7StopAudio from '@/assets/audio/Device 7 Stop.wav'

interface IGameButton {
  entryAmountNum: number
  formData: any
}

export const GameButton = ({ entryAmountNum, formData }: IGameButton) => {
  const { open } = useWeb3Modal()
  const { account, chainId, provider, isActive } = useWeb3()
  const { pathname } = useLocation()
  const nav = useNavigate()
  const [, setTabIndex] = useLocalStorage<SettingsPageEnum>(
    'selectedSettingsTabIndex',
    SettingsPageEnum.MAX_VALUES
  )
  const selectedGameContractAddress = useMemo(
    () => getGameAddressFromRoute(pathname) as string,
    [pathname]
  )
  const { loadSound, playSound } = useSound()
  const { riskFactor } = useBankrollStore()
  const { approveAllowance, bankrollContract } = useCurrencyContracts()
  const selectedCurrency = 'usdc'
  const { selectedAllowance, isApprovingAllowance, selectedBalance } = useCurrencyStore(
    useShallow(state => ({
      isApprovingAllowance: state.isApprovingAllowance,
      selectedBalance: state.balances.usdc,
      // bankrollUsdcAmount: state.balances.bankroll,
      selectedAllowance: state.allowances.usdc,
    }))
  )
  const { submitEntry } = useGameContract(routeGameTypeMap[pathname] as any)
  // const hasLoadedInitial = useWalletStore((state) => state.hasLoadedInitial)
  const {
    isApprovingContracts,
    hasApprovedContracts,
    inProgressEntry,
    isWithdrawing,
    isSubmitting,
    setIsApprovingContracts,
    setHasApprovedContracts,
  } = useSUContractStore(state => ({
    isApprovingContracts: state.isApprovingContracts,
    hasApprovedContracts: state.hasApprovedContracts,
    inProgressEntry: state.inProgressEntry,
    isWithdrawing: state.isWithdrawing,
    isSubmitting: state.isSubmitting,
    setIsApprovingContracts: state.setIsApprovingContracts,
    setHasApprovedContracts: state.setHasApprovedContracts,
  }))
  const {
    isUsingDefaultBuffers,
    ethUsdcPriceSCValue,
    ethUsdcPriceBufferPercentage,
    ethUsdcPriceCurrentSCValue,
    averageCallbackGasSCValue,
    averageCallbackGasBufferPercentage,
    averageCallbackGasCurrentSCValue,
    aaCostMultiplierSCValue,
    aaCostMultiplierBufferPercentage,
    aaCostMultiplierCurrentSCValue,
  } = useMaxValuesStore()
  const { fetchAndSetSCMaxValues } = useMaxValues()

  const [canWithdraw, setCanWithdraw] = useState(false)
  const { bankrollBalance } = fsocket.user.useState()
  const isAuthed = useIsAuthed()

  // const kellyFraction = useMemo(() => {
  //   return getKellyFraction(selectedGameContractAddress, formData.side)
  // }, [selectedGameContractAddress, formData.side])

  const potentialProfitCoefficient = useMemo(() => {
    return getPotentialProfitCoefficient(routeGameTypeMap[pathname] as GameNames, formData.side)
  }, [formData.side, pathname])

  const maxAllowedBet = useMemo(() => {
    console.log('maxAllowedBet updated bankroll')
    // @NOTE: If there is no data about bankrollBalance, set it to max value. As a result, user would not have the feature of seeing kelly limit
    if (!bankrollBalance) return Number.MAX_SAFE_INTEGER
    let risk = 100 // by default risk is 100 to represent 1.00
    if (riskFactor) {
      risk = riskFactor
    }

    // return Number(formatUsdc(bankrollBalance)) * Math.abs(kellyFraction)
    const maxBet =
      // since risk has 2 basis points, we have to divie by 100 to normalize it
      // since risk is represented in %, like if risk is 1.00% it means that we are fine with risking 1% of the bankroll
      // so, I divide risk by 100 get rid of basis points and divide by 100 again to make it so that it works by multiplying bankroll balance so I can just divide by 10000 once
      // when it comes to potentialProfitCoefficient, if you flip coin flip, it's potentialProfitCoefficient will be 0.98
      (Number(formatUsdc(bankrollBalance)) * (risk / 10000)) / potentialProfitCoefficient
    console.log('bankrollBalance', maxBet, bankrollBalance, potentialProfitCoefficient, riskFactor)
    return maxBet
  }, [potentialProfitCoefficient, bankrollBalance, riskFactor])

  const formattedMaxAllowedBet = useMemo(
    () => numeral(maxAllowedBet).format('0,0.00'),
    [maxAllowedBet]
  )

  // useEffect(() => {
  //   let intervalId: Timeout
  //   if (inProgressEntry) {
  //     const intervalFunc = () => {
  //       if (Date.now() > inProgressEntry.timestamp + 60000) {
  //         setCanWithdraw(true)
  //       }
  //     }
  //     intervalFunc()
  //     intervalId = setInterval(intervalFunc, 1_000)
  //   } else {
  //     setCanWithdraw(false)
  //   }

  //   return () => clearInterval(intervalId)
  // }, [inProgressEntry])

  useEffect(() => {
    loadSound('buttonClick', click11Audio)
    loadSound('approveClick', click12Audio)
    loadSound('submitClick', mouseOverSliderAudio)
    loadSound('errorClick', device7StopAudio)
  }, [loadSound])

  const playButtonSound = useCallback(
    (soundType: 'button' | 'approve' | 'submit' | 'error') => {
      const soundMap = {
        button: 'buttonClick',
        approve: 'approveClick',
        submit: 'submitClick',
        error: 'errorClick',
      }
      playSound(soundMap[soundType], 0.3, 0.5) // Adjust volume as needed
    },
    [playSound]
  )

  // @TODO: Abstract
  const approveContracts = useCallback(async () => {
    try {
      setIsApprovingContracts(true)
      if (!bankrollContract) {
        console.warn('bankrollContract undefined')
        return
      }
      const allowConsumerContractsTx = await bankrollContract.setAllowedContracts(
        [VITE_USDC_VAULT_ADDRESS],
        [true]
      )
      await allowConsumerContractsTx.wait()
      console.log('allowed consumer games')
      setHasApprovedContracts(true)
      playButtonSound('approve')
    } catch (err) {
      // @NOTE: Package found from the following discussion: https://github.com/ethers-io/ethers.js/discussions/3027
      // @NOTE: Still problematic to decode AA related stuff (as expected, because we receive an error from our POST request rather than an error from the geth node), like it will not give the custom error but give something like: "Buffer is not defined"
      // @NOTE: Maybe it might be a good idea to divide eoa and aa error handling?
      const decodedError = decodeError(err, BankrollContractInterface)
      console.log('decoded error: ', decodedError)
      addAppNoti({
        msg: decodedError.error,
        type: 'error',
      })
      throw new Error(`Error approving contracts`)
    } finally {
      setIsApprovingContracts(false)
    }
  }, [bankrollContract, setHasApprovedContracts, setIsApprovingContracts, provider, chainId])

  useEffect(() => {
    ;(async () => {
      if (!bankrollContract || !account) return
      const isApproved = await bankrollContract.isValidContractForFundOwner(
        VITE_USDC_VAULT_ADDRESS,
        account
      )
      setHasApprovedContracts(isApproved)
    })()
  }, [bankrollContract, account, setHasApprovedContracts, provider, chainId])

  const hasApproved = useMemo(
    () =>
      Number(entryAmountNum) === 0 ||
      (Number(selectedAllowance) !== 0 && Number(selectedAllowance) >= entryAmountNum),
    [selectedAllowance, entryAmountNum]
  )

  const buttonRenderer = useMemo(() => {
    if (!isAuthed) {
      return <ConnectWallet />
    }

    if (chainId !== DEFAULT_CHAIN_ID) {
      return (
        <Button
          onClick={() => {
            playButtonSound('button')
            addAndSwitchNetwork(provider)
          }}
          buttonType={ButtonEnum.WARNING}
          disabled={chainId === DEFAULT_CHAIN_ID}
          loadingText={'SWITCHING NETWORK'}
          type='button'
        >
          SWITCH NETWORK
        </Button>
      )
    }

    if (!hasApprovedContracts) {
      return (
        <Button
          onClick={approveContracts}
          buttonType={ButtonEnum.CONNECT_WALLET}
          disabled={isApprovingContracts}
          isLoading={isApprovingContracts}
          loadingText={'APPROVING GAMES'}
          type='button'
        >
          APPROVE GAMES
        </Button>
      )
    }

    if (inProgressEntry) {
      return (
        <Button
          onClick={() => {
            playButtonSound('button')
          }}
          buttonType={ButtonEnum.PRIMARY_1}
          disabled
          isLoading
          loadingText={'PLAYING'}
          type='button'
        >
          PLAYING
        </Button>
      )
    }

    if (hasApproved) {
      // @TODO: Might be working wrong right now, because of the update of logic
      // if (entryAmountNum / (formData.numberOfEntries || 1) > maxAllowedBet) {
      // @NOTE: With usdcVault's riskFactor, formData.numberOfEntries fo not better

      if (entryAmountNum > maxAllowedBet) {
        return (
          <Button
            onClick={() => playButtonSound('error')}
            buttonType={ButtonEnum.PRIMARY_1}
            disabled={true}
            isLoading={isSubmitting}
            loadingText={'SUBMITTING ENTRY'}
            type='button'
          >
            LIMIT: {formattedMaxAllowedBet}
          </Button>
        )
      }

      // @NOTE: Identify when MaxValues cause a problem and change the button to a warning button
      // @NOTE: This warning button has 2 different versions
      // @NOTE: * If user is using 'default' version, this button prompts them to "FETCH UP TO DATE MAX VALUES" so they just refetch their base values
      // @NOTE: * If user is using 'custom' version, this button prompts them to "UPDATE YOUR MAX VALUE BUFFERS" so they get redirected to settings page's maxValue tab
      // @NOTE: Get current smart contract values (ethUsdcPriceCurrentSCValue...)
      // @NOTE: Calculate user's max values
      // @NOTE: Decide to render warning button or not
      if (
        !(
          ethUsdcPriceSCValue &&
          ethUsdcPriceBufferPercentage &&
          ethUsdcPriceCurrentSCValue &&
          averageCallbackGasSCValue &&
          averageCallbackGasBufferPercentage &&
          averageCallbackGasCurrentSCValue &&
          aaCostMultiplierSCValue &&
          aaCostMultiplierBufferPercentage &&
          aaCostMultiplierCurrentSCValue
        )
      ) {
        // @TODO: Maybe have default values rather than returning?
        return
      }
      const maxEthUsdcPrice = applyBufferToValue(ethUsdcPriceSCValue, ethUsdcPriceBufferPercentage)
      const maxAverageCallbackGas = applyBufferToValue(
        averageCallbackGasSCValue,
        averageCallbackGasBufferPercentage
      )
      const maxAACostMultiplier = applyBufferToValue(
        aaCostMultiplierSCValue,
        aaCostMultiplierBufferPercentage
      )

      if (
        maxEthUsdcPrice < BigInt(ethUsdcPriceCurrentSCValue) ||
        maxAverageCallbackGas < BigInt(averageCallbackGasCurrentSCValue) ||
        maxAACostMultiplier < BigInt(aaCostMultiplierCurrentSCValue)
      ) {
        if (isUsingDefaultBuffers) {
          // @NOTE: If user is using "default", render the below button to allow them to update their base values
          return (
            <Button
              onClick={() => {
                fetchAndSetSCMaxValues(account!)
              }}
              buttonType={ButtonEnum.WARNING}
              disabled={false} // @TODO: Disable when fetching the sc values
              isLoading={false} // @TODO: Load when fetching the sc values
              loadingText={'FETCHING AND SETTING NEW SC VALUES'}
              type='button'
            >
              FETCH UP TO DATE MAX VALUES
            </Button>
          )
        } else {
          // @NOTE: If user is using "custom", render a button to redirect them to settings page so that they update their buffers
          return (
            <Button
              onClick={() => {
                setTabIndex(SettingsPageEnum.MAX_VALUES)
                nav('/settings')
              }}
              buttonType={ButtonEnum.WARNING}
              disabled={false} // @TODO: Disable when fetching the sc values
              isLoading={false} // @TODO: Load when fetching the sc values
              loadingText={'REDIRECTING'}
              type='button'
            >
              UPDATE YOUR MAX VALUE BUFFERS
            </Button>
          )
        }
      }
      return (
        <Button
          onClick={() => {
            playButtonSound('submit')
            submitEntry(formData)
          }}
          buttonType={ButtonEnum.PRIMARY_1}
          disabled={isSubmitting || entryAmountNum <= 0}
          isLoading={isSubmitting}
          loadingText={'SUBMITTING ENTRY'}
          type='button'
        >
          SUBMIT ENTRY
        </Button>
      )
    } else {
      return (
        <Button
          onClick={() => {
            playButtonSound('approve')
            approveAllowance(fareBankrollAddress, MAX_APPROVAL_AMOUNT, 'usdc')
          }}
          buttonType={ButtonEnum.CONNECT_WALLET}
          disabled={isApprovingAllowance || entryAmountNum <= 0}
          isLoading={isApprovingAllowance}
          loadingText={'APPROVING PHONEY'}
          type='button'
        >
          APPROVE {selectedCurrency.toUpperCase()}
        </Button>
      )
    }
  }, [
    bankrollBalance,
    hasApprovedContracts,
    isActive,
    chainId,
    inProgressEntry,
    hasApproved,
    provider,
    approveContracts,
    isApprovingContracts,
    isWithdrawing,
    canWithdraw,
    isSubmitting,
    entryAmountNum,
    submitEntry,
    formData,
    isApprovingAllowance,
    approveAllowance,
    ethUsdcPriceCurrentSCValue,
    averageCallbackGasCurrentSCValue,
    aaCostMultiplierCurrentSCValue,
    ethUsdcPriceBufferPercentage,
    averageCallbackGasBufferPercentage,
    aaCostMultiplierBufferPercentage,
    ethUsdcPriceSCValue,
    averageCallbackGasSCValue,
    aaCostMultiplierSCValue,
    isUsingDefaultBuffers,
    account,
    isAuthed,
    playButtonSound,
    selectedBalance,
  ])

  return <>{buttonRenderer}</>
}
