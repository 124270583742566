import useZeroDevStore from '@/store/useZeroDevStore'
import { useGameContract } from './useGameContract'
import { useShallow } from 'zustand/react/shallow'
import useSUContractStore, { SUContractResult } from '@/store/useSUContractStore'
import { gameStoreMapByGameName, sendGameStoreUpdateByAddress } from '@/store/useGameStateStore'
import { GameStateReducer } from '@/lib/fare/state'
import { BigNumber, Event, utils } from 'ethers'
import { GameNames, unit, USDC_DECIMALS } from '@/lib/crypto'
import { entryEvent } from '@/events/entryEvent'
import { formatEther } from 'viem'
import useTrialStore from '@/store/useTrialStore'

export interface Trial {
  gameName: string
  who: string
  submitter: string
  multiplier: BigNumber
  count: number
  q: bigint[]
  k: bigint[]
  filledOriginalQ: bigint[]
  filledOriginalK: bigint[]
  vrfCostInUsdc: BigNumber
  aaCostInUsdc: BigNumber
  kToMs: IKToMs
  mToPossibleOrderingCount: IMToPossibleOrderingCount
}

export interface IKToMs {
  [key: string]: number[][]
}

export interface IMToPossibleOrderingCount {
  [key: string]: number
}

function findMIndex(arr: number[], x: number) {
  let cumulativeSum = 0
  for (let i = 0; i < arr.length; i++) {
    cumulativeSum += arr[i]
    if (cumulativeSum >= x) {
      return i
    }
  }
  return -1 // Return -1 if x is not exceeded by the cumulative sum of the array
}

// inclusive lowBound and highBound (gets a random number between lowBound and hihgBound) count amount of times (but if some random number has already came up, it can not come up again)
function getUniqueRandomIntegers(lowBound: number, highBound: number, count: number): number[] {
  if (highBound - lowBound + 1 < count) {
    throw new Error('Not enough unique numbers in the given range')
  }
  const uniqueNumbers = new Set<number>()
  while (uniqueNumbers.size < count) {
    const randomInt = Math.floor(Math.random() * (highBound - lowBound + 1)) + lowBound
    uniqueNumbers.add(randomInt)
  }
  return Array.from(uniqueNumbers)
}

// inclusive lowBound and highBound
function getRandomInt(lowBound: number, highBound: number): number {
  return Math.floor(Math.random() * (highBound - lowBound + 1)) + lowBound
}

export const useGameContractListener = (gameName: GameNames) => {
  const {
    setSUContractResults,
    setSubmittedAmount,
    inProgressEntry,
    setInProgressEntry,
    setIsSubmitting,
    setIsWithdrawing,
  } = useSUContractStore(
    useShallow(state => ({
      setSUContractResults: state.setSUContractResults,
      setSubmittedAmount: state.setSubmittedAmount,
      inProgressEntry: state.inProgressEntry,
      setInProgressEntry: state.setInProgressEntry,
      setIsSubmitting: state.setIsSubmitting,
      setIsWithdrawing: state.setIsWithdrawing,
    }))
  )
  const { gameContractWS, account } = useGameContract(gameName)
  const { isUsingAA, zeroDevAddress } = useZeroDevStore(
    useShallow(state => ({
      isUsingAA: state.isUsingAA,
      zeroDevAddress: state.zeroDevAddress,
    }))
  )
  const { trialId, setTrialId, filledOriginalK, k, kToMs, mToPossibleOrderingCount } =
    useTrialStore()
  const entryCountRef = useRef(0)

  /* Memos */
  const send = useMemo(() => sendGameStoreUpdateByAddress(gameName), []) as
    | GameStateReducer
    | undefined

  const userAddress = useMemo(() => account, [account])

  // const fetchUpdateInProgressEntry = useCallback(async () => {
  //   if (!userAddress || !gameContractWS) return null
  //   const entryRes = await checkEntryInProgress(
  //     (isUsingAA ? zeroDevAddress : userAddress) as string,
  //     gameContractWS
  //   )
  //   if (!entryRes) return setInProgressEntry(null)
  //   const { rawEntry, inProgressEntry, entrySubmitted } = entryRes
  //   if (rawEntry.count) {
  //     setInProgressEntry(inProgressEntry)
  //     send?.({
  //       type: 'START',
  //       payload: entrySubmitted,
  //     })
  //   } else {
  //     setInProgressEntry(null)
  //   }
  // }, [userAddress, gameContract, isUsingAA, zeroDevAddress])

  // On mount, check if the userAddress is actively entered into the selected game
  // useEffect(() => {
  //   fetchUpdateInProgressEntry()
  // }, [fetchUpdateInProgressEntry])

  useEffect(() => {
    if (!gameContractWS || !userAddress) return
    const entrySubmittedFilter = gameContractWS.filters.TrialRegistered(
      null,
      userAddress,
      null,
      null,
      null,
      null,
      null,
      null
    )

    const entrySubmittedListener = (
      _trialId: BigNumber,
      who: string,
      submitter: string,
      multiplier: BigNumber,
      q: BigNumber[],
      k: BigNumber[],
      vrfCostInUsdc: BigNumber,
      aaCostInUsdc: BigNumber,
      event: Event
    ) => {
      const formattedTotalEntryAmount = utils.formatUnits(multiplier, USDC_DECIMALS)
      // setSubmittedSide(q[0])
      setSubmittedAmount(formattedTotalEntryAmount)
      setIsSubmitting(false)
      setInProgressEntry({ requestId: _trialId.toString(), timestamp: Date.now() })
      setTrialId(_trialId.toString())
    }

    gameContractWS.on(entrySubmittedFilter, entrySubmittedListener)

    return () => {
      gameContractWS.removeListener(entrySubmittedFilter, entrySubmittedListener)
    }
  }, [userAddress, gameContractWS])

  useEffect(() => {
    if (!gameContractWS || !userAddress) return
    const entryResolvedFilter = gameContractWS.filters.TrialResolved(trialId, null, null, null)

    // @TODO: Should handle the case where, trial id is not written to the local storage yet, but we are trying to resolve it
    const entryResolvedListener = (
      _trialId: BigNumber,
      resultIndex: BigNumber,
      evThreshold: BigNumber,
      randomness: BigNumber
    ) => {
      // @TODO: Find out about q, k values and more about the trial from local storage

      console.log('resolved::trialId: ', _trialId.toString())
      console.log('resolved::resultIndex: ', resultIndex.toString())
      console.log('resolved::evThreshold: ', evThreshold.toString())
      console.log('resolved::randomness: ', randomness.toString())

      if (_trialId.toString() !== trialId) {
        throw new Error('Listened to a trial resolvement but it is not the trial in state')
      }
      if (!filledOriginalK || !k || !kToMs || !mToPossibleOrderingCount) {
        console.log('filledOriginalK: ', filledOriginalK)
        console.log('k: ', k)
        console.log('kToMs: ', kToMs)
        console.log('mToPossibleOrderingCount: ', mToPossibleOrderingCount)
        return
      }

      console.log('resolved::gameName: ', gameName)

      let resultSides: number[] = []
      let playedCount = 0
      let totalRewards = '0'
      let rewards: string[] = []
      if (gameName === GameNames.CoinFlip) {
        // coin flip, m array's index meanings = [0, 1] = [win, lose]
        const submittedEntry = gameStoreMapByGameName[GameNames.CoinFlip].getState().submittedEntry
        if (!submittedEntry) return
        console.log('qk: resolveTrial.k: ', k)
        console.log('qk: resultIndex: ', resultIndex.toNumber())
        const payoutK = k[resultIndex.toNumber()] ?? '0'
        console.log('qk: payout k: ', payoutK)
        totalRewards = String(BigInt(payoutK) - unit)
        const possibleMs = kToMs[payoutK.toString()]
        console.log('qk: possible ms for k: ', possibleMs)
        const possibleMCounts = possibleMs.map(
          possibleM => mToPossibleOrderingCount[possibleM as any]
        )
        console.log('qk: counts for possible ms: ', possibleMCounts)
        const totalPossibleMCount = possibleMCounts.reduce((a, b) => a + b)
        const randomIntForFindingMInUse = getRandomInt(0, totalPossibleMCount - 1)
        console.log('qk: randomIntForFindingMInUse: ', randomIntForFindingMInUse)
        const mIndex = findMIndex(possibleMCounts, randomIntForFindingMInUse)
        console.log('qk: mIndex: ', mIndex)
        const mInUse = possibleMs[mIndex]
        playedCount = mInUse.reduce((a: number, b: number) => a + b)
        const winCount = mInUse[0]
        const loseCount = mInUse[1]
        const winningKsMultiplier = filledOriginalK[0]
        console.log('qk: possible ms: ', possibleMs)
        console.log('qk: m in use: ', mInUse)
        console.log('qk: win count: ', winCount)
        console.log('qk: lose count: ', loseCount)
        console.log('qk: played count: ', playedCount)
        // @NOTE: For game anumation to work as expected, if there is a stop loss or stop gain, we still have to have 0 values inside the array of rewards and resultSides so it works as expected
        const allWinResultSides = Array.from({ length: submittedEntry.entryCount }, (_, index) => {
          if (index < playedCount) {
            return submittedEntry.side
          }
          return 0
        }) as number[]
        const randomIndexes = getUniqueRandomIntegers(0, playedCount - 1, loseCount)
        console.log('qk: random index to lose for: ', randomIndexes)
        randomIndexes.forEach(index => {
          allWinResultSides[index] = 1 - submittedEntry.side
        })
        console.log('qk: entry amount: ', submittedEntry.entryAmount)
        console.log('qk: entry count: ', submittedEntry.entryCount)
        console.log('qk: totalRewards: ', totalRewards)
        resultSides = allWinResultSides
        rewards = resultSides.map(rs => {
          if (rs === submittedEntry.side) {
            return String(
              Number(formatEther(winningKsMultiplier)) *
                (Number(submittedEntry.entryAmount) / Number(submittedEntry.entryCount))
            )
          }
          return '0'
        })
        console.log('qk: resultSides: ', resultSides)
        console.log('qk: rewards: ', rewards)
        console.log('qk: user side: ', submittedEntry.side)
      } else if (gameName === GameNames.Dice) {
        console.log('qk: dice game is being resolved')
        // dice, m array's index meanings: 0 => win, 1 => lose
        const submittedEntry = gameStoreMapByGameName[GameNames.Dice].getState().submittedEntry
        if (!submittedEntry) return
        console.log('qk: dice game got the submitted games state as well')
        console.log('qk: resolveTrial.k: ', k)
        const payoutK = k[resultIndex.toNumber()] ?? '0'
        totalRewards = String(BigInt(payoutK) - unit)
        const possibleMs = kToMs[payoutK.toString()]
        const possibleMCounts = possibleMs.map(
          possibleM => mToPossibleOrderingCount[possibleM as any]
        )
        const totalPossibleMCount = possibleMCounts.reduce((a, b) => a + b)
        console.log('qk: possible m counts: ', possibleMCounts)
        const randomIntForFindingMInUse = getRandomInt(0, totalPossibleMCount - 1)
        console.log('qk: randomIntForFindingMInUse: ', randomIntForFindingMInUse)
        const mIndex = findMIndex(possibleMCounts, randomIntForFindingMInUse)
        console.log('qk: mIndex: ', mIndex)
        const mInUse = possibleMs[mIndex]
        const winCount = mInUse[0]
        const loseCount = mInUse[1]
        const winningKsMultiplier = filledOriginalK[0]
        playedCount = mInUse.reduce((a: number, b: number) => a + b)
        console.log('qk: possible ms: ', possibleMs)
        console.log('qk: m in use: ', mInUse)
        console.log('qk: win count: ', winCount)
        console.log('qk: lose count: ', loseCount)
        console.log('qk: played count: ', playedCount)
        // @NOTE: For game anumation to work as expected, if there is a stop loss or stop gain, we still have to have 0 values inside the array of rewards and resultSides so it works as expected
        const allWinResultSides = Array.from({ length: submittedEntry.entryCount }, (_, index) => {
          if (index < playedCount) {
            return getRandomInt(submittedEntry.side, 10000)
          }
          return 0
        }) as number[]
        const randomIndexes = getUniqueRandomIntegers(0, playedCount - 1, loseCount)
        console.log('qk: random index to lose for: ', randomIndexes)
        randomIndexes.forEach(index => {
          allWinResultSides[index] = getRandomInt(0, submittedEntry.side - 1)
        })
        console.log('qk: entry amount: ', submittedEntry.entryAmount)
        console.log('qk: entry count: ', submittedEntry.entryCount)
        console.log('qk: totalRewards: ', totalRewards)
        resultSides = allWinResultSides
        rewards = resultSides.map(rs => {
          if (rs >= submittedEntry.side) {
            return String(
              Number(formatEther(winningKsMultiplier)) *
                (Number(submittedEntry.entryAmount) / Number(submittedEntry.entryCount))
            )
          }
          return '0'
        })
        console.log('qk: user side: ', submittedEntry.side)
      } else if (gameName === GameNames.RPS) {
        console.log('qk: rps game is being resolved')
        // dice, m array's index meanings: 0 => win, 1 => draw, 2 => lose
        const submittedEntry = gameStoreMapByGameName[GameNames.RPS].getState().submittedEntry
        if (!submittedEntry) return
        console.log('qk: rps game got the submitted games state as well')
        console.log('qk: resolveTrial.k: ', k)
        const payoutK = k[resultIndex.toNumber()] ?? '0'
        totalRewards = String(BigInt(payoutK) - unit)
        const possibleMs = kToMs[payoutK.toString()]
        const possibleMCounts = possibleMs.map(
          possibleM => mToPossibleOrderingCount[possibleM as any]
        )
        const totalPossibleMCount = possibleMCounts.reduce((a, b) => a + b)
        console.log('qk: possible m counts: ', possibleMCounts)
        const randomIntForFindingMInUse = getRandomInt(0, totalPossibleMCount - 1)
        console.log('qk: randomIntForFindingMInUse: ', randomIntForFindingMInUse)
        const mIndex = findMIndex(possibleMCounts, randomIntForFindingMInUse)
        console.log('qk: mIndex: ', mIndex)
        const mInUse = possibleMs[mIndex]
        const winCount = mInUse[0]
        const drawCount = mInUse[1]
        const loseCount = mInUse[2]
        playedCount = mInUse.reduce((a: number, b: number) => a + b)
        console.log('qk: possible ms: ', possibleMs)
        console.log('qk: m in use: ', mInUse)
        console.log('qk: win count: ', winCount)
        console.log('qk: draw count: ', drawCount)
        console.log('qk: lose count: ', loseCount)
        console.log('qk: played count: ', playedCount)
        console.log('qk: received m possible order count: ', mToPossibleOrderingCount)
        // @NOTE: For game anumation to work as expected, if there is a stop loss or stop gain, we still have to have 0 values inside the array of rewards and resultSides so it works as expected
        const allWinResultSides = Array.from({ length: submittedEntry.entryCount }, (_, index) => {
          if (index < playedCount) {
            return (submittedEntry.side + 2) % 3
          }
          return 0
        }) as number[]
        // win:  ( user side + 2 ) % 3
        // draw: same
        // lose: ( user side + 1 ) % 3
        const randomLosingAndDrawingIndexes = getUniqueRandomIntegers(
          0,
          playedCount - 1,
          loseCount + drawCount
        )
        console.log('qk: random index to lose and draw for: ', randomLosingAndDrawingIndexes)
        randomLosingAndDrawingIndexes.forEach((index, i) => {
          console.log('qk: for index and i: ', index, i)
          if (i < loseCount) {
            console.log('qk: losing')
            allWinResultSides[index] = (submittedEntry.side + 1) % 3
          } else {
            console.log('qk: drawing')
            allWinResultSides[index] = submittedEntry.side
          }
        })
        const winningKsMultiplier = filledOriginalK[0]
        const drawingKsMultiplier = filledOriginalK[1]
        console.log('qk: entry amount: ', submittedEntry.entryAmount)
        console.log('qk: entry count: ', submittedEntry.entryCount)
        console.log('qk: totalRewards: ', totalRewards)
        resultSides = allWinResultSides
        rewards = resultSides.map(rs => {
          if (rs === submittedEntry.side) {
            return String(
              Number(formatEther(drawingKsMultiplier)) *
                (Number(submittedEntry.entryAmount) / Number(submittedEntry.entryCount))
            )
          } else if ((submittedEntry.side + 2) % 3 === rs) {
            return String(
              Number(formatEther(winningKsMultiplier)) *
                (Number(submittedEntry.entryAmount) / Number(submittedEntry.entryCount))
            )
          }
          return '0'
        })
        console.log('qk: user side: ', submittedEntry.side)
      }
      console.log('qk: resultSides: ', resultSides)
      setSUContractResults({
        requestId: _trialId.toString(),
        userAddress: account as `0x${string}`,
        resultSides,
        rewards,
        playedCount,
        totalRewards,
      })
      send?.({
        type: 'RESOLVE',
        payload: {
          resultSides,
          playedCount,
          rewards: rewards.map(r => Number(r)),
          totalRewards: Number(totalRewards),
        },
      })
      // TODO This logic needs to be moved to the individual game component rather than the hardcoded reset
      // const timeoutMs = Math.min(entryCountRef.current, formattedPlayedCount) * 200 + 3000
      // setTimeout(() => {
      //   send?.({
      //     type: 'RESET',
      //     payload: {},
      //   })
      //   // TODO This could could cause a problem because we are checking the state value
      //   // inProgressEntry which could remount the listener and ignore events during this time
      //   if (!inProgressEntry) return
      //   if (inProgressEntry.requestId === requestId.toString()) {
      //     console.log('resolved at: ', new Date().toLocaleString())
      //     setInProgressEntry(null)
      //     setIsSubmitting(false)
      //   }
      // }, timeoutMs)
    }

    gameContractWS.on(entryResolvedFilter, entryResolvedListener)

    return () => {
      gameContractWS.removeListener(entryResolvedFilter, entryResolvedListener)
    }
  }, [
    gameContractWS,
    userAddress,
    filledOriginalK,
    k,
    kToMs,
    mToPossibleOrderingCount,
    trialId,
    gameName,
  ])

  entryEvent.useSub('gameFinished', () => {
    send?.({
      type: 'RESET',
      payload: {},
    })

    // TODO This could could cause a problem because we are checking the state value
    // inProgressEntry which could remount the listener and ignore events during this time
    // if (!inProgressEntry) return
    // if (inProgressEntry.requestId === requestId.toString()) {
    // console.log('resolved at: ', new Date().toLocaleString())
    setInProgressEntry(null)
    setIsSubmitting(false)
    // }
  })

  // useEffect(() => {
  //   if (!gameContractWS || !userAddress) return
  //   const entryWithdrewFilter = gameContractWS.filters.EntryWithdrew(null, null, userAddress)

  //   const entryWithdrewListener = (
  //     requestId: BigNumber,
  //     userAddress: string,
  //     fundOwnerAddress: string
  //   ) => {
  //     console.log('ENTRY WITHDREW', inProgressEntry)
  //     setIsWithdrawing(false)

  //     if (inProgressEntry?.requestId === requestId.toString()) {
  //       setInProgressEntry(null)
  //       send?.({
  //         type: 'ERROR',
  //         payload: {
  //           errMsg: 'Entry withdraw',
  //         },
  //       })
  //     }
  //   }

  //   gameContractWS.on(entryWithdrewFilter, entryWithdrewListener)

  //   return () => {
  //     gameContractWS.removeListener(entryWithdrewFilter, entryWithdrewListener)
  //   }
  // }, [gameContractWS, userAddress, inProgressEntry])
}
