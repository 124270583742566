import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { RootWrapper, AppWrapper } from './style'
import { useIsBreakpoint } from './hooks/common/useIsBreakpoint'
import { useQuery } from './hooks/useQuery'
import useAuthStore from './store/useAuthStore'
import { PrivateRoutes } from './Router'

import { Header } from './components/shared/Header'
import { RightPanel } from './components/shared/Panels/RightPanel'
import { ParticlesBackground } from './components/shared/ParticlesBackground'
import { MobileHeader } from './components/shared/Header/MobileHeader'
import { MobileMenu } from './components/shared/MobileMenu'
import { LeftPanel } from './components/shared/Panels/LeftPanel'
import { BalanceListener } from './components/shared/Singleton/BalanceListener'
import { AllowanceListener } from './components/shared/Singleton/AllowanceListener'
import { MobileProfile } from './components/shared/MobileMenuModals/MobileProfile'

import { HandleLocalStorageWithAccountChange } from './components/shared/Panels/RightPanel/HandleLocalStorageWithAccountChange'
import { UserDataListener } from './components/shared/Singleton/UserDataListener'
import { MountFSocket } from './lib/fsocket/MountFSocket'
// import { LockoutPage } from './pages/LockoutPage'
import { AuthWalletOverlay } from './components/shared/Wallet/AuthWalletOverlay'
import { SoundProvider } from './components/shared/SoundSystem/SoundContext'

function Params() {
  const query = useQuery()

  useEffect(() => {
    const inviteCode = query.get('inviteCode')
    if (inviteCode) {
      useAuthStore.setState({ isShowingInviteCodeModal: true })
    }
  }, [query])

  return null
}

function Listeners() {
  return (
    <>
      <BalanceListener />
      <AllowanceListener />
      <HandleLocalStorageWithAccountChange />
      <UserDataListener />
      <MountFSocket />
    </>
  )
}

export function Router() {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [showProfile, setShowProfile] = useState<boolean>(false)

  const isMobileScreen = useIsBreakpoint('sm')

  return (
    <RootWrapper>
      <Routes>
        {/* <Route path='/connect-wallet' element={<LockoutPage />} /> */}
        <Route
          path='*'
          element={
            <>
              <MobileMenu isShowMenu={showMenu} onHide={() => setShowMenu(false)} />
              <MobileProfile isShowProfile={showProfile} onHide={() => setShowProfile(false)} />
              {!isMobileScreen && <Header />}
              {isMobileScreen && (
                <MobileHeader
                  isShowMenu={showMenu}
                  isShowProfile={showProfile}
                  setShowMenu={e => setShowMenu(e ?? !showMenu)}
                  setShowProfile={e => setShowProfile(e ?? !showProfile)}
                />
              )}
              <AppWrapper $isMobileScreen={isMobileScreen}>
                <ParticlesBackground />
                {!isMobileScreen && <LeftPanel />}
                <PrivateRoutes />
                {!isMobileScreen && <RightPanel />}
              </AppWrapper>
              <Listeners />
              <Params />
            </>
          }
        />
      </Routes>
      <AuthWalletOverlay />
    </RootWrapper>
  )
}

export function App() {
  return (
    <SoundProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </SoundProvider>
  )
}
