import { Input } from '@/components/shared/Input'
import { SAccountInfoBox, SAccountInfoWrapper, SInputWrapper } from './AccountTab'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import {
  ButtonWrapper,
  DepositsTitle,
  EOAWrapper,
  Gradient,
  InfoWrapper,
  QuickplayWrapper,
  SettingsSubtitle,
  TitleWrapper,
} from './style'
import { Button, ButtonEnum } from '@/components/shared/Button'
import useMaxValuesStore, {
  applyBufferToValue,
  DEFAULT_BUFFER_PERCENTAGE,
} from '@/store/useMaxValuesStore'
import { useWeb3 } from '@/hooks/useWeb3'
import useMaxValues from '@/hooks/useMaxValues'
import { Toggler } from '@/components/shared/Toggle'
import { SVGS } from '@/assets'
import { BORDER_COLORS, FARE_COLORS, TEXT_COLORS } from '@/design'
import numeral from 'numeral'

const Bar = styled.span<{ $isBelowCurrentSCValue?: boolean }>`
  ${({ $isBelowCurrentSCValue }) => {
    return $isBelowCurrentSCValue ?
        css`
          color: brown;
        `
      : css``
  }}
`

export const MaxValuesTab = () => {
  const {
    ethUsdcPriceSCValue,
    averageCallbackGasSCValue,
    aaCostMultiplierSCValue,
    ethUsdcPriceBufferPercentage,
    averageCallbackGasBufferPercentage,
    aaCostMultiplierBufferPercentage,
    setEthUsdcPriceBufferPercentage,
    setAverageCallbackGasBufferPercentage,
    setAACostMultiplierBufferPercentage,
    isUsingDefaultBuffers,
    setIsUsingDefaultBuffers,
  } = useMaxValuesStore()
  const { fetchAndSetSCMaxValues, fetchSCMaxValues } = useMaxValues()

  const { account } = useWeb3()
  const isMobileScreen = useIsBreakpoint('sm')
  // @TODO: All these, setting values have a problem related to input check, like they can set weird values that would cause the calculations to fail

  // @NOTE: ETH/USDC price
  const [editingEthUsdcPriceBuffer, setEditingEthUsdcPriceBuffer] = useState('')
  const [isEditingEthUsdcPriceBuffer, setIsEditingEthUsdcPriceBuffer] = useState(false)
  // @TODO: Do input check, like do not accept non number values maybe check how floating values work
  const saveEthUsdcPriceBuffer = async () => {
    if (!account) return
    setEthUsdcPriceBufferPercentage(editingEthUsdcPriceBuffer, account)
    setIsEditingEthUsdcPriceBuffer(false)
  }

  // @NOTE: Average callback gas
  const [editingAverageCallbackGas, setEditingAverageCallbackGas] = useState('')
  const [isEditingAverageCallbackGas, setIsEditingAverageCallbackGas] = useState(false)
  // @TODO: Do input check, like do not accept non number values maybe check how floating values work
  const saveAverageCallbackGas = async () => {
    if (!account) return
    setAverageCallbackGasBufferPercentage(editingAverageCallbackGas, account)
    setIsEditingAverageCallbackGas(false)
  }

  // @NOTE: aa cost multiplier
  const [editingAACostMultiplier, setEditingAACostMultiplier] = useState('')
  const [isEditingAACostMultiplier, setIsEditingAACostMultiplier] = useState(false)
  // @TODO: Do input check, like do not accept non number values maybe check how floating values work
  const saveAACostMultiplier = async () => {
    if (!account) return
    setAACostMultiplierBufferPercentage(editingAACostMultiplier, account)
    setIsEditingAACostMultiplier(false)
  }
  const [ethUsdcPriceCurrentSCValue, setEthUsdcPriceCurrentSCValue] = useState('')
  const [averageCallbackGasCurrentSCValue, setAverageCallbackGasCurrentSCValue] = useState('')
  const [aaCostMultiplierCurrentSCValue, setAACostMultiplierCurrentSCValue] = useState('')

  const refetchSCMaxValues = async () => {
    // @TODO: Refetch values and set local storage
    if (!account) return
    await fetchAndSetSCMaxValues(account)
  }

  const fetchAndSetCurrentSCValues = useCallback(async () => {
    const scValues = await fetchSCMaxValues()
    if (!scValues) return
    setEthUsdcPriceCurrentSCValue(scValues.ethUsdcPriceSCValue)
    setAverageCallbackGasCurrentSCValue(scValues.averageCallbackGasSCValue)
    setAACostMultiplierCurrentSCValue(scValues.aaCostMultiplierSCValue)
  }, [fetchSCMaxValues])

  useEffect(() => {
    fetchAndSetCurrentSCValues()
  }, [fetchAndSetCurrentSCValues])

  // @NOTE: Updates the isUsingDefaultBuffers value inside local storage to be true
  // @NOTE: Sets all buffer percentages to be DEFAULT_BUFFER_PERCENTAGE%
  const handleDefaultCheck = useCallback(async () => {
    if (!account) return
    setEthUsdcPriceBufferPercentage(DEFAULT_BUFFER_PERCENTAGE, account)
    setAverageCallbackGasBufferPercentage(DEFAULT_BUFFER_PERCENTAGE, account)
    setAACostMultiplierBufferPercentage(DEFAULT_BUFFER_PERCENTAGE, account)
    setIsUsingDefaultBuffers(true, account)
  }, [
    account,
    setEthUsdcPriceBufferPercentage,
    setAverageCallbackGasBufferPercentage,
    setAACostMultiplierBufferPercentage,
    setIsUsingDefaultBuffers,
  ])

  // @NOTE: Updates the isUsingDefaultBuffers value inside local storage to be false
  const handleCustomCheck = useCallback(async () => {
    if (!account) return
    setIsUsingDefaultBuffers(false, account)
  }, [account, setIsUsingDefaultBuffers])

  return (
    <>
      <SettingsSubtitle>Select your preferred method for using max values:</SettingsSubtitle>
      <SettingsSubtitle>
        There are values inside the Smart Contract that are used to calculate how much to charge for
        the random number and quickplay fee. These values are updatable by Fare Protocol, by setting
        these max values, you are protecting yourself from unexpected fees.
      </SettingsSubtitle>
      <QuickplayWrapper>
        <DepositsTitle
          $walletType='quickplay'
          $depositType={isUsingDefaultBuffers ? 'aa' : undefined}
          onClick={handleDefaultCheck}
        >
          <TitleWrapper>
            <img src={SVGS.quickplayIcon} alt='auto' />
            <span>DEFAULT</span>
          </TitleWrapper>
          <Toggler
            toggleType='quickplay'
            isChecked={isUsingDefaultBuffers}
            onChecked={handleDefaultCheck}
          />
          <Gradient />
        </DepositsTitle>
        <div>
          <p style={{ textAlign: 'justify' }}>
            Defaults all buffer percentages to{' '}
            <span style={{ color: TEXT_COLORS.one }}>{DEFAULT_BUFFER_PERCENTAGE}%</span>
          </p>
          {isUsingDefaultBuffers && (
            <ButtonWrapper isMobileScreen={isMobileScreen} depositType='aa'>
              <div className='flex'>
                <div>ETH / USDC price buffer: {ethUsdcPriceBufferPercentage}%</div>
                <div>Average Callback Gas buffer: {averageCallbackGasBufferPercentage}%</div>
                <div>AA cost multiplier buffer: {aaCostMultiplierBufferPercentage}%</div>
              </div>
            </ButtonWrapper>
          )}
        </div>
      </QuickplayWrapper>

      <EOAWrapper>
        <DepositsTitle
          $walletType='external'
          $depositType={isUsingDefaultBuffers ? undefined : 'eoa'}
          onClick={handleCustomCheck}
        >
          <TitleWrapper>
            <img src={SVGS.externalPlayIcon} alt='external-play' />
            <span>CUSTOM</span>
          </TitleWrapper>
          <Toggler isChecked={!isUsingDefaultBuffers} onChecked={handleCustomCheck} />
          <Gradient />
        </DepositsTitle>
        <div>
          <p style={{ textAlign: 'justify' }}>
            Allows you to customize each buffer percentage value
          </p>
          {!isUsingDefaultBuffers && (
            <ButtonWrapper isMobileScreen={isMobileScreen} depositType='eoa'>
              <InfoWrapper className='account-info-wrapper'>
                <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
                  <span className='account-info-label'>
                    Buffers are applied to the base values. To update your base values to current
                    smart contract values use the button below
                  </span>
                  <SAccountInfoBox>
                    <Button
                      key='3'
                      isMinified
                      disabled={false}
                      isLoading={false}
                      buttonType={ButtonEnum.EDIT_1}
                      onClick={refetchSCMaxValues}
                    >
                      Update Base Values
                    </Button>
                  </SAccountInfoBox>
                </SAccountInfoWrapper>
                <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
                  <span>ETH / USDC price: {Number(ethUsdcPriceCurrentSCValue) / 1e8}</span>
                  <span>Your Base Value: {Number(ethUsdcPriceSCValue) / 1e8}</span>
                  <span>
                    Your Max Value:{' '}
                    <Bar
                      $isBelowCurrentSCValue={
                        Number(
                          applyBufferToValue(
                            ethUsdcPriceSCValue || '100000000',
                            ethUsdcPriceBufferPercentage || '0'
                          )
                        ) < Number(ethUsdcPriceCurrentSCValue)
                      }
                    >
                      {Number(
                        applyBufferToValue(
                          ethUsdcPriceSCValue || '100000000',
                          ethUsdcPriceBufferPercentage || '0'
                        )
                      ) / 1e8}
                    </Bar>
                  </span>
                  <span className='account-info-label'>Buffer %</span>
                  <SAccountInfoBox>
                    {isEditingEthUsdcPriceBuffer ?
                      <>
                        <SInputWrapper>
                          <Input
                            className='account-input-field'
                            name='fp-input-1'
                            autoFocus
                            placeholder='Enter buffer percentage...'
                            value={editingEthUsdcPriceBuffer}
                            onChange={ev => setEditingEthUsdcPriceBuffer(ev.currentTarget.value)}
                          />
                        </SInputWrapper>
                        <Button
                          key='1'
                          className='cancel-btn'
                          isMinified
                          disabled={false}
                          isLoading={false}
                          buttonType={ButtonEnum.EDIT_1}
                          onClick={() => setIsEditingEthUsdcPriceBuffer(false)}
                        >
                          CANCEL
                        </Button>
                        <Button
                          key='2'
                          className={'save-btn'}
                          isMinified
                          disabled={false}
                          isLoading={false}
                          buttonType={ButtonEnum.EDIT_1}
                          onClick={saveEthUsdcPriceBuffer}
                        >
                          SAVE
                        </Button>
                      </>
                    : <>
                        <span className='account-info-value'>
                          {ethUsdcPriceBufferPercentage || 'DEFAULT TO 10%'}
                        </span>
                        <Button
                          key='3'
                          isMinified
                          disabled={false}
                          isLoading={false}
                          buttonType={ButtonEnum.EDIT_1}
                          onClick={() => setIsEditingEthUsdcPriceBuffer(true)}
                        >
                          {ethUsdcPriceBufferPercentage ? 'EDIT' : 'ADD'}
                        </Button>
                      </>
                    }
                  </SAccountInfoBox>
                </SAccountInfoWrapper>
                <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
                  <span>
                    Average Callback Gas: {numeral(averageCallbackGasCurrentSCValue).format('0,0')}
                  </span>
                  <span>Base Value: {numeral(averageCallbackGasSCValue).format('0,0')}</span>

                  <span className=''>
                    Your Max Value:{' '}
                    <Bar
                      $isBelowCurrentSCValue={
                        Number(
                          applyBufferToValue(
                            averageCallbackGasSCValue || '',
                            averageCallbackGasBufferPercentage || '0'
                          )
                        ) < Number(averageCallbackGasCurrentSCValue)
                      }
                    >
                      {numeral(
                        applyBufferToValue(
                          averageCallbackGasSCValue || '',
                          averageCallbackGasBufferPercentage || '0'
                        )
                      ).format('0,0')}
                    </Bar>
                  </span>
                  <span className='account-info-label'>Buffer %</span>
                  <SAccountInfoBox>
                    {isEditingAverageCallbackGas ?
                      <>
                        <SInputWrapper>
                          <Input
                            className='account-input-field'
                            name='fp-input-1'
                            autoFocus
                            placeholder='Enter buffer percentage...'
                            value={editingAverageCallbackGas}
                            onChange={ev => setEditingAverageCallbackGas(ev.currentTarget.value)}
                          />
                        </SInputWrapper>
                        <Button
                          key='1'
                          className='cancel-btn'
                          isMinified
                          disabled={false}
                          isLoading={false}
                          buttonType={ButtonEnum.EDIT_1}
                          onClick={() => setIsEditingAverageCallbackGas(false)}
                        >
                          CANCEL
                        </Button>
                        <Button
                          key='2'
                          className={'save-btn'}
                          isMinified
                          disabled={false}
                          isLoading={false}
                          buttonType={ButtonEnum.EDIT_1}
                          onClick={saveAverageCallbackGas}
                        >
                          SAVE
                        </Button>
                      </>
                    : <>
                        <span className='account-info-value'>
                          {averageCallbackGasBufferPercentage || 'DEFAULT TO 10%'}
                        </span>
                        <Button
                          key='3'
                          isMinified
                          disabled={false}
                          isLoading={false}
                          buttonType={ButtonEnum.EDIT_1}
                          onClick={() => setIsEditingAverageCallbackGas(true)}
                        >
                          {averageCallbackGasBufferPercentage ? 'EDIT' : 'ADD'}
                        </Button>
                      </>
                    }
                  </SAccountInfoBox>
                </SAccountInfoWrapper>
                <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
                  <span>AA cost multiplier: {aaCostMultiplierCurrentSCValue}</span>
                  <span>Base Value: {aaCostMultiplierSCValue}</span>
                  <span>
                    Your Max Value:{' '}
                    <Bar
                      $isBelowCurrentSCValue={
                        Number(
                          applyBufferToValue(
                            aaCostMultiplierSCValue || '',
                            aaCostMultiplierBufferPercentage || '0'
                          )
                        ) < Number(aaCostMultiplierCurrentSCValue)
                      }
                    >
                      {String(
                        applyBufferToValue(
                          aaCostMultiplierSCValue || '',
                          aaCostMultiplierBufferPercentage || '0'
                        )
                      )}
                    </Bar>
                  </span>
                  <span className='account-info-label'>Buffer %</span>
                  <SAccountInfoBox>
                    {isEditingAACostMultiplier ?
                      <>
                        <SInputWrapper>
                          <Input
                            className='account-input-field'
                            name='fp-input-1'
                            autoFocus
                            placeholder='Enter buffer percentage...'
                            value={editingAACostMultiplier}
                            onChange={ev => setEditingAACostMultiplier(ev.currentTarget.value)}
                          />
                        </SInputWrapper>
                        <Button
                          key='1'
                          className='cancel-btn'
                          isMinified
                          disabled={false}
                          isLoading={false}
                          buttonType={ButtonEnum.EDIT_1}
                          onClick={() => setIsEditingAACostMultiplier(false)}
                        >
                          CANCEL
                        </Button>
                        <Button
                          key='2'
                          className={'save-btn'}
                          isMinified
                          disabled={false}
                          isLoading={false}
                          buttonType={ButtonEnum.EDIT_1}
                          onClick={saveAACostMultiplier}
                        >
                          SAVE
                        </Button>
                      </>
                    : <>
                        <span className='account-info-value'>
                          {aaCostMultiplierBufferPercentage || 'DEFAULT TO 10%'}
                        </span>
                        <Button
                          key='3'
                          isMinified
                          disabled={false}
                          isLoading={false}
                          buttonType={ButtonEnum.EDIT_1}
                          onClick={() => setIsEditingAACostMultiplier(true)}
                        >
                          {aaCostMultiplierBufferPercentage ? 'EDIT' : 'ADD'}
                        </Button>
                      </>
                    }
                  </SAccountInfoBox>
                </SAccountInfoWrapper>
              </InfoWrapper>
            </ButtonWrapper>
          )}
        </div>
      </EOAWrapper>
    </>
  )
}
