import Avatar from '@/components/shared/Avatar'
import { Button, ButtonEnum } from '@/components/shared/Button'
import { InfoWrapper, SettingsSubtitle } from './style'
import { Input } from '@/components/shared/Input'
import { BORDER_COLORS, FARE_COLORS } from '@/design'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { Toggler } from '@/components/shared/Toggle'
import useAuth from '@/hooks/useAuth'
import { HTTP_URL } from '@/constants/http'
import { useWeb3 } from '@/hooks/useWeb3'
import useUserData from '@/hooks/useUserData'
import AuthWallet from '@/components/shared/Wallet/AuthWallet'
import { addAppNoti } from '@/store/useNotiStore'

const SAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SBtnWrapper = styled.div`
  display: flex;
  gap: 12px;
`

export const SAccountInfoWrapper = styled.div<{ $isMobileScreen: boolean }>`
  display: flex;
  flex-direction: column;
  button {
    width: ${({ $isMobileScreen }) => ($isMobileScreen ? '80px' : '120px')};
  }
  > span {
    padding: 12px !important;
    padding-left: 0px !important;
    text-transform: uppercase;
  }
  .account-info-value {
    color: rgba(255, 255, 255, 0.6);
  }
`

export const SAccountInfoBox = styled.div`
  border: 1px solid ${BORDER_COLORS.one};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  gap: 12px;
  .twitter-profile-pic {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    margin-right: 12px;
  }

  .disconnect-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.salmon} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.salmon}70;
    }
  }

  .cancel-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.peach} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.peach}70;
    }
  }

  .save-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.aqua} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.aqua}70;
    }
  }

  @media (max-width: 500px) {
    padding: 16px;
    margin: 16px;
    gap: 12px;
  }
`

export const SInputWrapper = styled.div`
  position: relative;
  flex: 1;
  .account-input-field {
    border: 1px solid ${FARE_COLORS.blue};
  }
`

const SColumnTwitter = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  gap: 12px;
  flex: 1;
  .twitter-profile-pic {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    margin-right: 12px;
  }

  .disconnect-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.salmon} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.salmon}70;
    }
  }

  .cancel-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.peach} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.peach}70;
    }
  }

  .save-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.aqua} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.aqua}70;
    }
  }
`

const SRowTwitter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.border-separator {
    border-top: 1px solid ${BORDER_COLORS.one};
    padding-top: 16px;
  }
`

export const AccountTab = () => {
  const [editingUsername, setEditingUsername] = useState('')
  const [isEditingUsername, setIsEditingUsername] = useState(false)
  const [savedEmail, setSavedEmail] = useState('')
  const [email, setEmail] = useState('')
  const [isEditingEmail, setIsEditingEmail] = useState(false)
  const isMobileScreen = useIsBreakpoint('sm')
  const {
    username,
    requestToSaveUsername,
    avatarSeedAddress,
    requestToRerollAvatarSeed,
    isUsingTwitterToDisplay,
    requestToSaveIsUsingTwitterToDisplay,
    twitterUsername,
    twitterImage,
    requestToRefetchTwitterInfo,
    requestToDisconnectTwitter,
  } = useUserData()
  const { authApi, authenticate, authToken } = useAuth()
  const { account } = useWeb3()

  useEffect(() => {
    ;(async () => {
      const { email: receivedEmail } = await authApi.getEmail()
      setEmail(receivedEmail)
      setSavedEmail(receivedEmail)
    })()
  }, [authApi, account])

  const saveEmail = async () => {
    setIsEditingEmail(false)
    if (!authToken) {
      await authenticate()
    }
    try {
      const { message } = await authApi.setEmail(email)
      if (!message) {
        addAppNoti({
          msg: `Failed: email update`,
          type: 'error',
        })
        throw new Error('Error email update')
      } else {
        addAppNoti({
          msg: `Email updated`,
          type: 'success',
        })
        setSavedEmail(email)
      }
    } catch (err) {
      if ((err as unknown as any).response.data.message) {
        addAppNoti({
          msg: `${(err as any).response.data.message}`,
          type: 'error',
        })
        throw new Error('Error email update')
      } else {
        addAppNoti({
          msg: `Failed: email update`,
          type: 'error',
        })
        throw new Error('Error email update')
      }
      // @NOTE: If user fails to save their email. Update the email field with the latest saved email
      setEmail(savedEmail)
    }
  }

  const saveUsername = async () => {
    setIsEditingUsername(false)
    await requestToSaveUsername(editingUsername)
  }

  const connectTwitter = async () => {
    if (!authToken) {
      await authenticate()
    }
    window.location.replace(`${HTTP_URL}/auth/twitter/callback`)
  }

  const refetchTwitterInfo = async () => {
    try {
      await requestToRefetchTwitterInfo()
    } catch (err) {
      addAppNoti({
        msg: `${(err as any).response.data.message}`,
        type: 'error',
      })
      throw new Error('Error refetching twitter info')
    }
  }

  const disconnectTwitter = async () => {
    await requestToDisconnectTwitter()
  }

  return !authToken ?
      <AuthWallet />
    : <div className='settings-tab-content'>
        <SettingsSubtitle>Manage your account:</SettingsSubtitle>
        <InfoWrapper className='account-info-wrapper'>
          <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
            <span className='account-info-label'>Avatar</span>
            <SAccountInfoBox>
              <Avatar seed={avatarSeedAddress || ''} size='md' />
              <Button
                isMinified
                disabled={false}
                isLoading={false}
                buttonType={ButtonEnum.EDIT_1}
                onClick={requestToRerollAvatarSeed}
              >
                REROLL
              </Button>
            </SAccountInfoBox>
          </SAccountInfoWrapper>
          <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
            <span className='account-info-label'>Username</span>
            <SAccountInfoBox>
              {isEditingUsername ?
                <>
                  <SInputWrapper>
                    <Input
                      className='account-input-field'
                      name='fp-input-1'
                      autoFocus
                      placeholder='Enter username...'
                      value={editingUsername}
                      onChange={ev => setEditingUsername(ev.currentTarget.value)}
                    />
                  </SInputWrapper>
                  <Button
                    key='1'
                    className='cancel-btn'
                    isMinified
                    disabled={false}
                    isLoading={false}
                    buttonType={ButtonEnum.EDIT_1}
                    onClick={() => setIsEditingUsername(false)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    key='2'
                    className={'save-btn'}
                    isMinified
                    disabled={false}
                    isLoading={false}
                    buttonType={ButtonEnum.EDIT_1}
                    onClick={saveUsername}
                  >
                    SAVE
                  </Button>
                </>
              : <>
                  <span className='account-info-value'>{username || 'UNNAMED'}</span>
                  <Button
                    key='3'
                    isMinified
                    disabled={false}
                    isLoading={false}
                    buttonType={ButtonEnum.EDIT_1}
                    onClick={() => setIsEditingUsername(true)}
                  >
                    {username ? 'EDIT' : 'ADD'}
                  </Button>
                </>
              }
            </SAccountInfoBox>
          </SAccountInfoWrapper>
          <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
            <span className='account-info-label'>Email</span>
            <SAccountInfoBox>
              {isEditingEmail ?
                <>
                  <SInputWrapper>
                    <Input
                      className='account-input-field'
                      name='fp-input-1'
                      autoFocus
                      placeholder='Enter email...'
                      value={email}
                      onChange={ev => setEmail(ev.currentTarget.value)}
                    />
                  </SInputWrapper>
                  <Button
                    key='1'
                    className='cancel-btn'
                    isMinified
                    disabled={false}
                    isLoading={false}
                    buttonType={ButtonEnum.EDIT_1}
                    onClick={() => setIsEditingEmail(false)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    key='2'
                    className={'save-btn'}
                    isMinified
                    disabled={false}
                    isLoading={false}
                    buttonType={ButtonEnum.EDIT_1}
                    onClick={saveEmail}
                  >
                    SAVE
                  </Button>
                </>
              : <>
                  <span className='account-info-value'>{email || 'NO EMAIL'}</span>
                  <Button
                    key='3'
                    isMinified
                    disabled={false}
                    isLoading={false}
                    buttonType={ButtonEnum.EDIT_1}
                    onClick={() => setIsEditingEmail(true)}
                  >
                    {email ? 'EDIT' : 'ADD'}
                  </Button>
                </>
              }
            </SAccountInfoBox>
          </SAccountInfoWrapper>
          <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
            <span className='account-info-label'>Twitter</span>
            <SAccountInfoBox>
              <SColumnTwitter>
                <SRowTwitter>
                  {twitterUsername ?
                    <SAvatarWrapper>
                      <img className='twitter-profile-pic' src={twitterImage} />
                      <a
                        account-info-value
                        href={`https://twitter.com/${twitterUsername}`}
                        target='_blank'
                        style={{ color: `${FARE_COLORS.aqua}`, textDecoration: 'none' }}
                        rel='noreferrer'
                      >
                        @{twitterUsername}
                      </a>
                    </SAvatarWrapper>
                  : <span className='account-info-value'>NOT CONNECTED</span>}
                  {twitterUsername ?
                    <SBtnWrapper>
                      <Button
                        className={'save-btn'}
                        isMinified
                        disabled={false}
                        isLoading={false}
                        buttonType={ButtonEnum.EDIT_1}
                        onClick={refetchTwitterInfo}
                      >
                        UPDATE
                      </Button>
                      <Button
                        className={'disconnect-btn'}
                        isMinified
                        disabled={false}
                        isLoading={false}
                        buttonType={ButtonEnum.EDIT_1}
                        onClick={disconnectTwitter}
                      >
                        DISCONNECT
                      </Button>
                    </SBtnWrapper>
                  : <Button
                      className={'save-btn'}
                      isMinified
                      disabled={false}
                      isLoading={false}
                      buttonType={ButtonEnum.EDIT_1}
                      onClick={connectTwitter}
                    >
                      CONNECT
                    </Button>
                  }
                </SRowTwitter>
                <SRowTwitter className='border-separator'>
                  <span className='account-info-value'>DISPLAY TWITTER AVATAR/USERNAME</span>
                  <Toggler
                    disabled={!twitterUsername}
                    isChecked={!!isUsingTwitterToDisplay}
                    onChecked={() => requestToSaveIsUsingTwitterToDisplay(!isUsingTwitterToDisplay)}
                    toggleType='quickplay'
                  />
                </SRowTwitter>
              </SColumnTwitter>
            </SAccountInfoBox>
          </SAccountInfoWrapper>
        </InfoWrapper>
      </div>
}
