import useAuth from './useAuth'
import { useWeb3 } from './useWeb3'
import useUserDataStore from '@/store/useUserDataStore'
import { addAppNoti } from '@/store/useNotiStore'

const useUserData = (shouldRetrieve = false) => {
  const { account } = useWeb3()
  const { authApi, authToken, authenticate } = useAuth()
  const {
    username,
    setUsername,
    avatarSeedAddress,
    setAvatarSeedAddress,
    isUsingTwitterToDisplay,
    setIsUsingTwitterToDisplay,
    twitterUsername,
    twitterDisplayName,
    twitterImage,
    deathmatchPoints,
    latestSUEntries,
    setTwitterUsername,
    setTwitterDisplayName,
    setTwitterImage,
    setTwitterAccount,
    setUserData,
    setDeathmatchPoints,
    setLatestSUEntries,
  } = useUserDataStore()

  // @NOTE: Do not request the data from backend everytime you use this hook. Only do it once, by setting `shouldRetrieve` to true in one of the hook usage
  useEffect(() => {
    if (!shouldRetrieve) return
    ;(async () => {
      try {
        const { userData } = await authApi.getUserData()
        setUserData(userData)
      } catch {
        setUserData({} as any)
      }
    })()
  }, [account, authApi, shouldRetrieve, setUserData, authToken])

  const requestToSaveUsername = async (newUsername: string) => {
    if (!authToken) {
      await authenticate()
    }
    try {
      const { message } = await authApi.setUsername(newUsername)
      if (message) {
        setUsername(newUsername)
        addAppNoti({
          type: 'success',
          msg: 'Username updated',
        })
      } else {
        addAppNoti({
          type: 'error',
          msg: 'Failed: username update',
        })
        throw new Error('Error while setting username')
      }
    } catch (err) {
      if ((err as unknown as any).response.data.message) {
        addAppNoti({
          msg: `${(err as any).response.data.message}`,
          type: 'error',
        })
        throw new Error('Error while setting username')
      } else {
        addAppNoti({
          msg: `Failed: username update`,
          type: 'error',
        })
        throw new Error('Error while setting username')
      }
    }
  }

  const requestToRerollAvatarSeed = async () => {
    if (!authToken) {
      await authenticate()
    }
    try {
      const { newAvatarSeedAddress } = await authApi.rerollAvatarSeed()
      if (newAvatarSeedAddress) {
        setAvatarSeedAddress(newAvatarSeedAddress)
        addAppNoti({
          msg: `Avatar updated`,
          type: 'success',
        })
      } else {
        addAppNoti({
          msg: `Failed: avatar update`,
          type: 'error',
        })
        throw new Error('Error while rerolling avatar seed')
      }
    } catch (err) {
      if ((err as unknown as any).response.data.message) {
        addAppNoti({
          msg: `${(err as any).response.data.message}`,
          type: 'error',
        })
        throw new Error('Error while rerolling avatar seed')
      } else {
        addAppNoti({
          msg: `Failed: avatar update`,
          type: 'error',
        })
        throw new Error('Error while rerolling avatar seed')
      }
    }
  }

  const requestToSaveIsUsingTwitterToDisplay = async (newIsUsingTwitterToDisplay: boolean) => {
    if (!authToken) {
      await authenticate()
    }
    try {
      const { message } = await authApi.setIsUsingTwitterToDisplay(newIsUsingTwitterToDisplay)
      if (message) {
        setIsUsingTwitterToDisplay(newIsUsingTwitterToDisplay)
        addAppNoti({
          msg: newIsUsingTwitterToDisplay ? `Twitter display enabled` : `Twitter display disabled`,
          type: 'success',
        })
      } else {
        addAppNoti({
          msg: `Failed: enable twitter display`,
          type: 'error',
        })
        throw new Error('Error while updating isUsingTwitterToDisplay')
      }
    } catch (err) {
      if ((err as unknown as any).response.data.message) {
        addAppNoti({
          msg: `${(err as any).response.data.message}`,
          type: 'error',
        })
        throw new Error('Error while updating isUsingTwitterToDisplay')
      } else {
        addAppNoti({
          msg: `Failed: enable twitter display`,
          type: 'error',
        })
        throw new Error('Error while updating isUsingTwitterToDisplay')
      }
    }
  }

  const requestToRefetchTwitterInfo = async () => {
    if (!authToken) {
      await authenticate()
    }
    try {
      const { message, data } = await authApi.refetchTwitterInfo()
      if (message) {
        setTwitterAccount(data.twitterAccount)
        addAppNoti({
          msg: message,
          type: 'success',
        })
      } else {
        addAppNoti({
          msg: `Failed: twitter update`,
          type: 'error',
        })
        throw new Error('Error while refetching twitter info')
      }
    } catch (err) {
      if ((err as unknown as any).response.data.message) {
        addAppNoti({
          msg: `${(err as any).response.data.message}`,
          type: 'error',
        })
        throw new Error('Error while refetching twitter info')
      } else {
        addAppNoti({
          msg: `Failed: twitter update`,
          type: 'error',
        })
        throw new Error('Error while refetching twitter info')
      }
    }
  }

  const requestToDisconnectTwitter = async () => {
    if (!authToken) {
      await authenticate()
    }
    try {
      const { message } = await authApi.disconnectTwitter()
      if (message) {
        setIsUsingTwitterToDisplay(false)
        setTwitterAccount({})
        addAppNoti({
          msg: `Twitter disconnected`,
          type: 'success',
        })
      } else {
        addAppNoti({
          msg: `Failed: twitter update`,
          type: 'error',
        })
        throw new Error('Error while disconnecting twitter')
      }
    } catch (err) {
      if ((err as unknown as any).response.data.message) {
        addAppNoti({
          msg: `${(err as any).response.data.message}`,
          type: 'error',
        })
        throw new Error('Error while disconnecting twitter')
      } else {
        addAppNoti({
          msg: `Failed: twitter update`,
          type: 'error',
        })
        throw new Error('Error while disconnecting twitter')
      }
    }
  }

  return {
    username,
    setUsername,
    avatarSeedAddress,
    setAvatarSeedAddress,
    isUsingTwitterToDisplay,
    setIsUsingTwitterToDisplay,
    twitterUsername,
    setTwitterUsername,
    twitterDisplayName,
    setTwitterDisplayName,
    twitterImage,
    setTwitterImage,
    setTwitterAccount,
    deathmatchPoints,
    setDeathmatchPoints,
    latestSUEntries,
    setLatestSUEntries,
    requestToSaveUsername,
    requestToRerollAvatarSeed,
    requestToSaveIsUsingTwitterToDisplay,
    requestToRefetchTwitterInfo,
    requestToDisconnectTwitter,
  }
}

export default useUserData
