import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import type { SessionKeyProvider } from '@zerodev/sdk'
import { LOCAL_STORAGE } from '@/constants/utils'

export const DEFAULT_IS_USING_QUICK_PLAY = false

export const setIsUsingQuickPlayInLocalStorage = (
  isUsingQuickPlay: boolean,
  publicAddress: string
) => {
  let isUsingQuickplayStr = localStorage.getItem(LOCAL_STORAGE.IS_USING_QUICKPLAY)
  if (!isUsingQuickplayStr) {
    localStorage.setItem(LOCAL_STORAGE.IS_USING_QUICKPLAY, JSON.stringify({}))
    isUsingQuickplayStr = localStorage.getItem(LOCAL_STORAGE.IS_USING_QUICKPLAY)
  }
  localStorage.setItem(
    LOCAL_STORAGE.IS_USING_QUICKPLAY,
    JSON.stringify({ ...JSON.parse(isUsingQuickplayStr!), [publicAddress]: isUsingQuickPlay + '' })
  )
}

export const getIsUsingQuickPlayFromLocalStorage = (publicAddress: string) => {
  const isUsingQuickplayStr = localStorage.getItem(LOCAL_STORAGE.IS_USING_QUICKPLAY)
  if (!isUsingQuickplayStr) return DEFAULT_IS_USING_QUICK_PLAY
  return JSON.parse(isUsingQuickplayStr)[publicAddress] ?
      JSON.parse(isUsingQuickplayStr)[publicAddress] === 'true'
    : DEFAULT_IS_USING_QUICK_PLAY
}

export type ZeroDevState = {
  isUsingAA: boolean
  zeroDevAddress: string | null
  zeroDevSessionKeyProvider: SessionKeyProvider | null
  isActivatingAA: boolean
  hasSetupBefore: boolean
}

export type ZeroDevActions = {
  setIsUsingAA: (isUsing: boolean, publicAddress: string) => void
  setZeroDevAddress: (address?: string) => void
  setZeroDevSessionKeyProvider: (sessionKeyProvider?: SessionKeyProvider) => void
  setIsActivatingAA: (isActivatingAA: boolean) => void
  setHasSetupBefore: (hasSetupBefore: boolean) => void
}

export const initialZeroDevState: ZeroDevState = {
  isUsingAA: DEFAULT_IS_USING_QUICK_PLAY,
  zeroDevAddress: null,
  zeroDevSessionKeyProvider: null,
  isActivatingAA: false,
  hasSetupBefore: false,
}

export type ZeroDevStore = ZeroDevState & ZeroDevActions

const useZeroDevStore = create<ZeroDevStore>()(
  immer(set => ({
    ...initialZeroDevState,
    setIsUsingAA: (isUsingAA, publicAddress) =>
      set(state => {
        state.isUsingAA = isUsingAA
        setIsUsingQuickPlayInLocalStorage(isUsingAA, publicAddress)
      }),
    setZeroDevAddress: zeroDevAddress =>
      set(() => ({
        zeroDevAddress,
      })),
    setZeroDevSessionKeyProvider: zeroDevSessionKeyProvider =>
      set(() => ({
        zeroDevSessionKeyProvider,
      })),
    setIsActivatingAA: isActivatingAA =>
      set(() => ({
        isActivatingAA,
      })),
    setHasSetupBefore: hasSetupBefore =>
      set(() => ({
        hasSetupBefore,
      })),
  }))
)

export default useZeroDevStore
