import { fsocket } from '@/lib/fsocket'
import useAuthStore from '@/store/useAuthStore'

export const MountFSocket = () => {
  const authToken = useAuthStore(state => {
    // @TODO: Need to standardize if we use authPublicAddress or address from useWeb3 to get address.
    const definitelyAuthTokenObj: typeof state.authTokenObj = state.authTokenObj || {}
    return definitelyAuthTokenObj[state.authPublicAddress]
  })

  useEffect(() => {
    if (!authToken) return
    console.log('authToken has changed. Reconnecting...')
    fsocket.reconnectAll(authToken)
  }, [authToken])

  return null
}
