export const WS_URL = import.meta.env.VITE_WS_URL

export enum ROOM_NAMES {
  ChatRoom = 'ChatRoom',
  LiveEntries = 'LiveEntries',
}
export enum ROOM_EVENTS {
  NEW_CHAT_MESSAGE = 'NewChatMessage',
  HEARTBEAT = 'Heartbeat',
  USERNAME_UPDATED = 'UsernameUpdated',
}

export const MAX_CHAT_MSG_LENGTH = 140
export const MAX_CHAT_MSGS = 50
export const MAX_LIVE_ENTRIES = 50

export enum WebSocketCustomCodes {
  /**
   * Indicates a user doesn't have access to perform this action
   */
  RESTRICTED_USER_ACTION = 3000,
  /**
   * Message parameter validation error
   */
  MESSAGE_VALIDATION_ERROR = 3001,

  USER_MESSAGE_TIMEOUT = 3002,
}
