import { getChainParamsById } from '@/constants/chains'
import type { Web3Provider } from '@ethersproject/providers'
import { DEFAULT_CHAIN_ID } from '@/constants/web3'

export async function addAndSwitchNetwork(provider?: Web3Provider) {
  const switchChainId = Number(DEFAULT_CHAIN_ID)
  const switchChainIdHex = '0x' + switchChainId.toString(16)
  const switchChainParams = { ...getChainParamsById(switchChainId)[0], chainId: switchChainIdHex }
  const _provider = provider || window.ethereum
  try {
    if (!window.ethereum) throw new Error('Wallet extension is not installed')
    // edge case if MM and CBW are both installed
    if (window.ethereum.providers?.length) {
      window.ethereum.providers.forEach(async (p: any) => {
        if (p.isMetaMask) provider = p
      })
    }
    if (_provider.request) {
      await _provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: switchChainIdHex }], // Hexadecimal version of 80001, prefixed with 0x
      })
    } else {
      await _provider.send('wallet_switchEthereumChain', [{ chainId: switchChainIdHex }])
    }
  } catch (error: any) {
    console.error(error)
    if (error.code === 4902) {
      try {
        if (_provider.request) {
          await _provider.request({
            method: 'wallet_addEthereumChain',
            params: [switchChainParams],
          })
        } else {
          await _provider.send('wallet_addEthereumChain', [switchChainParams])
        }
        if (_provider.request) {
          await _provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: switchChainIdHex }], // Hexadecimal version of 80001, prefixed with 0x
          })
        } else {
          await _provider.send('wallet_switchEthereumChain', [{ chainId: switchChainIdHex }])
        }
      } catch (addError: any) {
        console.error(addError)
        throw new Error('User canceled network add/swtich.')
      }
    } else {
      throw new Error(error)
    }
  }
}
