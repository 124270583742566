import React from 'react'
import { createRoot } from 'react-dom/client'
import { enableMapSet } from 'immer'

import { initSentry } from './lib/sentry'
import { createWeb3 } from './lib/crypto/createWeb3'

import { GlobalStyle } from './style'
import { App } from './App'

// Init
createWeb3()
enableMapSet()
initSentry()

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
)
