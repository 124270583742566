import React from 'react'
import { ContentWrapper } from './style'

interface AnimatedWrapperProps {
  children: React.ReactNode
  isVisible: boolean
  animateProps?: object
  exitProps?: object
  transitionDuration?: number
}

export const AnimatedWrapper: React.FC<AnimatedWrapperProps> = ({
  children,
  isVisible,
  transitionDuration = 0.1,
}) => {
  const animationVariants = {
    initial: {
      opacity: 0,
      scaleY: 0,
    },
    animate: {
      opacity: 1,
      scaleY: 1,
      transition: {
        duration: transitionDuration,
        ease: [0.12, 0, 0.39, 0],
      },
    },
    exit: {
      opacity: 0,
      scaleY: 0,
      transition: {
        duration: transitionDuration,
        ease: [0.22, 1, 0.36, 1],
      },
    },
  }

  return (
    <ContentWrapper
      variants={animationVariants}
      initial='initial'
      animate={isVisible ? 'animate' : 'initial'}
      exit='exit'
    >
      <div>{children}</div>
    </ContentWrapper>
  )
}
