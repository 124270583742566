import { FARE_COLORS, FONT_STYLES, MENU_COLORS, SPACING, TEXT_COLORS } from '@/design'

export const ImageWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;

  > img {
    width: 42px;
    height: 42px;
    margin: auto;
    padding: ${SPACING.xs}px;
  }
`

export const MenuLink = styled(Link)<{ isActive?: boolean }>`
  margin: auto;
  grid-column: span 1;
  grid-row: span 1;
  width: calc(100% - ${SPACING.sm * 2}px);
  height: 80px;
  margin: ${SPACING.sm}px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: max(100px, 30%) 1fr;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  position: relative;
  text-decoration: none;
  overflow: hidden !important;
  border-left: 3px solid transparent;
  transition: 0.2s all ease-in-out;

  > span {
    left: 0;
    right: 0;
    ${FONT_STYLES.lg};
    color: ${({ isActive }) => (isActive ? TEXT_COLORS.one : TEXT_COLORS.two)};
    margin: 0;
  }

  // DICE
  &:nth-of-type(1) {
    ${({ isActive }) =>
      isActive ?
        css`
          background: linear-gradient(270deg, ${MENU_COLORS.dice.two}, ${MENU_COLORS.dice.three});
          color: ${TEXT_COLORS.one};
          border-left: 3px solid ${MENU_COLORS.dice.one};
          box-shadow: inset 10px 0 8px -8px ${MENU_COLORS.dice.one};
        `
      : css`
          background: linear-gradient(270deg, ${MENU_COLORS.dice.four}, transparent);
          color: ${TEXT_COLORS.two};
        `};
  }

  // COIN FLIP
  &:nth-of-type(2) {
    ${({ isActive }) =>
      isActive ?
        css`
          background: linear-gradient(
            270deg,
            ${MENU_COLORS.coinFlip.two},
            ${MENU_COLORS.coinFlip.three}
          );
          color: ${TEXT_COLORS.one};
          border-left: 3px solid ${MENU_COLORS.coinFlip.one};
          box-shadow: inset 10px 0 8px -8px ${MENU_COLORS.coinFlip.one};
        `
      : css`
          background: linear-gradient(270deg, ${MENU_COLORS.coinFlip.four}, transparent);
          color: ${TEXT_COLORS.two};
        `};
  }

  // RPS
  &:nth-of-type(3) {
    ${({ isActive }) =>
      isActive ?
        css`
          background: linear-gradient(270deg, ${MENU_COLORS.rps.two}, ${MENU_COLORS.rps.three});
          color: ${TEXT_COLORS.one};
          border-left: 3px solid ${MENU_COLORS.rps.one};
          box-shadow: inset 10px 0 8px -8px ${MENU_COLORS.rps.one};
        `
      : css`
          background: linear-gradient(270deg, ${MENU_COLORS.rps.four}, transparent);
          color: ${TEXT_COLORS.two};
        `};
  }

  // DEATHMATCH
  &:nth-of-type(4) {
    ${({ isActive }) =>
      isActive ?
        css`
          background: linear-gradient(
            270deg,
            ${MENU_COLORS.deathmatch.two},
            ${MENU_COLORS.deathmatch.three}
          );
          color: ${TEXT_COLORS.one};
          border-left: 3px solid ${MENU_COLORS.deathmatch.one};
          box-shadow: inset 10px 0 8px -8px ${MENU_COLORS.deathmatch.one};
        `
      : css`
          background: linear-gradient(270deg, ${MENU_COLORS.deathmatch.four}, transparent);
          color: ${TEXT_COLORS.two};
        `};
  }

  // DEATHMATCH
  &:nth-of-type(5) {
    ${({ isActive }) =>
      isActive ?
        css`
          background: linear-gradient(
            270deg,
            ${MENU_COLORS.deathmatch.two},
            ${MENU_COLORS.deathmatch.three}
          );
          color: ${TEXT_COLORS.one};
          border-left: 3px solid ${MENU_COLORS.deathmatch.one};
          box-shadow: inset 10px 0 8px -8px ${MENU_COLORS.deathmatch.one};
        `
      : css`
          background: linear-gradient(270deg, ${MENU_COLORS.deathmatch.four}, transparent);
          color: ${TEXT_COLORS.two};
        `};
  }

  // SETTINGS
  &:nth-of-type(6) {
    ${({ isActive }) =>
      isActive ?
        css`
          background: linear-gradient(
            270deg,
            ${MENU_COLORS.settings.two},
            ${MENU_COLORS.settings.three}
          );
          color: ${TEXT_COLORS.one};
          border-left: 3px solid ${MENU_COLORS.settings.one};
          box-shadow: inset 10px 0 8px -8px ${MENU_COLORS.settings.one};
        `
      : css`
          background: linear-gradient(270deg, ${MENU_COLORS.settings.two}, transparent);
          color: ${TEXT_COLORS.two};
        `};
  }
`

export const ExternalLinkWrapper = styled.div`
  display: flex;
`

export const ExternalMenuLink = styled(Link)<{ isActive?: boolean }>`
  grid-column: span 1;
  grid-row: span 1;
  width: calc(100% - ${SPACING.sm * 2}px);
  display: inline-flex;
  flex-direction: column;
  margin: ${SPACING.sm}px;
  margin-top: 0;
  border-radius: 6px;
  text-align: center;
  padding: ${SPACING.sm}px;

  img {
    margin: auto;
    height: 42px;
    width: 42px;
  }

  span {
    margin-top: ${SPACING.sm}px;
  }

  // DISCORD
  &:nth-of-type(1) {
    background: ${MENU_COLORS.discord.one};
    border-radius: 6px;

    > * {
      color: ${TEXT_COLORS.one};
    }
  }

  // X
  &:nth-of-type(2) {
    background: ${MENU_COLORS.x.one};
    border-radius: 6px;

    > * {
      color: ${TEXT_COLORS.one};
    }
  }
`

export const MenuSandwich = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  > div:not(:last-child) {
    margin-bottom: ${SPACING.xxs}px;
  }
`

const ROTATION_DELTA = 6

export const Bar = styled.div<{ isActive?: boolean }>`
  width: 25px;
  height: 2px;
  background-color: #333;
  transition: 0.4s;

  &:nth-child(1) {
    background: ${FARE_COLORS.salmon};
  }

  &:nth-child(2) {
    background: ${FARE_COLORS.pink};
  }

  &:nth-child(3) {
    background: ${FARE_COLORS.blue};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &:first-child {
        /* Rotate first bar */
        transform: translate(0, ${ROTATION_DELTA}px) rotate(-45deg);
        background-color: ${FARE_COLORS.salmon};
      }

      &:nth-child(2) {
        /* Fade out the second bar */
        opacity: 0;
      }

      &:last-child {
        /* Rotate last bar */
        transform: translate(0, -${ROTATION_DELTA}px) rotate(45deg);
        background-color: ${FARE_COLORS.salmon};
      }
    `}
`

export const RotatedBar = styled(Bar)`
  /* Rotate first bar */
  transform: translate(0, ${ROTATION_DELTA}px) rotate(-45deg);
`

export const HiddenBar = styled(Bar)`
  /* Fade out the second bar */
  opacity: 0;
`

export const RotatedBar2 = styled(Bar)`
  /* Rotate last bar */
  transform: translate(0, -${ROTATION_DELTA}px) rotate(45deg);
`

export const DirectoryTitle = styled.div`
  text-transform: uppercase;
  width: 100%;
  margin: ${SPACING.md}px;
  color: ${TEXT_COLORS.two};
`
export const MobileMenuContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
