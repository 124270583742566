import { AnimatePresence } from 'framer-motion'
import { NotiPill } from '../NotiPill'

const SCenterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
`

export const CenterHeader = () => {
  return (
    <SCenterHeader>
      <AnimatePresence>
        <NotiPill />
      </AnimatePresence>
    </SCenterHeader>
  )
}
