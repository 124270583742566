import { BORDER_COLORS } from '@/design'
import { deviceBP } from '@/design/breakpoints'
import { AnimatePresence, Variants } from 'framer-motion'
import { motion } from 'framer-motion'

const SPointsExplainer = styled.div`
  width: 100%;
  border: 1px solid ${BORDER_COLORS.one};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  overflow: hidden;
  margin-bottom: 8px;
  border-radius: 6px;

  .explainer-span {
    position: absolute;
  }

  @media ${deviceBP.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const explainerVariants: Variants = {
  initial: {
    x: 100,
  },
  animate: {
    x: 0,
  },
  exit: {
    x: -100,
  },
}

const PointsExplainer = () => {
  const [currentExplainIdx, setCurrentExplainIdx] = useState(0)
  const explainElems = useMemo(() => [], [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentExplainIdx(value => (value + 1) % 3)
    }, 4_000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <AnimatePresence mode='wait'>
      <SPointsExplainer>
        {currentExplainIdx === 0 && (
          <motion.span
            className='explainer-span'
            key={0}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            WIN 200 PHONEY = 1 KILL
          </motion.span>
        )}
        {currentExplainIdx === 1 && (
          <motion.span
            className='explainer-span'
            key={1}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            LOSE 100 PHONEY = 1 DEATH
          </motion.span>
        )}
        {currentExplainIdx === 2 && (
          <motion.span
            className='explainer-span'
            key={2}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            INVITE 3 POINTS = 1 ASSIST
          </motion.span>
        )}
      </SPointsExplainer>
    </AnimatePresence>
  )
}

export default PointsExplainer
