import { type CurrencyType } from '@/lib/crypto'
// import { type IERC20Metadata } from '@/lib/crypto/typechain'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type IERC20Metadata = any

export type CurrencyAmountMap = {
  [K in CurrencyType]: string
}

export type CurrencyContractMap = {
  [K in CurrencyType]: IERC20Metadata | null
}

export type CurrencyBalanceMap = CurrencyAmountMap & { bankroll: string }

export type CurrencyState = {
  usdcAmount: string
  bankrollUsdcAmount: string
  usdcContract?: IERC20Metadata
  ethAmount: string
  isListenerMounted: boolean
  wsUsdcContract: IERC20Metadata | null
  isApprovingAllowance: boolean
  selectedCurrency: CurrencyType
  allowances: CurrencyAmountMap
  balances: CurrencyBalanceMap
  contracts: CurrencyContractMap
  isListening: boolean
}

export type CurrencyActions = {
  setIsListening: (isListening: boolean) => void
  setUsdcAmount: (amount: string) => void
  setBankrollUsdcAmount: (amount: string) => void
  setUsdcContract: (erc20: IERC20Metadata) => void
  setEthAmount: (amount: string) => void
  setIsListenerMounted: (isListenerMounted: boolean) => void
  setWsUsdcContract: (wsUsdcContract: IERC20Metadata) => void
  setSelectedCurrency: (type: CurrencyType) => void
  setBalance: (type: keyof CurrencyBalanceMap, amount: string) => void
  setContract: (type: CurrencyType, contract: IERC20Metadata) => void
  setAllowance: (type: CurrencyType, amount: string) => void
  setIsApprovingAllowance: (isApprovingAllowance: boolean) => void
}

// game contract and currency
export const initialCurrencyAllowance: CurrencyAmountMap = {
  usdc: '0',
  eth: '0',
  fare: '0',
}

export const initialBalances: CurrencyBalanceMap = {
  usdc: '0',
  eth: '0',
  fare: '0',
  bankroll: '0',
}

export const initialCurrencyState: CurrencyState = {
  isListening: false,
  usdcAmount: '0',
  bankrollUsdcAmount: '0',
  ethAmount: '0',
  isListenerMounted: false,
  wsUsdcContract: null,
  isApprovingAllowance: false,
  selectedCurrency: 'usdc',
  balances: initialBalances,
  allowances: initialCurrencyAllowance,
  contracts: {
    usdc: null,
    fare: null,
    eth: null,
  },
}

export type CurrencyStore = CurrencyState & CurrencyActions

const useCurrencyStore = create<CurrencyStore>()(
  immer(set => ({
    ...initialCurrencyState,
    setIsListening: isListening =>
      set(() => ({
        isListening,
      })),
    setUsdcAmount: usdcAmount =>
      set(() => ({
        usdcAmount,
      })),
    setBankrollUsdcAmount: bankrollUsdcAmount =>
      set(() => ({
        bankrollUsdcAmount,
      })),
    setUsdcContract: usdcContract =>
      set(() => ({
        usdcContract,
      })),
    setWsUsdcContract: wsUsdcContract =>
      set(() => ({
        wsUsdcContract,
      })),
    setEthAmount: ethAmount =>
      set(() => ({
        ethAmount,
      })),
    setIsListenerMounted: isListenerMounted =>
      set(() => ({
        isListenerMounted,
      })),
    setSelectedCurrency: selectedCurrency =>
      set(() => ({
        selectedCurrency,
      })),
    setBalance: (type, balance) =>
      set(state => ({
        balances: {
          ...state.balances,
          [type]: balance,
        },
      })),
    setAllowance: (type, amount) =>
      set(state => ({
        allowances: {
          ...state.allowances,
          [type]: amount,
        },
      })),
    setContract: (type, contract) =>
      set(state => ({
        contracts: {
          ...state.contracts,
          [type]: contract,
        },
      })),
    setIsApprovingAllowance: isApprovingAllowance =>
      set(() => ({
        isApprovingAllowance,
      })),
  }))
)

export default useCurrencyStore
