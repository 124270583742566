import { changeDeathmatchTab } from '@/store/useDeathmatchTab'
import './noti-pill.css'
import { AnimatePresence, Variants, motion } from 'framer-motion'
import { useSessionStorage } from 'usehooks-ts'
import { useWeb3 } from '@/hooks/useWeb3'
import { useFetchInviteCodes } from '@/hooks/useFetchInviteCode'
import useInviteCodeStore from '@/store/useInviteCodeStore'

const SNotiPill = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 32px;
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
`

const HAS_CLICKED_NOTI_PILL_KEY = 'has-clicked-noti-pill'

export const NotiPill = () => {
  const { account } = useWeb3()
  const navigate = useNavigate()
  const [hasViewed, setHasViewed] = useSessionStorage(HAS_CLICKED_NOTI_PILL_KEY, 'false')
  const inviteCodeCount = useInviteCodeStore(state => (state.activeInviteCodes || []).length)
  const fetchInviteCodes = useFetchInviteCodes()

  const onClick = useCallback(() => {
    if (hasViewed === 'true') return
    changeDeathmatchTab('invite codes')
    navigate('deathmatch')
    setHasViewed('true')
  }, [navigate, hasViewed])

  useEffect(() => {
    if (!account) return
    fetchInviteCodes(account)
  }, [account])

  return (
    <AnimatePresence>
      {hasViewed !== 'true' && inviteCodeCount > 0 && (
        <SNotiPill
          className='noti-pill'
          initial={{
            opacity: 0,
            y: -60,
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              type: 'tween',
              ease: 'linear',
              duration: 0.5,
              delay: 1.5,
            },
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            type: 'tween',
            ease: 'linear',
            duration: 0.18,
          }}
          onClick={onClick}
        >
          YOU HAVE {inviteCodeCount} INVITE CODE(S)!
        </SNotiPill>
      )}
    </AnimatePresence>
  )
}
