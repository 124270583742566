import useInviteCodeStore from '@/store/useInviteCodeStore'
import { Button, ButtonEnum } from '../Button'
import { BaseModal } from '../Modal'
import { ContentColumn } from '../Modal/style'

interface IInfoModal {
  header: string
  message: string
  onSuccess?: () => void
}

const SInfoModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  padding-top: 0px !important;
  width: 300px;

  .modal-title {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: none !important;
  }

  > span {
    color: #aaa;
    text-align: justify;
    margin-top: 8px;
  }

  /* > * { */
  /*   grid-row: span 1; */
  /*   width: 100%; */
  /*   border: none; */
  /*   border-radius: 0; */
  /*   text-align: center; */

  /*   &:not(:last-of-type) { */
  /*     height: max-content; */
  /*   } */
  /* } */
`
export const InfoModal = ({ header, message }: IInfoModal) => {
  const isShowingWelcomeModal = useInviteCodeStore(state => state.isShowingWelcomeModal)

  const onClose = useCallback(() => {
    useInviteCodeStore.setState({ isShowingWelcomeModal: false })
    localStorage.setItem('has-shown-welcome-modal', 'true')
  }, [])

  return (
    <BaseModal
      show={isShowingWelcomeModal}
      setShow={onClose}
      title={header}
      hideX
      centerHeader
      // noPadding
      content={
        <SInfoModalWrapper>
          <span>{message}</span>

          <Button
            disabled={false}
            style={{ marginTop: 16 }}
            buttonType={ButtonEnum.CONNECT_WALLET}
            type='button'
            onClick={onClose}
          >
            Enter
          </Button>
        </SInfoModalWrapper>
      }
    ></BaseModal>
  )
}
