import { type providers, utils, type BigNumberish, type BigNumber } from 'ethers'
import {
  currencyDecimalsMap,
  VITE_USDC_ADDRESS,
  type CurrencyType,
  ZERO_ADDRESS,
  VITE_FARE_COIN_FLIP_ADDRESS,
  VITE_FARE_DICE_ADDRESS,
  VITE_FARE_RPS_ADDRESS,
} from './contracts'
import { SVGS } from '@/assets'

export const windowEth: providers.ExternalProvider = window.ethereum
export const USDC_DECIMALS = 6
export const ETH_DECIMALS = 18

export const formatUsdc = (value: BigNumberish) => utils.formatUnits(value || 0, USDC_DECIMALS)

export const parseUsdc = (value: string) => utils.parseUnits(value || '0', USDC_DECIMALS)

export const formatEth = (value: BigNumberish) => utils.formatEther(value || 0)

export const parseEth = (value: string) => utils.parseEther(value || '0')

export const formatCurrency = (value: BigNumberish, type: CurrencyType) =>
  utils.formatUnits(value, currencyDecimalsMap[type])

export const parseCurrency = (value: string, type: CurrencyType) =>
  utils.parseUnits(value, currencyDecimalsMap[type])

export const tokenAddressToFormatter = {
  [VITE_USDC_ADDRESS.toLowerCase()]: formatUsdc,
  [ZERO_ADDRESS]: formatEth,
} as const

export const contractAddressToSVGMap = {
  // [VITE_FARE_COIN_FLIP_ADDRESS.toLowerCase()]: SVGS.walletIcon,
  // [VITE_FARE_DICE_ADDRESS.toLowerCase()]: SVGS.diceIcon,
  // [VITE_FARE_RPS_ADDRESS.toLowerCase()]: SVGS.scissorIcon,
}

export const tokenAddressToSVGMap = {
  [VITE_USDC_ADDRESS.toLowerCase()]: SVGS.usdcIcon,
  [ZERO_ADDRESS]: SVGS.ethIcon,
}

export const MAX_APPROVAL_AMOUNT = 1_564_495_459_459_429

export function multiplyBigNumberWithFixedPointNumber(
  bigNumber: BigNumber,
  fixedPointNumber: string
): BigNumber {
  const dotIndex = fixedPointNumber.indexOf('.')
  if (dotIndex === -1) {
    return bigNumber.mul(fixedPointNumber)
  }
  const digitCountAfterDot = fixedPointNumber.slice(dotIndex + 1).length
  const divisor = 10 ** digitCountAfterDot
  const multiplier = fixedPointNumber.replace('.', '')

  return bigNumber.mul(multiplier).div(divisor)
}

export * from './contracts'
