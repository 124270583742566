import { useGLTF } from '@react-three/drei'

const dicePath = '/glb/Dice_Fare_Glass_Tinted.glb'

interface IDiceModel {
  groupProps?: JSX.IntrinsicElements['group']
  meshProps?: JSX.IntrinsicElements['mesh']
  boxGeometryProps?: JSX.IntrinsicElements['boxGeometry']
}

export const DiceModelNew = forwardRef<any, IDiceModel>((props: any, ref: any) => {
  const { nodes, materials } = useGLTF(dicePath) as any
  const scaleValue = 0.27

  const diceMeshRef = useRef(null)
  const diceMatRef = materials['Dice.Body.Textured']
  const diceFrameMatRef = materials['Dice.Frame']

  useImperativeHandle(
    ref,
    () => ({
      diceMeshRef: diceMeshRef.current,
      diceMatRef: diceMatRef,
      diceFrameMatRef: diceFrameMatRef,
    }),
    [diceMeshRef, diceMatRef, diceFrameMatRef]
  )

  return (
    <group {...props.groupProps} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.DiceMesh.geometry}
        material={materials['Dice.Frame']}
        scale={[scaleValue, scaleValue, scaleValue]}
      />
      <mesh
        ref={diceMeshRef}
        castShadow
        receiveShadow
        geometry={nodes.DiceMesh_1.geometry}
        material={materials['Dice.Body.Textured']}
        scale={[scaleValue, scaleValue, scaleValue]}
      />
    </group>
  )
})

useGLTF.preload(dicePath)
