import type { LiveEntrySocket, ILiveEntry } from '../types'
import { FSpaceBase, IFSpaceBaseOpts } from './FSpace'
import { LiveEntryFState, initialLiveEntryFState } from '../state'
import { useDelayedLiveEntry } from '@/store/useGameStateStore'
import useUserDataStore from '@/store/useUserDataStore'

type LiveEntrySpaceOpts = Omit<IFSpaceBaseOpts<LiveEntryFState>, 'name' | 'status' | 'state'>

const MAX_LIVE_ENTRIES = 100

export class LiveEntrySpace extends FSpaceBase<LiveEntrySocket, LiveEntryFState> {
  constructor({ fsocketOpts, authToken }: LiveEntrySpaceOpts) {
    super({
      name: 'live-entry',
      state: initialLiveEntryFState,
      fsocketOpts,
      authToken,
    })

    this.#mountListeners()
    // authToken ? this.connect() : console.log(`No auth token. Not initializing /${this.name}.`)
    this.connect()
  }

  #mountListeners() {
    if (this.hasMountedListeners) return
    this.hasMountedListeners = true
    this.io.on('initial_state', initialLiveEntries => {
      this.state.liveEntries = initialLiveEntries
    })

    // @TODO: Ensure that the same chat message isn't added more than once
    this.io.on('new_live_entry', liveEntry => {
      const isMyEntry = this.#checkIsMyEntry(liveEntry)
      console.log('qk: is my entry: ', isMyEntry)
      console.log('qk: live entry: ', liveEntry)
      if (isMyEntry) return

      if (this.state.liveEntries.length >= MAX_LIVE_ENTRIES) {
        this.state.liveEntries.splice(this.state.liveEntries.length - 1, 1)
      }

      this.state.liveEntries.unshift(liveEntry)
    })
  }
  #checkIsMyEntry(liveEntry: ILiveEntry) {
    const delayedLiveEntry = useDelayedLiveEntry.getState()
    const currTxHash = delayedLiveEntry.liveEntry.submittedTxHash
    console.log('qk: liveEntry.txHash: ', liveEntry.txHash)
    console.log('qk: currTxHash: ', currTxHash)
    // @NOTE: remove in progress entry if this one was an in progress one
    useUserDataStore.getState().removeInProgressEntry(liveEntry.txHash)

    if (liveEntry.txHash && liveEntry.txHash.toLowerCase() === currTxHash?.toLowerCase()) {
      delayedLiveEntry.setInProgressLiveEntry(liveEntry, liveEntry.resolvementTxHash)
      return true
    }
  }
}
