import { useWeb3 } from '@/hooks/useWeb3'

import useAuth from '@/hooks/useAuth'
import { Button, ButtonEnum } from '../Button'
import useAuthStore from '@/store/useAuthStore'

const SAuthButton = styled(Button)`
  margin-right: 16px !important;
`

const InviteCodeBtn = ({ ...props }) => {
  const { isActive } = useWeb3()
  const { setIsShowingInviteCodeModal, isShowingInviteCodeModal, hasEnteredInviteCode } =
    useAuthStore(state => ({
      setIsShowingInviteCodeModal: state.setIsShowingInviteCodeModal,
      isShowingInviteCodeModal: state.isShowingInviteCodeModal,
      hasEnteredInviteCode: state.hasEnteredInviteCode,
    }))

  const { authPublicAddress, authenticate, isAuthed, isAuthing } = useAuth()

  if (!isActive || !authPublicAddress || hasEnteredInviteCode) return null

  return (
    <SAuthButton
      type='button'
      buttonType={ButtonEnum.CONNECT_WALLET}
      disabled={isShowingInviteCodeModal}
      onClick={() => setIsShowingInviteCodeModal(true)}
      isLoading={isShowingInviteCodeModal}
      loadingText={'Entering Invite Code'}
      {...props}
    >
      Enter Invite Code
    </SAuthButton>
  )
}

export default InviteCodeBtn
