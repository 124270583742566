import axios from 'axios'

import { HTTP_URL } from '@/constants/http'
import { LOCAL_STORAGE } from '@/constants/utils'
import { faxios } from './auth'

const routes = {
  inviteCodes: `${HTTP_URL}/invite-codes`,
  tryInviteCode: `${HTTP_URL}/invite-code`,
  requestInviteCodes: `${HTTP_URL}/request-invite-codes`,
}

export type CreatorInviteCode = {
  code: string
  isActive: boolean
  usedAt: Date
  userAddress: string | null
}

export type CreatorUsedInviteCode = CreatorInviteCode & {
  userPoints: number
}

export type InviteCodes = {
  activeInviteCodes: CreatorInviteCode[]
  usedInviteCodes: CreatorUsedInviteCode[]
}

export interface TryInviteCodeResp {
  isSuccessful: boolean
  remainingAttempts?: number
  hoursLeft?: number
  reason?: string
}

export interface RequestInviteCodesResp {
  message: string
}

export const inviteCodesApi = {
  async getInviteCodes(publicAddress: string) {
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { 'fp-token': token },
      method: 'GET',
      url: routes.inviteCodes,
    })

    return data as InviteCodes
  },

  async tryInviteCode(inviteCode: string, publicAddress: string) {
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { 'fp-token': token },
      method: 'POST',
      data: {
        inviteCode,
      },
      url: routes.tryInviteCode,
    })

    return data as TryInviteCodeResp
  },

  async requestInviteCodes(publicAddress: string) {
    try {
      const token = JSON.parse(
        (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
      )[publicAddress]
      const { data } = await faxios({
        headers: { 'fp-token': token },
        method: 'POST',
        url: routes.requestInviteCodes,
      })

      return data as RequestInviteCodesResp
    } catch (err) {
      throw new Error('Already request codes')
    }
  },
}
