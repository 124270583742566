import { BORDER_COLORS, FARE_COLORS, FONT_STYLES } from '@/design'
import Avatar from '../shared/Avatar'
import CountUp from 'react-countup'
import useLeaderboard from '@/hooks/useLeaderboard'
import { SVGS } from '@/assets'
import { deviceBP } from '@/design/breakpoints'
import { getUserImageToRender, getUsernameToRender } from '@/utils/userDisplay'
import numeral from 'numeral'

export const MIN_LEADERBOARD_POINT = 10

const SLeaderboardGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  flex: 1;
  min-height: 0;
  border: 1px solid ${BORDER_COLORS.one};
  padding-top: 8px;
  border-radius: 6px;

  .kills-color {
    color: ${FARE_COLORS.aqua};
  }

  .deaths-color {
    color: ${FARE_COLORS.salmon};
  }

  .assists-color {
    color: ${FARE_COLORS.peach};
  }
`

const SLeaderboardGridHeader = styled.div`
  color: white;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  padding-bottom: 8px;

  span {
    &:nth-child(1) {
      flex: 3;

      @media ${deviceBP.sm} {
        flex: 2;
        flex-shrink: 2;
      }
    }

    &:nth-child(2) {
      flex: 3;
      text-align: center;

      @media ${deviceBP.sm} {
        flex: 5;
      }
    }

    &:nth-child(3) {
      flex: 3;
      text-align: center;

      @media ${deviceBP.sm} {
        flex: 5;
      }
    }

    &:nth-child(4) {
      flex: 3;
      text-align: right;
      @media ${deviceBP.sm} {
        flex: 3;
      }
    }
  }
`

const SLeaderboardRow = styled.div`
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  padding-left: 12px;
  padding-right: 12px;
  transition: all ease-in-out 0.08s;
  &:hover {
    cursor: pointer;
    background: #121212;
  }

  @media ${deviceBP.sm} {
    &:hover,
    &:active {
      padding-bottom: 10px;
      .user-data-text {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
      }
    }
  }

  > div {
    &:nth-child(1) {
      flex: 3;

      @media ${deviceBP.sm} {
        flex-shrink: 2;
        flex: 2;
      }
    }

    &:nth-child(2) {
      flex: 3;
      text-align: center;
      @media ${deviceBP.sm} {
        flex: 5;
      }
    }

    &:nth-child(3) {
      flex: 3;
      text-align: center;
      @media ${deviceBP.sm} {
        flex: 5;
      }
    }

    &:nth-child(4) {
      flex: 3;
      text-align: right;

      @media ${deviceBP.sm} {
        flex: 3;
      }
    }
  }
`

export const SAvatar = styled(Avatar)`
  > .avatar-wrapper {
    height: 36px;
    width: 36px;
  }
  img {
    height: 22px;
    width: 22px;
  }
`

const SUserDataWrapper = styled.div`
  .rank-avatar-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;

    .rank-border {
      position: absolute;
      left: 0;
      top: 0;
      width: 36px;
      height: 36px;
    }
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
  > span {
    padding-right: 12px;
  }
  .user-data-text {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > span {
      font-size: 11px;
      line-height: 8px;
      padding-top: 1px;
      color: rgb(170, 170, 170);
      &:first-child {
        font-size: 14px;
        line-height: 11px;
        padding-top: 2px;
        color: white;
        padding-bottom: 4px;
      }
    }

    @media ${deviceBP.sm} {
      display: none;
      position: absolute;
      bottom: 0px;
      background: #121212;
      border: 1px solid ${BORDER_COLORS.one};
      border-radius: 6px;
    }
  }
`

const SUserKDA = styled.div`
  ${FONT_STYLES.md};
`

const SUserPoints = styled.div`
  ${FONT_STYLES.md};
  text-shadow:
    1px 1px 8.3px rgba(255, 255, 255, 0.5),
    1px 1px 8.3px rgba(255, 255, 255, 0.5);
  font-style: normal;
  font-weight: 500;
`

const SLeaderboardBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 1 auto;
  height: 0;
`

const LeaderboardGrid = ({ isMobile }: { isMobile: boolean }) => {
  const { leaderboard } = useLeaderboard(true)
  console.log(leaderboard)

  const leaderboardRows = useMemo(() => {
    if (!leaderboard) return <div>loading leaderboard</div>

    return leaderboard
      .filter(rowData => rowData.totalPoints >= 10)
      .map((rowData, idx) => {
        const userImageToRender = getUserImageToRender(
          rowData.fundOwnerAddress,
          rowData.isUsingTwitterToDisplay,
          rowData.avatarSeedAddress,
          rowData.twitterImage,
          {},
          { height: '20px', width: '20px' }
        )
        const usernameToRender = getUsernameToRender(
          rowData.fundOwnerAddress,
          rowData.isUsingTwitterToDisplay,
          rowData.username,
          rowData.twitterUsername
        )

        return (
          <SLeaderboardRow key={rowData.fundOwnerAddress}>
            <SUserDataWrapper>
              <span>#{idx + 1}</span>
              <div className='rank-avatar-wrapper'>
                <img
                  className='rank-border'
                  alt='rank-border'
                  src={(SVGS as any)[`level${rowData.level}Icon`]}
                  style={{ opacity: rowData.level === 0 ? 0.25 : 1 }}
                  id={String(rowData.level)}
                />
                {userImageToRender}
              </div>
              <div className='user-data-text'>{usernameToRender}</div>
            </SUserDataWrapper>
            <SUserKDA>{numeral(rowData.usdcBalance || '0').format('0,0')}</SUserKDA>
            <SUserKDA>
              {/* <span className='kills-color'>{numeral(rowData.killPoints).format('0,0')}</span> /{' '} */}
              {/* <span className='deaths-color'>{numeral(rowData.deathPoints).format('0,0')}</span> /{' '} */}
              {/* <span className='assists-color'>{numeral(rowData.referralPoints).format('0,0')}</span> */}
              <span className='kills-color'>{rowData.killPoints}</span> /{' '}
              <span className='deaths-color'>{rowData.deathPoints}</span> /{' '}
              <span className='assists-color'>{rowData.referralPoints}</span>
            </SUserKDA>
            <SUserPoints>
              <CountUp end={rowData.totalPoints} />
            </SUserPoints>
          </SLeaderboardRow>
        )
      })
  }, [leaderboard, isMobile])

  return (
    <SLeaderboardGrid>
      <SLeaderboardGridHeader>
        <span>USER</span>
        <span>PHONEY</span>
        <span>K / D / A</span>
        <span>POINTS</span>
      </SLeaderboardGridHeader>
      <SLeaderboardBody>{leaderboardRows}</SLeaderboardBody>
    </SLeaderboardGrid>
  )
}

export default LeaderboardGrid
