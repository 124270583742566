import { BORDER_COLORS, SPACING } from '@/design'
import Avatar from '../Avatar'
import { motion } from 'framer-motion'

export const SDropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
`

export const SAddressAvatarWrapper = styled.div<{ $userColor?: string; $isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 42px;
  /* min-width: 132px; */
  width: auto;
  padding-left: 8px;
  padding-right: 12px;
  /* border: 1px solid ${({ $userColor }) => $userColor || BORDER_COLORS.one}; */
  border: 1px solid ${BORDER_COLORS.one};
  color: #aaaaaa;
  border-radius: 6px;
  /* background: #101010; */
  transition: all ease-in-out 0.08s;
  position: relative;

  &:hover {
    border-color: ${props => props.$userColor};
  }
  .avatar-wrapper {
    margin-right: 8px;
  }

  ${props =>
    props.$isOpen &&
    css`
      background: #101010;
      color: white;
      border-color: ${props.$userColor};
    `}

  > span {
    line-height: 1px;
    padding-top: 3px;
    user-select: none;
    justify-self: flex-start;
  }
`

export const SDropdownAvatar = styled(Avatar)`
  /* margin-left: 8px; */
  /* margin-right: 8px; */
  height: 20px !important;
  width: 20px !important;
  > img {
    height: 14px !important;
    width: 14px !important;
  }
`

export const SDropdownContent = styled(motion.div)<{ $userColor?: string }>`
  position: absolute;
  top: 48px;
  right: 0px;
  width: 131px;
  border: 1px solid ${BORDER_COLORS.one};
  z-index: 10;
  border-radius: 6px;
  background: black;
  /* border-color: ${props => props.$userColor}; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 10px;
`

export const SDropdownItem = styled.div<{ $userColor?: string }>`
  position: relative;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  padding-left: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  transition: all ease-in-out 0.12s;
  height: 20px;
  display: flex;
  align-items: center;
  > span {
    transition: all ease-in-out 0.12s;
    font-size: 11px;
    line-height: 12px;
    color: #aaaaaa;
  }

  > .hover-bar {
    transition: all ease-in-out 0.12s;
    position: absolute;
    right: 6px;
    top: 0px;
    height: 20px;
    width: 1px;

    box-shadow: inset 0px 0px 10px transparent;
    border: 1px solid transparent;
    background: rgba(255, 255, 255, 0);
  }
  &:hover {
    > span {
      color: white;
    }
    > .hover-bar {
      /* background: rgba(255, 255, 255, 0.12); */
      box-shadow: inset 0px 0px 10px ${props => props.$userColor}99;
      border: 1px solid ${props => props.$userColor};
    }
  }
`
