import { type DebouncedState, useDebouncedCallback } from 'use-debounce'
import { type IBonusQuestDetail, type IFarePointsDetail } from './questDetails.types'
import { checkCompletion } from './questHelpers'
import { HTTP_URL } from '@/constants/http'
import { SVGS } from '@/assets'
import { addAppNoti } from '@/store/useNotiStore'
import { FilledLoadingChip } from '@/components/shared/LoadingChip'

interface BonusAchievement {
  authPublicAddress: string
  screenshot: DebouncedState<
    () => Promise<{
      linkToRedirect: string
    }>
  >
  bonusQuest: IBonusQuestDetail
  pointsDetails: IFarePointsDetail
}

const SBonusAchievement = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const SBonusPointsTitle = styled.div<{ $isCompleted?: boolean }>`
  color: ${props => (props.$isCompleted ? 'rgba(255,255,255,0.6)' : 'white')};
  margin: 0px;

  text-transform: uppercase;
  margin-bottom: 6px;
`

const SCheckCompletition = styled.div`
  font-size: 10px;
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 8px;
  right: 8px;
`

const STopRightMsg = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 10px;
`

const SBonusTask = styled.div<{ $isCompleted?: boolean }>`
  display: flex;
  align-items: center;
  img {
    height: 12px;
    margin-right: 8px;
  }
  button,
  a,
  .completed-action {
    all: unset;
    text-decoration-line: ${props => (!props.$isCompleted ? 'underline' : 'none')};
    color: ${props => (props.$isCompleted ? 'rgba(255,255,255,.8)' : 'white')};
    font-size: 12px;
    cursor: pointer;
  }
`

const BonusAchievement = ({
  authPublicAddress,
  screenshot,
  bonusQuest,
  pointsDetails,
}: BonusAchievement) => {
  // const hasFinishedBonuses = useRef(true)
  const [hasFinishedBonuses, setHasFinishedBonuses] = useState(true)
  const [checkTaskIds, setCheckTaskIds] = useState<number[]>([])
  const [isCheckingCompletion, setIsCheckingCompletion] = useState(false)

  const debouncedCheckCompletion = useDebouncedCallback(
    async (bonusTaskId: number) => await checkCompletion(bonusTaskId, authPublicAddress),
    250,
    { leading: true }
  )

  const bonusTaskElems = useMemo(() => {
    const checkIds: number[] = []
    const elems = bonusQuest.tasks.map(bonusTask => {
      let actionLink: any = null
      const isCompleted = bonusTask.isCompleted

      if (isCompleted) {
        actionLink = <span className='completed-action'>{bonusTask.description}</span>
      } else {
        setHasFinishedBonuses(false)
        checkIds.push(bonusTask.id)
      }

      if (bonusTask.href && !isCompleted) {
        actionLink = (
          <a target='_blank' rel='noreferrer' href={bonusTask.href}>
            {bonusTask.description}
          </a>
        )
      }

      if (bonusTask.actionName === 'Share') {
        actionLink = (
          <a
            onClick={async () => {
              try {
                addAppNoti({
                  msg: 'Sharing on X',
                  type: 'success',
                })
                const returnedData = await screenshot()
                console.log(returnedData)
                window.open((returnedData as any).linkToRedirect, '_blank')
              } catch (err) {
                addAppNoti({
                  msg: 'Failed: share on X',
                  type: 'error',
                })
                throw new Error('Error while sharing on X')
              }
            }}
          >
            {bonusTask.description}
          </a>
        )
      } else if (bonusTask.actionName === 'Discord') {
        actionLink =
          pointsDetails.discordId ?
            bonusTask?.actionName && bonusTask?.href && bonusTask?.specificData ?
              <a target='_blank' rel='noreferrer' href={bonusTask.specificData}>
                Join Discord Server
              </a>
            : null
          : <a target='_blank' rel='noreferrer' href={bonusTask.href}>
              Connect Discord
            </a>
      }

      if (bonusTask.actionName && bonusTask.actionName === 'Twitter' && !isCompleted) {
        actionLink = (
          <a rel='noreferrer' href={`${HTTP_URL}/auth/twitter/callback`}>
            {bonusTask.description}
          </a>
        )
      }

      if (actionLink) {
        return (
          <SBonusTask $isCompleted={isCompleted}>
            <img alt={'Bonus Skull'} src={!isCompleted ? SVGS.deathmatchIcon : SVGS.skullEmpty} />
            {actionLink}
          </SBonusTask>
        )
      }

      return null
    }) as any

    setCheckTaskIds(checkIds)

    return elems
  }, [bonusQuest])

  return (
    <SBonusAchievement>
      {!hasFinishedBonuses && isCheckingCompletion && <FilledLoadingChip width={48} height={48} />}
      {!hasFinishedBonuses && (
        <>
          <SBonusPointsTitle $isCompleted={hasFinishedBonuses}>BONUS POINTS</SBonusPointsTitle>
          {bonusTaskElems}
          <SBonusTask $isCompleted={false}>
            <img alt={'Bonus Skull'} src={SVGS.deathmatchIcon} />
            <button
              onClick={async () => {
                setIsCheckingCompletion(true)
                try {
                  await Promise.all(checkTaskIds.map(debouncedCheckCompletion))
                } finally {
                  console.log('TEST: runs after compl')
                  setIsCheckingCompletion(false)
                }
              }}
            >
              Confirm Completion
            </button>
          </SBonusTask>
        </>
      )}
      {hasFinishedBonuses && <SCheckCompletition>COMPLETED</SCheckCompletition>}
      <STopRightMsg>ADDED END OF SEASON</STopRightMsg>
    </SBonusAchievement>
  )
}

export default BonusAchievement
