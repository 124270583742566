import { Link } from 'react-router-dom'
import { SVGS } from '@/assets'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { routeLinks } from '@/constants/links'

import {
  AccountWrapper,
  HeaderWrapper,
  MainHeader,
  RightHeader,
  HeaderStrip,
  LeftHeader,
  HeaderLink,
  HeaderLinkWrapper,
} from './style'
import ConnectWallet from '../Wallet/ConnectWallet'
import { Divider } from '../Divider'
// import { addAndSwitchNetwork } from '@/lib/crypto/network'
import { DEFAULT_CHAIN_ID } from '@/constants/web3'
import { Button, ButtonEnum } from '../Button'
import { Dropdown } from '../Dropdown'
import AuthWallet from '../Wallet/AuthWallet'
import { InviteCodeModal } from '../Wallet/InviteCodeModal'
import useAuthStore from '@/store/useAuthStore'
import InviteCodeBtn from '../Wallet/InviteCodeBtn'
import useAuth from '@/hooks/useAuth'
import { useWeb3 } from '@/hooks/useWeb3'
import { addAndSwitchNetwork } from '@/lib/crypto/network'
import { AppNoti } from '../AppNoti'
import { InfoModal } from '../InfoModal'
import { CenterHeader } from './CenterHeader'
// import { useWeb3Modal } from '@web3modal/ethers5/react'

export const Header = () => {
  return (
    <HeaderWrapper>
      <MainHeader>
        <LeftMainHeader />
        <CenterHeader />
        <RightMainHeader />
      </MainHeader>
      <HeaderStrip />
    </HeaderWrapper>
  )
}

const LeftMainHeader = () => {
  const location = useLocation()
  const isDesktopScreen = useIsBreakpoint('lg')
  const activePage = useMemo(() => {
    return location.pathname
  }, [location])

  return (
    <LeftHeader>
      <img src={SVGS.oneLineFpLogo} alt='fareplay-logo' />
      {isDesktopScreen && (
        <>
          <Divider />
          <HeaderLinkWrapper>
            {routeLinks.map((link, i) => (
              <HeaderLink
                isActive={link.to === activePage}
                key={link.to}
                transition={{
                  duration: 0.25,
                  delay: (i + 1) * 0.1,
                  ease: 'easeIn',
                }}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <Link
                  to={link.to as string}
                  target={link.to.startsWith('https') ? '_blank' : undefined}
                >
                  <img src={link.img} />
                </Link>
              </HeaderLink>
            ))}
          </HeaderLinkWrapper>
        </>
      )}
    </LeftHeader>
  )
}

const RightMainHeader = () => {
  const { isActive, chainId, provider, account } = useWeb3()
  const hasEnteredInviteCode = useAuthStore(state => state.hasEnteredInviteCode)
  const { authToken } = useAuth()

  const showConnectWallet = useMemo(() => !isActive || !account, [isActive, account])
  const leftButtonRenderer = useMemo(() => {
    if (chainId !== DEFAULT_CHAIN_ID) {
      return (
        <Button
          className='wrong-network-btn'
          isMinified
          onClick={() => addAndSwitchNetwork(provider)}
          buttonType={ButtonEnum.WARNING}
          disabled={chainId === DEFAULT_CHAIN_ID}
          loadingText={'SWITCHING NETWORK'}
          type='button'
        >
          SWITCH NETWORK
        </Button>
      )
    }

    if (!authToken) {
      return <AuthWallet />
    }

    if (!hasEnteredInviteCode) {
      return <InviteCodeBtn />
    }

    return null
  }, [chainId, provider, hasEnteredInviteCode, authToken])

  if (showConnectWallet || !authToken || !hasEnteredInviteCode) {
    return (
      <RightHeader>
        <AppNoti />
        <AccountWrapper>
          <ConnectWallet />
        </AccountWrapper>
      </RightHeader>
    )
  }

  return (
    <RightHeader>
      <AppNoti />
      <AccountWrapper>
        {leftButtonRenderer}
        <Dropdown />
        <InviteCodeModal />
        <InfoModal
          header='Welcome to Fareplay - Season of Beras'
          message={
            // @TODO: Update this message but not sure what the message will be for mainnet (updated to something for now but have to be looked at)
            'This is a public campaign where you can earn FARE points for wagering. Please report bugs in the #bug-reports channel in the Fareplay Discord. Good luck!'
          }
        />
      </AccountWrapper>
    </RightHeader>
  )
}
