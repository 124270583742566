import { currencyDecimalsMap, fareBankrollAddress } from '@/lib/crypto'
import { useCurrencyContracts } from './useCurrencyContracts'
import { useDebouncedCallback } from 'use-debounce'
import useCurrencyStore from '@/store/useCurrencyStore'
// import {
//   ApprovalEvent,
//   ApprovalEventFilter,
// } from '@/lib/crypto/typechain/contracts/IERC20Metadata.sol/IERC20'
import { TypedListener } from '@/lib/crypto/typechain/common'
import { utils } from 'ethers'
import { useWeb3 } from '@/hooks/useWeb3'
import { ApprovalEvent, ApprovalEventFilter } from '@/lib/crypto/typechain/USDCMock'

export const useAllowanceListner = () => {
  const setAllowance = useCurrencyStore(state => state.setAllowance)
  const { fetchAllowance, erc20ContractsWS } = useCurrencyContracts()
  const { account } = useWeb3()

  const debounceFetchAllowance = useDebouncedCallback(
    async (...args: Parameters<typeof fetchAllowance>) => {
      try {
        const allowance = await fetchAllowance(...args)
        console.log('allowance', allowance)
        setAllowance('usdc', allowance)
      } catch (err) {
        console.warn(err)
      }
    },
    250,
    { leading: true, maxWait: 2_000 }
  )

  useEffect(() => {
    if (!account || !erc20ContractsWS.usdc) return
    const usdcWS = erc20ContractsWS.usdc

    let approvalFromFilter: ApprovalEventFilter
    let approvalFromListener: TypedListener<ApprovalEvent>
    approvalFromFilter = usdcWS.filters.Approval(account, fareBankrollAddress, null)
    approvalFromListener = async (_owner, _spender, value) => {
      console.log(_owner, _spender, value)
      if (account) setAllowance('usdc', utils.formatUnits(value, currencyDecimalsMap.usdc))
    }
    usdcWS.on(approvalFromFilter, approvalFromListener)

    return () => {
      usdcWS.removeListener(approvalFromFilter, approvalFromListener)
    }
  }, [account, erc20ContractsWS])

  useEffect(() => {
    if (account && erc20ContractsWS.usdc && fareBankrollAddress) {
      debounceFetchAllowance(account, fareBankrollAddress, 'usdc')
    }
  }, [account, erc20ContractsWS])
}
