import { HTTP_URL } from '@/constants/http'
import { LOCAL_STORAGE } from '@/constants/utils'
import { faxios } from '@/lib/http/auth'
import { inviteCodesApi } from '@/lib/http/inviteCodes'
import useInviteCodeStore from '@/store/useInviteCodeStore'
import { useDebouncedCallback } from 'use-debounce'

export const PointsPageMounter = ({ publicAddress }: { publicAddress: string }) => {
  const fetchInviteCodes = useDebouncedCallback(
    async (addr: string) => {
      try {
        const inviteCodes = await inviteCodesApi.getInviteCodes(addr)
        useInviteCodeStore.getState().setInviteCodes({
          usedInviteCodes: inviteCodes.usedInviteCodes,
          activeInviteCodes: inviteCodes.activeInviteCodes,
        })
      } catch (err) {
        // @TODO: Add better error handling
        console.error(err)
      }
    },
    250,
    { leading: true }
  )

  const fetchPointsDetails = useDebouncedCallback(async (addr: string) => {
    try {
      const { data } = await faxios({
        method: 'GET',
        headers: {
          token: JSON.parse(
            (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
          )[addr],
        },
        url: `${HTTP_URL}/auth/fare-points/detail`,
      })
      console.log('received data: ', data)
      useInviteCodeStore.getState().setPointsDetails(data)
    } catch (err) {
      // @TODO: Add better error handling
      console.error(err)
    }
  }, 250)

  const fetchQuests = useDebouncedCallback(async (addr: string) => {
    try {
      const { data } = await faxios({
        method: 'GET',
        headers: {
          token: JSON.parse(
            (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
          )[addr],
        },
        url: `${HTTP_URL}/auth/quests`,
      })
      console.log('received data: ', data)
      useInviteCodeStore.getState().setQuests(data.quests)
      useInviteCodeStore.getState().setBonusQuests(data.bonusQuests)
    } catch (err) {
      // @TODO: Add better error handling
      console.error(err)
    }
  }, 250)

  useEffect(() => {
    fetchInviteCodes(publicAddress)
    fetchPointsDetails(publicAddress)
    fetchQuests(publicAddress)
  }, [publicAddress])

  return null
}
