import { ClickToCopyInviteCode } from '@/components/shared/ClickToCopy/ClickToCopyInviteCode'
import { LoadingChip } from '@/components/shared/LoadingChip'
import { BORDER_COLORS, FARE_COLORS, TEXT_COLORS } from '@/design'
import { deviceBP } from '@/design/breakpoints'
import { inviteCodesApi } from '@/lib/http/inviteCodes'
import useAuthStore from '@/store/useAuthStore'
import useInviteCodeStore from '@/store/useInviteCodeStore'
import { addAppNoti } from '@/store/useNotiStore'

const SInviteCodes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 18px;
  box-sizing: border-box;
  flex: 1;
  height: 100%;

  @media ${deviceBP.sm} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 1;
    min-height: 0;
  }
`

const SInput = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  text-align: center;
  width: 200px;
  height: 100%;
  border: 1px solid transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, rgb(255, 94, 79), rgb(65, 13, 255));
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: rgb(0, 0, 0) 2px 1000px 1px inset;
  flex: 2;
`

const SCopyBtn = styled.button`
  flex: 1;
  all: unset;
  cursor: pointer;
  /* border: 1px solid ${BORDER_COLORS.one}; */
  padding: 0px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 6px;
  /* background: #121212; */
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  height: 100%;
  transition: ease-in-out all 0.12s;
  border: 1px solid ${BORDER_COLORS.one};
  box-shadow: inset 0px 0px 56px ${FARE_COLORS.blue}00;

  &:hover {
    border: 1px solid ${FARE_COLORS.blue};
    box-shadow: inset 0px 0px 56px ${FARE_COLORS.blue}75;
  }
`

const SUserInfoWell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 4;
  cursor: pointer;
  border: 1px solid ${BORDER_COLORS.one};
  padding: 0px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 6px;
  background: transparent;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  height: 100%;
  &.assist-color {
    color: ${FARE_COLORS.peach};
  }
`
const SInviteCodeItem = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  height: 32px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &.is-used {
    ${SInput} {
      opacity: 0.7;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
        linear-gradient(259deg, rgb(255, 94, 79), rgb(65, 13, 255));
    }
  }

  @media ${deviceBP.sm} {
    min-height: 32px;
  }
`
interface IInviteCodeItem {
  code: string
  userAddress?: string
}

const UsedInviteCodeItem = ({ userAddress, points }: { userAddress: string; points: number }) => {
  const assists = useMemo(() => Math.floor(points / 3), [points])
  return (
    <SInviteCodeItem className='is-used'>
      {/* <SInput>{userAddress.substring(0, 10)}</SInput> */}
      <SUserInfoWell style={{ flex: 6, display: 'flex', justifyContent: 'space-between' }}>
        <span>{userAddress.substring(0, 10)}</span>
        <span>{points} PTS</span>
      </SUserInfoWell>
      <SUserInfoWell className='assist-color'>{assists} ASSISTS</SUserInfoWell>
    </SInviteCodeItem>
  )
}

const InviteCodeItem = ({ code, userAddress }: IInviteCodeItem) => {
  return (
    <SInviteCodeItem>
      <SInput>{code}</SInput>
      <ClickToCopyInviteCode copyText={code}>COPY CODE</ClickToCopyInviteCode>
      <ClickToCopyInviteCode copyText={`${window.location.host}/connect-wallet?inviteCode=${code}`}>
        COPY LINK
      </ClickToCopyInviteCode>
    </SInviteCodeItem>
  )
}

const SDivider = styled.div`
  flex: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 8px;
  text-transform: uppercase;
  color: white;
`

const SExplainText = styled.div`
  flex: 1;
  text-align: justify;
  margin-top: 8px;
  color: ${TEXT_COLORS.two};
`

const STextHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  height: 100%;
  position: relative;
  overflow: scroll;

  @media ${deviceBP.sm} {
    width: 100%;
  }
`

const SGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  height: 100%;
  width: 100%;
  /* overflow-y: auto; */
  /* position: absolute; */
  /* left: 0; */
  /* top: 48px; */
  @media ${deviceBP.sm} {
    max-height: 100px;
    overflow: auto;
    padding-top: 8px;
    padding-bottom: 12px;
    border-top: 1px solid ${FARE_COLORS.pink}50;
    border-bottom: 1px solid ${FARE_COLORS.pink}50;
  }
`

const SEmptyWell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  span {
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 12px 18px;
    border-radius: 6px;
    color: rgba(255, 255, 255, 0.6);
  }
`

const SRequestInviteCodesBtn = styled.button`
  all: unset;
  color: ${TEXT_COLORS.two};
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  text-align: center;
  width: 200px;
  height: 100%;
  border: 1px solid transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, rgb(255, 94, 79), rgb(65, 13, 255));
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: rgb(0, 0, 0) 2px 1000px 1px inset;
  flex: 2;

  &.has-requested {
    text-decoration-line: none;
    cursor: default;
  }
`

const InviteCodes = () => {
  const { activeInviteCodes, usedInviteCodes } = useInviteCodeStore()
  const inviteCodesRequested = useAuthStore(state => state.inviteCodesRequested)
  const [requestingCodes, setRequestingCodes] = useState(false)

  const requestInviteCodes = useCallback(async () => {
    try {
      setRequestingCodes(true)
      await inviteCodesApi.requestInviteCodes(useAuthStore.getState().authPublicAddress)
      useAuthStore.setState({ inviteCodesRequested: 1 })
      addAppNoti({
        type: 'success',
        msg: 'Invite codes requested',
      })
    } catch (err) {
      addAppNoti({
        type: 'error',
        msg: String(err),
      })
      throw new Error('Error while requesting invite code')
    } finally {
      setRequestingCodes(false)
    }
  }, [])

  const allInviteCodes = activeInviteCodes.map(val => val.code).join(', ')
  const allInviteLinks = activeInviteCodes
    .map(val => `${window.location.host}/connect-wallet?inviteCode=${val.code}`)
    .join('\n')

  return (
    <>
      <SInviteCodes>
        <SSectionContainer>
          <STextHeaderWrapper>
            <SDivider>INVITE CODES</SDivider>
            <SExplainText>
              Earn 10,000 mock PHONEY for every user you invite to Deathmatch. Earn an assist point
              for every three points earned by users you invite. Check back for new invite codes -
              we refresh them randomly.
            </SExplainText>
            <SInviteCodeItem style={{ height: '20px', marginTop: '12px' }}>
              {!requestingCodes && (
                <SRequestInviteCodesBtn
                  className={inviteCodesRequested > 0 ? 'has-requested' : ''}
                  onClick={inviteCodesRequested > 0 ? () => { } : requestInviteCodes}
                >
                  {inviteCodesRequested > 0 ? 'Codes requested.' : 'Request More Codes'}
                </SRequestInviteCodesBtn>
              )}
              <ClickToCopyInviteCode copyText={allInviteCodes}>COPY ALL</ClickToCopyInviteCode>
              <ClickToCopyInviteCode copyText={allInviteLinks}>COPY ALL</ClickToCopyInviteCode>
            </SInviteCodeItem>
          </STextHeaderWrapper>
          <SGridWrapper>
            {activeInviteCodes && activeInviteCodes.length !== 0 ?
              activeInviteCodes?.map((val, key) => <InviteCodeItem key={key} code={val.code} />)
            : <SEmptyWell>
                <span>NO CODES AVAILABLE. CHECK BACK LATER.</span>
              </SEmptyWell>
            }
          </SGridWrapper>
        </SSectionContainer>
        <SSectionContainer>
          <STextHeaderWrapper>
            <SDivider>INVITED USERS</SDivider>
            <SExplainText>
              Users who have joined with your invite codes and the Assist Points you have earned for
              their wagers. While your personal Kill Points and Death Points update in real time,
              your Assist points update approximately every 10 minutes.
            </SExplainText>
          </STextHeaderWrapper>
          <SGridWrapper>
            {usedInviteCodes && usedInviteCodes.length !== 0 ?
              usedInviteCodes?.map((val, key) => (
                <UsedInviteCodeItem
                  key={key}
                  userAddress={val.userAddress || ''}
                  points={val.userPoints}
                />
              ))
            : <SEmptyWell>
                <span>NO USERS INVITED. SHARE YOUR CODES.</span>
              </SEmptyWell>
            }
          </SGridWrapper>
        </SSectionContainer>
      </SInviteCodes>
    </>
  )
}

export default InviteCodes
