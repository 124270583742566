import html2canvas from 'html2canvas'
import { useDebouncedCallback } from 'use-debounce'
import { BACKGROUND_COLORS } from '@/design'
import axios from 'axios'
import { HTTP_URL } from '@/constants/http'
import useAuth from './useAuth'
import { faxios } from '@/lib/http/auth'
import { delay } from '@/utils'

export const useScreenshotElement = (captureRef: React.RefObject<HTMLDivElement>) => {
  const { authToken: token } = useAuth()

  const convertSvgToCanvas = async (targetElement: React.RefObject<HTMLDivElement>) => {
    if (!targetElement.current) return
    const svgs = targetElement.current.querySelectorAll('img')
    const promiseList: Promise<void>[] = []

    svgs.forEach((svg: HTMLImageElement) => {
      const quickFixSkullURL = 'deathmatch-colored'
      const quickFixDMURL = 'deathmatch-text-colored'
      const quickFixTwitterImg = 'https://pbs.twimg.com/profile_images/'
      if (
        svg.src.includes(quickFixDMURL) ||
        svg.src.includes(quickFixSkullURL) ||
        svg.src.includes(quickFixTwitterImg) ||
        svg.id === '0'
      )
        return

      promiseList.push(
        new Promise((resolve, reject) => {
          fetch(svg.src)
            .then(response => response.blob())
            .then(blob => {
              const reader = new FileReader()
              reader.onloadend = function () {
                const canvas = document.createElement('canvas')
                canvas.width = svg.width
                canvas.height = svg.height
                const ctx = canvas.getContext('2d')
                const DOMURL = window.URL.createObjectURL
                const img = new Image()
                const _url = DOMURL(blob)
                img.src = _url
                img.onload = function () {
                  ctx?.drawImage(img, 0, 0)
                  const png = canvas.toDataURL('image/png')
                  svg.src = png
                  window.URL.revokeObjectURL(png)
                  resolve()
                }
              }
              reader.readAsDataURL(blob)
            })
            .catch(reject)
        })
      )
    })

    return Promise.all(promiseList)
  }

  const screenshotElement = useDebouncedCallback(
    async () => {
      try {
        if (captureRef.current) {
          await convertSvgToCanvas(captureRef)
          await delay(2_000)
          const canvas = await html2canvas(captureRef.current, {
            imageTimeout: 0,
            useCORS: true,
            backgroundColor: BACKGROUND_COLORS.one,
            allowTaint: true,
            logging: true,
          })
          const image = canvas.toDataURL('image/png')
          if (image) {
            console.log(`${HTTP_URL}/auth/screenshot`)
            const formData = new FormData()
            formData.append('deathmatchImage', image)
            const response = await faxios({
              headers: {
                'Content-Type': 'multipart/form-data',
                token,
              },
              method: 'POST',
              url: `${HTTP_URL}/auth/screenshot`,
              data: formData,
            })
            return response.data as { linkToRedirect: string }
          } else {
            console.log('no image data: ', image)
          }
          // captureRef.current.style.background = BACKGROUND_COLORS.two
        }
      } catch (err) {
        console.log('error while screenshotting: ', err)
        console.error(err)
      }
    },
    500,
    { leading: true }
  )

  return screenshotElement
}
