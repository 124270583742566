import { useFrame } from '@react-three/fiber'
import { circOut } from 'framer-motion'
import {
  vec3,
  quat,
  type RapierRigidBody,
  RigidBody,
  type CollisionEnterHandler,
  type Vector3Object,
} from '@react-three/rapier'
import { useEffectOnce } from 'react-use'
import {
  type MeshPhysicalMaterial,
  Vector3,
  type Vector3Tuple,
  Clock,
  Quaternion,
  Euler,
  Color,
} from 'three'
import { Billboard, MeshWobbleMaterial, Text as TextDrei } from '@react-three/drei'
import { getRandomFloat } from '@/utils/3d'
import { DiceNumber, type DiceNumberProps } from './DiceNumber'
import numeral from 'numeral'
import { useDiceGameState } from '@/store/useGameStateStore'
import { useTextureCacheStore } from '@/store/useTextureCacheStore'
import { useShallow } from 'zustand/react/shallow'
import { DiceModelNew } from './DiceModelNew'
import { MultiDice } from './MultiDice'
import { entryEvent } from '@/events/entryEvent'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'
import DiceLandAudio from '@/assets/audio/_land.wav'
import DicePrepAudio from '@/assets/audio/Device 7 Start.wav'
import DiceResetAudio from '@/assets/audio/Device 7 Stop.wav'
import WinSoundAudio from '@/assets/audio/coins/Coins 11.wav'

const lerpVectors = (origin: Vector3, target: Vector3, t: number) => {
  const result = new Vector3()

  result.lerpVectors(origin, target, t)

  return { x: result.x, y: result.y, z: result.z } as Vector3Object
}

function quadratic(t: number, peakY: number): number {
  return 4 * peakY * t * (1 - t) // Quadratic function for smooth rise and fall
}

// Types
type TransitionState<T> = {
  target: T
  duration: number
  time?: number
}
type OriginState<T> = {
  start: T
  current: T
}
type AnimationTransitionState<T> = TransitionState<T> & OriginState<T>
type AnimateVec3State = AnimationTransitionState<Vector3>
type AnimateQuatState = AnimationTransitionState<Quaternion>

const fallbackTargetVec3: Vector3Tuple = [0, 1, -2.5] as const

type DiceReactorComponentProps = {
  startPos: Vector3
}

export const DiceController = ({ startPos }: DiceReactorComponentProps) => {
  /* State */
  const [multiDiceState, setMultiDiceState] = useState<'initial' | 'start' | 'resolve'>('initial')
  const [multiDiceCount, setMultiDiceCount] = useState(0)
  const setBorderActive = useGameOutcomeStore(state => state.setIsShowingOutcome)
  const setPlayerWon = useGameOutcomeStore(state => state.setDidPlayerWin)
  const setWinIntensity = useGameOutcomeStore(state => state.setIntensity)

  // Cached textures
  const loadTextures = useTextureCacheStore(state => (state as any).loadTextures)
  const fareWhiteTexture = useTextureCacheStore(state => (state as any).fareWhiteTexture)
  const fareGradientTexture = useTextureCacheStore(state => (state as any).fareGradientTexture)

  /* Refs */
  const rbRef = useRef<RapierRigidBody>(null!)
  const hasCollided = useRef<boolean>(false)

  // const diceMeshRef = useRef<Mesh>(null!)d
  const diceRefs = useRef<{
    diceMeshRef: any
    diceMatRef: any
    diceFrameMatRef: any
    emitFromFrame: boolean
  }>({
    diceMeshRef: null,
    diceMatRef: null,
    diceFrameMatRef: null,
    emitFromFrame: false,
  })

  const animClock = useRef<Clock>(new Clock(false))
  const animVec3Transition = useRef<AnimateVec3State>()
  const animQuatTransition = useRef<AnimateQuatState>()
  const textRef = useRef<DiceNumberProps>(null!)
  const resultTextRef = useRef<DiceNumberProps>(null!)
  // const vec3Pointer = useRef<Vector3>(new Vector3(0, 0, 0))
  // const [isPointerDown, setIsPointerDown] = useState(false)
  const { send, results, gameState, side, submittedEntry } = useDiceGameState(
    useShallow(state => ({
      send: state.send,
      results: state.results,
      gameState: state.type,
      side: state.submittedEntry ? state.submittedEntry.side / 100 : state.entry.side,
      submittedEntry: state.submittedEntry,
    }))
  )

  const soundContext = useSound()

  useEffect(() => {
    soundContext.loadSound('diceLand', DiceLandAudio)
    soundContext.loadSound('dicePrep', DicePrepAudio)
    soundContext.loadSound('diceReset', DiceResetAudio)
    soundContext.loadSound('winSound', WinSoundAudio)
  }, [soundContext, soundContext.loadSound])

  /* Memos */
  const winColor = useMemo(() => new Color(0.1, 1, 0.1), [])
  const loseColor = useMemo(() => new Color(1, 0.1, 0.1), [])

  /* Callbacks */
  const getNormalizedZFromNumber = (value: number) => {
    const padding = 3
    const totalWorldRange = 6
    const totalRange = 94.9
    const normalizedSide = Number((value - 5).toFixed(2))

    const percentage = normalizedSide / totalRange
    const worldPercentage = percentage * totalWorldRange - 3

    const clampedX = Math.max(-padding, Math.min(worldPercentage, padding))
    return clampedX + 0.15
  }

  const loadDice = (targetVec3: Vector3Tuple) => {
    const { current: rb } = rbRef
    if (rb.isEnabled()) rb.setEnabled(false)

    soundContext.playSound('dicePrep', 0.2, 0.75)

    animVec3Transition.current = {
      duration: 1,
      target: new Vector3(...targetVec3),
      current: vec3(rb.translation()),
      start: vec3(rb.translation()),
    }

    textRef.current.visible = false

    if (animClock.current.running) animClock.current.stop()
    animClock.current.start()
  }

  const idleDice = () => {
    textRef.current.visible = true
    resultTextRef.current.visible = false
  }

  const throwDice = (resolveNumber: number) => {
    const { current: rb } = rbRef
    console.log('resolved', resolveNumber)

    // const randomImpulse = Math.max(0.01, Math.abs(getRandomFloat(-0.18, 0.18)))
    const randomImpulse = Math.max(0.1, Math.abs(getRandomFloat(-0.35, 0.35)))

    animVec3Transition.current = undefined

    rb.setEnabled(true)
    setTimeout(() => {
      rb.applyImpulse(new Vector3(0, 1.25, 0.6), true)
      rb.applyTorqueImpulse(new Vector3(0, randomImpulse, -randomImpulse), true)
    }, 10)
  }

  const resetDice = () => {
    const { current: rb } = rbRef
    hasCollided.current = false
    if (rb.isEnabled()) rb.setEnabled(false)
    setMultiDiceState('initial')
    setMultiDiceCount(0)
    soundContext.playSound('diceReset', 0.2, 0.5)

    diceRefs.current.diceMatRef.emissiveMap = fareGradientTexture
    diceRefs.current.diceMatRef.emissive = new Color(1, 1, 1)

    // Deactivate Border
    setBorderActive(false)
    setWinIntensity(1)

    resultTextRef.current.visible = false

    animVec3Transition.current = {
      duration: 1.25,
      target:
        new Vector3(getNormalizedZFromNumber(side), 0.5, 0) || startPos || new Vector3(0, 0, 0),
      start: vec3(rb.translation()),
      current: vec3(rb.translation()),
    }
    animQuatTransition.current = {
      duration: 1.25,
      target: new Quaternion().setFromEuler(new Euler(0, 0, 0)),
      current: quat(rb.rotation()),
      start: quat(rb.rotation()),
    }

    animClock.current.start()
  }

  const errorDice = (errMsg: string) => {
    const { current: rb } = rbRef
    if (rb.isEnabled()) rb.setEnabled(false)
    console.log('errorDice | errMsg:', errMsg)
    diceRefs.current && diceRefs.current.diceMeshRef.rotation.set(0, 0, 0)
    animQuatTransition.current = {
      duration: 1.25,
      target: new Quaternion().setFromEuler(new Euler(0, 0, 0)),
      start: diceRefs.current.diceMeshRef.quaternion.clone(),
      current: diceRefs.current.diceMeshRef.quaternion.clone(),
    }
    send({ type: 'RESET', payload: {} })
  }

  useEffect(() => {
    loadTextures()
  }, [loadTextures])

  useEffect(() => {
    switch (gameState) {
      case 'IDLE':
        idleDice()
        break
      case 'START':
        const diceCount = useDiceGameState.getState().submittedEntry?.entryCount
        if (Number(diceCount) > 1) {
          loadDice([0, 5, -2.5])
          setMultiDiceCount(Number(diceCount))
        } else {
          loadDice(fallbackTargetVec3)
        }
        break
      case 'RESOLVE':
        const resultNumber = useDiceGameState.getState().results?.resultSides as number[]
        const _resolveCount = useDiceGameState.getState().submittedEntry?.entryCount

        if (_resolveCount === 1) {
          throwDice(resultNumber[0] || 0)
        } else {
          setMultiDiceState('resolve')
        }

        break
      case 'RESET':
        if (multiDiceState === 'initial') {
          resetDice()
        } else {
          // console.log('reset dice here')
        }

        break
      case 'ERROR':
        errorDice('Error has occurred')
        break
      default:
        break
    }
  }, [gameState])

  /* Fiber */
  useFrame(({ pointer, camera }) => {
    const rb = rbRef.current
    const animVec3 = animVec3Transition.current
    const animQuat = animQuatTransition.current
    const aClock = animClock.current

    /* Start Animation */
    if (gameState === 'START' && aClock.running && animVec3) {
      const t = Math.min(animClock.current.getElapsedTime() / animVec3.duration, 1)
      const easedT = circOut(t)

      const newVec3 = lerpVectors(animVec3.start, animVec3.target, easedT)
      newVec3.y += quadratic(t, 0.6) // Add quadratic function for smooth rise and fall
      rb.setTranslation(newVec3, true)
      const newRot = Math.PI * 2 * easedT

      diceRefs.current.diceMeshRef.parent.rotation.y = newRot // Rotate group so all meshes rotate together

      if (t >= 1) {
        aClock.stop()
      }
    }

    /* Reset Animation */
    if (gameState === 'RESET' && aClock.running && animVec3 && animQuat) {
      const t = Math.min(animClock.current.getElapsedTime() / animVec3.duration, 1)
      const easedT = circOut(t)

      // Interpolate position for x and z, and adjust y
      const newVec3 = lerpVectors(animVec3.start, animVec3.target, easedT)
      newVec3.y += quadratic(t, 1.5) // Add quadratic function for smooth rise and fall

      // Interpolate quaternion
      const newQuat = animQuat.current.slerpQuaternions(animQuat.start, animQuat.target, easedT)

      rb.setTranslation(newVec3, true)
      rb.setRotation(newQuat, true)

      diceRefs.current.diceMatRef.emissiveIntensity = 1 + 4 * Math.abs(easedT - 1)
      diceRefs.current.emitFromFrame &&
        (diceRefs.current.diceFrameMatRef.emissiveIntensity = 1 + 4 * Math.abs(easedT - 1))

      if (t >= 1) {
        aClock.stop()
        // rb.setEnabled(true)
        send({ type: 'IDLE', payload: {} })
      }
    }

    /* Resolve */
    if (gameState === 'RESOLVE') {
      if (resultTextRef.current && resultTextRef.current.position) {
        const resultText = resultTextRef.current as any
        const pos = rb.translation()
        resultText.position.set(pos.x - 0, pos.y + 1, pos.z + 0)
        resultText.lookAt(camera.position)
      }
    }

    /* Handle Input */
    // if (gameState === 'IDLE' && isPointerDown) {
    //   const padding = 3
    //   // Calculate the direction from the camera to the pointer position in world space
    //   const dir = vec3Pointer.current
    //     .set(pointer.x, pointer.y, 0)
    //     .unproject(camera)
    //     .sub(camera.position)
    //     .normalize()

    //   // Calculate the distance to the plane z = 0
    //   const distance = -camera.position.z / dir.z

    //   // Calculate the point in world space that corresponds to the pointer position
    //   const pos = dir.multiplyScalar(distance).add(camera.position)

    //   // Clamp the x position within the padding limits
    //   const clampedX = Math.max(-padding, Math.min(pos.x, padding))

    //   rb.setTranslation({ x: clampedX, y: 0.5, z: 0 }, true)

    //   // if (tick.current % 20 === 0) {
    //   //   const centeredX = clampedX + padding
    //   //   const normalizedX = centeredX / (padding * 2)
    //   //   let newSideNumber
    //   //   if (normalizedX <= 0.5) {
    //   //     newSideNumber = lerp(5, 50.5, normalizedX * 2)
    //   //   } else {
    //   //     newSideNumber = lerp(50.5, 99.9, (normalizedX - 0.5) * 2)
    //   //   }
    //   //   selectedNumberSig.value = Number(newSideNumber)
    //     // useDiceGameState.setState({ side: Number(newSideNumber) })
    //   // }
    // }
  })

  // useControls(
  //   'Dice Controls',
  //   {
  //     ['Load Dice']: button(() => send('Start', { position: fallbackTargetVec3 })),
  //     ['Throw Dice']: button(() =>
  //       send('Resolve', { sideNumber: Number((Math.random() * 100).toFixed(2)) })
  //     ),
  //     ['Reset Dice']: button(() => send('Reset')),
  //     ['Error Dice']: button(() => {
  //       send('Error', { errMsg: 'Dice game could not start' })
  //       // setTimeout(() => {
  //       //   send('Reset')
  //       // }, 200)
  //     }),
  //   },
  //   [send]
  // )

  /* Effects */
  useEffectOnce(() => {
    rbRef.current.setTranslation(startPos.clone(), true)
  })

  useEffect(() => {
    // @TODO: UNCOMMENT ME
    if (textRef.current) {
      textRef.current.text = numeral(side).format('00.00')
    }
  }, [side])

  useEffect(() => {
    const rb = rbRef.current
    if (rb.isEnabled()) rb.setEnabled(false)
    // if (gameState === 'IDLE' && isPointerDown) return
    if (gameState === 'RESET' && animVec3Transition.current) {
      animVec3Transition.current.target = new Vector3(getNormalizedZFromNumber(side), 0.5, 0)
    }
    if (gameState !== 'IDLE') return

    const clampedX = getNormalizedZFromNumber(side)
    rb.setTranslation({ x: clampedX, y: 0.5, z: 0 }, true)

    // selectedNumberSig.value = Number(side)
  }, [side])

  /* Events */
  // const onPointerDown = (e: any) => {
  //   e.stopPropagation()
  //   e.target.setPointerCapture(e.pointerId)
  //   setIsPointerDown(true)
  //   rbRef.current.setEnabled(false)
  // }

  // const onPointerUp = (e: any) => {
  //   e.stopPropagation()
  //   e.target.releasePointerCapture(e.pointerId)
  //   rbRef.current.setEnabled(true)
  //   setIsPointerDown(false)
  // }

  const onCollisionEnter: CollisionEnterHandler = payload => {
    if (gameState === 'IDLE' && !textRef.current.visible) {
      textRef.current.visible = true
    }
    if (gameState === 'RESOLVE' && results) {
      if (hasCollided.current) return
      hasCollided.current = true
      const resultSide = results.resultSides[0]
      const didWin = resultSide / 100 > side
      const _color = didWin ? winColor : loseColor
      const _intensitiy = didWin ? 2.3 : 5.9

      diceRefs.current.diceMatRef.emissiveMap = fareWhiteTexture
      diceRefs.current.diceMatRef.emissive = _color
      diceRefs.current.diceMatRef.emissiveIntensity = _intensitiy

      // Activate border feedback
      setBorderActive(true)
      // Play SFX here
      soundContext.playSound('diceLand', 0.2)
      setPlayerWon(didWin)
      if (didWin) {
        if (side <= 35) setWinIntensity(1)
        else if (side <= 55) setWinIntensity(2)
        else if (side <= 85) setWinIntensity(3)
        else if (side <= 90) setWinIntensity(4)
        else setWinIntensity(5)

        soundContext.playSound('winSound', 1)
      }

      resultTextRef.current.text = numeral(resultSide / 100).format('00.00')
      resultTextRef.current.color = _color
      ;(resultTextRef.current.material as MeshPhysicalMaterial).emissive = _color
      ;(resultTextRef.current.material as MeshPhysicalMaterial).emissiveIntensity = _intensitiy
      resultTextRef.current.visible = true

      if (submittedEntry && submittedEntry.entryAmount) {
        entryEvent.pub('entryFinished', {
          deltaAmount: results.rewards[0] - submittedEntry.entryAmount / submittedEntry.entryCount,
        })
        entryEvent.pub('updateBalance', {
          balanceUpdateDelay: 0,
          totalDeltaNumber:
            results.totalRewards - submittedEntry.entryAmount / submittedEntry.entryCount,
        })
        setTimeout(() => {
          entryEvent.pub('gameFinished')
        }, 2_100)
      }
    }
  }

  return (
    <>
      <TextDrei
        ref={resultTextRef}
        fontSize={0.5}
        position-y={1}
        visible={true}
        characters='.0123456789'
        font='/fonts/Gohu/gohu-small-uni-14.ttf'
      >
        <meshStandardMaterial attach={'material'} color={'white'} />
        <MeshWobbleMaterial factor={0.75} speed={1.25} />
      </TextDrei>
      <RigidBody ref={rbRef} canSleep restitution={0.21} onCollisionEnter={onCollisionEnter}>
        <Billboard follow>
          <DiceNumber ref={textRef as any} position-y={0.65} fontSize={0.5} visible={false} />
        </Billboard>
        <DiceModelNew ref={diceRefs} />
      </RigidBody>
      {multiDiceCount > 0 && (
        <MultiDice amount={multiDiceCount} multiDiceState={multiDiceState} resetDice={resetDice} />
      )}
    </>
  )
}
