import CountUp from 'react-countup'
import { useForm, Controller } from 'react-hook-form'
import {
  Form,
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormTab,
  SFormSection,
} from '@/components/shared/Form/style'
import { SVGS } from '@/assets'
import numeral from 'numeral'
import { type ISUContractForm } from '@/store/useSUContractStore'
import { RPSSelection } from '@/lib/crypto/rps'
import { useRPSGameState } from '@/store/useGameStateStore'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useShallow } from 'zustand/react/shallow'
import { BORDER_COLORS } from '@/design'
import { GameButton } from '../shared/Button/GameButton'
import { FareNumberInput } from '../shared/Input/FareNumberInput'
import { ModeButton } from '../shared/Button/style'
import { clamp, ensureNumber } from '@/utils'
import { useSound } from '../shared/SoundSystem/SoundContext'
import mouseOverSliderAudio from '@/assets/audio/mouse-over-slider.wav'

export const cssContainer = css`
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
`

const SForm = styled(Form)`
  ${cssContainer};
`

const SFormTab = styled(FormTab)`
  gap: 9px;
`

const SDoubleInputRow = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  flex-direction: row;
  transition: 0.35s all ease-in-out;
  ${props =>
    props.$isDisabled &&
    css`
      filter: grayscale(100%) brightness(0.8);
      cursor: not-allowed;
      pointer-events: none;
    `}
`

export const RPSForm = () => {
  const { selectedBalance } = useCurrencyStore(
    useShallow(state => ({
      selectedBalance: state.balances.usdc,
    }))
  )

  const balanceNumber = useMemo(() => Number(selectedBalance) || 0, [selectedBalance])

  const { control, register, watch } = useForm<ISUContractForm>({
    defaultValues: {
      side: 0,
      entryAmount: balanceNumber < 100 ? balanceNumber : 100,
      numberOfEntries: 1,
      stopGain: 0,
      stopLoss: 0,
    },
  })
  const formData = watch()
  const { entryAmount, numberOfEntries, stopLoss, stopGain } = formData
  const { selectedSide, setSelectedSide } = useRPSGameState(state => ({
    selectedSide: state.entry.side,
    setSelectedSide: (side: number) => state.setEntry({ side }),
  }))

  const entryAmountNum = numeral(entryAmount).value() || 0
  const stopLossNum = numeral(stopLoss).value() || 0
  const stopGainNum = numeral(stopGain).value() || 0

  const { loadSound, playSound } = useSound()

  useEffect(() => {
    loadSound('selectionClick', mouseOverSliderAudio)
  }, [loadSound])

  const handleModeButtonClick = useCallback(
    (side: RPSSelection) => {
      if (side !== selectedSide) {
        setSelectedSide(side)
        playSound('selectionClick', 0.1, 0.3)
      }
    },
    [selectedSide, playSound, setSelectedSide]
  )

  return (
    <SForm>
      <SFormTab $tabs={3}>
        <ModeButton
          $isActive={selectedSide === RPSSelection.Rock}
          onClick={() => handleModeButtonClick(RPSSelection.Rock)}
        >
          <span>Rock</span>
        </ModeButton>
        <ModeButton
          $isActive={selectedSide === RPSSelection.Paper}
          onClick={() => handleModeButtonClick(RPSSelection.Paper)}
        >
          <span>Paper</span>
        </ModeButton>
        <ModeButton
          $isActive={selectedSide === RPSSelection.Scissors}
          onClick={() => handleModeButtonClick(RPSSelection.Scissors)}
        >
          <span>Scissors</span>
        </ModeButton>
      </SFormTab>
      <form>
        <SFormSection>
          <FormLabelRow>
            <FormLabel>
              <FormIndicator $isActive={entryAmountNum > 0} />
              <FormAmountLabel>Amount</FormAmountLabel>
            </FormLabel>
            <FormLabel>
              <FormAmountLabel>
                {Number(selectedBalance) > 0 && (
                  <FormAmountLabel>
                    <CountUp
                      end={Number(selectedBalance)}
                      decimals={2}
                      duration={2}
                      separator={','}
                      preserveValue
                    />
                  </FormAmountLabel>
                )}
              </FormAmountLabel>
            </FormLabel>
          </FormLabelRow>
          <Controller
            {...register('entryAmount', { required: true, max: 1_000_000_000, min: 0 })}
            control={control}
            defaultValue={0}
            render={({ field }) => {
              const value = numeral(field.value).value() || 0
              const isDisabled = (numeral(selectedBalance).value() || 0) === 0

              return (
                <FareNumberInput
                  {...field}
                  onChange={event => field.onChange(numeral(event.target.value).value() as any)}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  isAllowed={({ floatValue = 0 }) => {
                    return floatValue <= 1_000_000_000
                  }}
                  onFocus={event => event.target.select()}
                  hasInputSlider
                  disabled={isDisabled}
                  inputSliderProps={{
                    value,
                    onChange: sliderValue => field.onChange(sliderValue),
                    min: 0,
                    max: Number(selectedBalance),
                    step: 1,
                  }}
                  thousandSeparator=','
                  inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                />
              )
            }}
          />
          {/* {row.error} ERROR SHOULD BE HERE */}
        </SFormSection>
        <SFormSection>
          <FormLabelRow>
            <FormLabel $isRelative>
              <FormIndicator $isActive={numberOfEntries > 0} style={{ left: -12, top: 4 }} />
              <FormAmountLabel>Number of Entries</FormAmountLabel>
            </FormLabel>
            <FormLabel>
              {Number(entryAmount / (numberOfEntries || 1)) > 0 && (
                <FormAmountLabel>
                  <CountUp
                    end={Number(entryAmount / (numberOfEntries || 1))}
                    decimals={2}
                    duration={0.18}
                    separator={','}
                    preserveValue
                  />
                  <span>&nbsp;/ entry</span>
                </FormAmountLabel>
              )}
            </FormLabel>
          </FormLabelRow>
          <Controller
            {...register('numberOfEntries')}
            control={control}
            defaultValue={1}
            render={({ field }) => {
              const MIN = 1
              const MAX = 20
              const value = numeral(field.value).value()
              const clampedValue = clamp(ensureNumber(value), MIN, MAX)
              const { ref, ...props } = field
              return (
                <FareNumberInput
                  {...field}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  isAllowed={({ floatValue = 0 }) => {
                    return floatValue <= 20
                  }}
                  onFocus={event => event.target.select()}
                  hasInputSlider
                  inputSliderProps={{
                    value: clampedValue,
                    onChange: sliderValue => field.onChange(sliderValue),
                    min: 1,
                    max: 20,
                    step: 1,
                  }}
                  thousandSeparator=','
                />
              )
            }}
          />
        </SFormSection>
        <SDoubleInputRow $isDisabled={stopLossNum === 0}>
          <SFormSection>
            <FormLabelRow>
              <FormLabel $isRelative>
                <FormIndicator $isActive={stopLossNum > 0} style={{ left: -12, top: 4 }} />
                <FormAmountLabel>Stop Loss</FormAmountLabel>
              </FormLabel>
            </FormLabelRow>
            <Controller
              {...register('stopLoss')}
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <FareNumberInput
                  {...field}
                  onChange={event => field.onChange(event.target.value as any)}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  isAllowed={({ floatValue = 0 }) => {
                    return floatValue <= 1_000_000_000
                  }}
                  onFocus={event => event.target.select()}
                  thousandSeparator=','
                  inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                />
              )}
            />
          </SFormSection>
          <SFormSection>
            <FormLabelRow>
              <FormLabel $isRelative>
                <FormIndicator $isActive={stopGainNum > 0} style={{ left: -12, top: 4 }} />
                <FormAmountLabel>Stop Gain</FormAmountLabel>
              </FormLabel>
            </FormLabelRow>
            <Controller
              {...register('stopGain')}
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <FareNumberInput
                  {...field}
                  onChange={event => field.onChange(event.target.value as any)}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  isAllowed={({ floatValue = 0 }) => {
                    return floatValue <= 1_000_000_000
                  }}
                  onFocus={event => event.target.select()}
                  thousandSeparator=','
                  inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                />
              )}
            />
          </SFormSection>
        </SDoubleInputRow>
        <FormFooter>
          <GameButton
            formData={{ ...formData, side: selectedSide }}
            entryAmountNum={entryAmountNum}
          />
        </FormFooter>
      </form>
    </SForm>
  )
}
