import { type StateCreator } from 'zustand'
import { type GameLiveEntryContext, type GameLiveEntrySlice } from './index.types'
import useLiveEntriesStore from '@/store/useLiveEntriesStore'
import useUserDataStore from '@/store/useUserDataStore'
import { BigNumber } from 'ethers'
import { addAppNoti } from '@/store/useNotiStore'
import { skulls } from '@/assets/svg/deathmatch-skulls'
import useBankrollStore from '@/store/useBankrollStore'
import { fsocket } from '@/lib/fsocket'
import { MAX_LIVE_ENTRIES } from '@/constants/ws'

export const initialGameLiveEntryState: GameLiveEntryContext = {
  inProgressLiveEntry: null,
  submittedTxHash: '',
  liveEntryKey: null,
} as const

export const createGameLiveEntrySlice: StateCreator<
  GameLiveEntrySlice,
  [],
  [],
  GameLiveEntrySlice
> = set => ({
  ...initialGameLiveEntryState,
  liveEntry: initialGameLiveEntryState,
  setInProgressLiveEntry: (inProgressLiveEntry, liveEntryKey) =>
    set(state => ({
      ...state,
      liveEntry: { ...state.liveEntry, inProgressLiveEntry, liveEntryKey },
    })),
  setSubmittedTxHash: submittedTxHash =>
    set(state => ({ ...state, liveEntry: { ...state.liveEntry, submittedTxHash } })),
  clear: () => set(state => ({ ...state, liveEntry: { ...initialGameLiveEntryState } })),
  insertLiveEntry: () =>
    set(state => {
      if (state.liveEntry.inProgressLiveEntry && state.liveEntry.liveEntryKey) {
        if (fsocket.liveEntry.proxyState.liveEntries.length >= MAX_LIVE_ENTRIES) {
          fsocket.liveEntry.proxyState.liveEntries.splice(
            fsocket.liveEntry.proxyState.liveEntries.length - 1,
            1
          )
        }

        fsocket.liveEntry.proxyState.liveEntries.unshift(state.liveEntry.inProgressLiveEntry)

        useUserDataStore.getState().addLatestSUEntries(state.liveEntry.inProgressLiveEntry)

        const deltaAmount = BigNumber.from(state.liveEntry.inProgressLiveEntry.deltaAmount)
        if (deltaAmount.gt(0)) {
          // Increase accWinningAmount
          useUserDataStore.getState().increaseAccWinningAmount(deltaAmount)
        } else if (deltaAmount.lt(0)) {
          // Increase accLosingAmount
          useUserDataStore.getState().increaseAccLosingAmount(deltaAmount.abs())
        }

        return { ...state, liveEntry: { ...initialGameLiveEntryState } }
      }

      return state
    }),
})
