import useAuth from '@/hooks/useAuth'
import { Redirect } from '../Routing/Redirect'
import { useEffectOnce } from 'react-use'
import { useDebouncedCallback } from 'use-debounce'
import { useQuery } from '@/hooks/useQuery'
import { addAppNoti } from '@/store/useNotiStore'

const LinkDiscord = () => {
  const { authApi } = useAuth()
  const [loading, setLoading] = useState(true)
  const query = useQuery()

  // use useEffect and send a request to backend with both discord accessToken and auth token to verify both discord and metamask account
  // after that redirect to settings
  // @TODO: Works as expected if it successfully links discord account with publicAddress. But does not handle errors yet
  useEffectOnce(() => {
    linkDiscord()
  })

  const linkDiscord = useDebouncedCallback(async () => {
    // if (!authPublicAddress) {
    //   console.log('should auth wallet first')
    //   await authenticate()
    //   console.log('authed wallet, now it can link wallet')
    //   return
    // }
    //   await linkTwitter(authPublicAddress)
    try {
      const discordToken = query.get('discordToken')
      console.log('DISCORD: discorfToken from query: ', discordToken)
      await authApi.linkDiscord(discordToken || '')
    } catch (err) {
      addAppNoti({
        msg: (err as any).response.data.message,
        type: 'error',
      })
      throw new Error(`Error linking discord`)
    } finally {
      setLoading(false)
    }
  }, 15)

  return loading ? <div>linking discord...</div> : <Redirect path='/deathmatch' />
}

export default LinkDiscord
