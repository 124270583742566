import { useWeb3 } from '@/hooks/useWeb3'
import { useHowl, Play } from 'rehowl'
import useAuth from '@/hooks/useAuth'
import useAuthStore from '@/store/useAuthStore'
import SpeakerSVG from './SpeakerSVG'
import LockoutConnect from './LockoutConnect'
import { InviteCodeModal } from '@/components/shared/Wallet/InviteCodeModal'
import { useDebouncedCallback } from 'use-debounce'
import { AppNoti } from '@/components/shared/AppNoti'
import { PNGS, SVGS } from '@/assets'

const SLockoutPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: black;
  position: relative;
`

const SMuteBtn = styled.button`
  all: unset;
  position: absolute;
  bottom: 48px;
  right: 48px;
  z-index: 999;

  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
  }
`

const SFareLogo = styled.div`
  display: flex;
  position: fixed;
  left: 32px;
  top: 32px;
  > img {
    height: 18px;
    width: auto;
  }
`

const rsSongs = [
  'Adventure.mp3',
  'SeaShanty2.mp3',
  'Scape Main.mp3',
  'Flute Salad.mp3',
  'Duel Arena.mp3',
]
const randomRsSong = rsSongs[Math.floor(Math.random() * rsSongs.length)]

const PlaySmashMouth = ({ muted }: { muted: boolean }) => {
  const { howl, state } = useHowl({ src: `/mp3/${randomRsSong}` })
  return <Play howl={howl} mute={muted} volume={0.5} />
}

// {/* <SMuteBtn onClick={() => useAuthStore.setState({ hasEnteredInviteCode: true })}> */}
const MuteBtn = ({ muted, setMuted }: { muted: boolean; setMuted: (muted: boolean) => void }) => {
  return (
    <SMuteBtn onClick={() => setMuted(!muted)}>
      <SpeakerSVG fill={muted ? 'white' : '#aaaaaa'} />
    </SMuteBtn>
  )
}

const SmashMouthSong = () => {
  const [muted, setMuted] = useState(false)

  return (
    <>
      <MuteBtn muted={muted} setMuted={setMuted} />
      <PlaySmashMouth muted={muted} />
    </>
  )
}

const SDisconnectBtn = styled.button`
  all: unset;
  color: #aaaaaa;
  font-size: 16px;
  text-decoration-line: underline;
  cursor: pointer;
  position: absolute;
  right: 48px;
  top: 48px;
  z-index: 999;
`

const SRouletteTable = styled.div`
  position: fixed;
  z-index: 0;
  > img {
    width: 600px;
    height: 600px;
  }
`

const DisconnectBtn = ({ deactiveAndLogout }: { deactiveAndLogout: any }) => {
  return <SDisconnectBtn onClick={deactiveAndLogout}>disconnect</SDisconnectBtn>
}

const SNotiWrapper = styled.div`
  position: fixed;
  top: 18px;
  right: 24px;
`

export const LockoutPage = () => {
  const redirect = useNavigate()
  const { isActive, chainId, provider, account, disconnect, isActivating } = useWeb3()
  const { authToken, authenticate, authPublicAddress, verify } = useAuth()
  const {
    setIsShowingInviteCodeModal,
    isShowingInviteCodeModal,
    hasEnteredInviteCode,
    setHasVerifiedPrivateRoute,
    hasVerifiedPrivateRoute,
  } = useAuthStore(state => ({
    setIsShowingInviteCodeModal: state.setIsShowingInviteCodeModal,
    isShowingInviteCodeModal: state.isShowingInviteCodeModal,
    hasEnteredInviteCode: state.hasEnteredInviteCode,
    hasVerifiedPrivateRoute: state.hasVerifiedPrivateRoute,
    setHasVerifiedPrivateRoute: state.setHasVerifiedPrivateRoute,
  }))

  const deactivate = useCallback(async () => {
    if (!isActive) return

    await disconnect()
  }, [isActive, disconnect])

  const deactiveAndLogout = useCallback(async () => {
    try {
      console.log('called disconnect')
      await deactivate()
      // navigate('/')
      window.location.reload()
    } catch (err) {
      console.error(err)
    }
  }, [deactivate])

  const showConnectWallet = useMemo(
    () => !isActive || !account || !authToken,
    [isActive, account, authToken]
  )

  const onSuccessInviteCode = useCallback(() => {
    // redirect('/deathmatch')
  }, [])

  const debounceVerify = useDebouncedCallback(
    async () => {
      try {
        await verify()
        setHasVerifiedPrivateRoute(true)
      } catch (err) {
        console.error(err)
        // await authenticate()
      }
    },
    250,
    { leading: true }
  )

  useEffect(() => {
    if (isActive && !authPublicAddress && provider) {
      console.log('hittt')
      debounceVerify()
    }
  }, [isActive, authPublicAddress, provider])

  useEffect(() => {
    if (isActive && authPublicAddress && !hasEnteredInviteCode) {
      setIsShowingInviteCodeModal(true)
    }
  }, [authPublicAddress, isActive])

  useEffect(() => {
    if (hasEnteredInviteCode && hasVerifiedPrivateRoute) {
      redirect('/deathmatch')
    }
  }, [hasEnteredInviteCode, hasVerifiedPrivateRoute])

  return (
    <SLockoutPage>
      <SRouletteTable>
        <img alt='Roulette table' src={PNGS.rouletteTable} />
      </SRouletteTable>
      <SFareLogo>
        <img alt='fareplay logo' src={SVGS.oneLineFpLogo} />
      </SFareLogo>
      <SNotiWrapper>
        <AppNoti />
      </SNotiWrapper>
      {showConnectWallet ?
        <LockoutConnect
          provider={provider}
          verify={debounceVerify}
          auth={authenticate}
          account={account}
          isActive={isActive}
          isActivating={isActivating}
          disconnect={disconnect}
        />
      : <DisconnectBtn deactiveAndLogout={deactiveAndLogout} />}
      <SmashMouthSong />
      {!showConnectWallet && (
        <InviteCodeModal isCloseable={false} onSuccess={onSuccessInviteCode} />
      )}
    </SLockoutPage>
  )
}
