import { useShallow } from 'zustand/react/shallow'
import useZeroDevStore from '@/store/useZeroDevStore'
import {
  SUContractInterface,
  fareCoinFlipAddress,
  fareDiceAddress,
  fareRPSAddress,
} from '@/lib/crypto'
import {
  ParamOperator,
  SessionKeyProvider,
  ZeroDevEthersProvider,
  convertEthersSignerToAccountSigner,
} from '@zerodev/sdk'
import { Wallet, utils } from 'ethers'
import { LocalAccountSigner } from '@alchemy/aa-core'
import type { Hex } from 'viem'
import { LOCAL_STORAGE } from '@/constants/utils'
import useBankroll from './useBankroll'
import { DEFAULT_CHAIN_URL } from '@/constants/web3'
import { useWeb3 } from './useWeb3'

const { VITE_ZERO_DEV_PROJECT_ID } = import.meta.env

const useZeroDev = () => {
  const { provider, account } = useWeb3()

  const {
    isUsingAA,
    zeroDevAddress,
    zeroDevSessionKeyProvider,
    setIsUsingAA,
    setZeroDevAddress,
    setZeroDevSessionKeyProvider,
    isActivatingAA,
    setIsActivatingAA,
    hasSetupBefore,
    setHasSetupBefore,
  } = useZeroDevStore(useShallow(state => state))

  const { setAllowedSubmitter, getIsSubmitterAllowed } = useBankroll()

  const activateAA = async () => {
    try {
      setIsActivatingAA(true)
      if (!isUsingAA && zeroDevSessionKeyProvider && account && zeroDevAddress) {
        const isSubmitterAllowed = await getIsSubmitterAllowed(account, zeroDevAddress)
        if (isSubmitterAllowed) {
          setIsUsingAA(true, account)
          return
        } else {
          await setAllowedSubmitter(zeroDevAddress, true)
          setIsUsingAA(true, account)
          return
        }
      }
      if (!provider) return setIsActivatingAA(false)
      const signer = provider.getSigner()
      if (!signer || !account) return setIsActivatingAA(false)

      // @TODO: Check paymaster logic
      // Create a ZeroDev ZeroDevEthersProvider passing the ethers Wallet as the signer
      const ecdsaProvider = await ZeroDevEthersProvider.init('ECDSA', {
        projectId: VITE_ZERO_DEV_PROJECT_ID,
        owner: convertEthersSignerToAccountSigner(signer),
        opts: {
          providerConfig: { rpcUrl: DEFAULT_CHAIN_URL },
        },
      })
      // Get the address of the signer
      const zdAddress = await ecdsaProvider.accountProvider.getAddress()
      setZeroDevAddress(zdAddress)

      // Get sessionKey from localStorage, if it does not exist, create a new one
      const zdSessionKeyLocalStorageString = localStorage.getItem(
        LOCAL_STORAGE.ZERO_DEV_SESSION_KEY
      )
      if (!zdSessionKeyLocalStorageString || !JSON.parse(zdSessionKeyLocalStorageString)[account]) {
        // Create SessionKey
        const sessionKeyPrivateKey = Wallet.createRandom().privateKey as Hex
        const sessionKey = LocalAccountSigner.privateKeyToAccountSigner(sessionKeyPrivateKey)
        // @TODO: Since they did an update, reconsider the below case. It might have been fixed
        // @NOTE: I have decided to user "SessionKeyProvider.init" rather than "ZeroDevEthersProvider.init("SESSION_KEY")"
        // @NOTE: Because if we use the ZeroDevEthersProvider, it basically make it seem like the tx took way too long than it really did
        // @NOTE: With SessionKeyProvider, tx is included when `sendUserOperation` returns. It returns userOpHash and not he transactionHash
        // @NOTE: When userOpHash has been returned the transaction has actually been included in a block
        // @NOTE: When we do `waitForUserOperationTransaction` we retrieve the transaction hash for the userOp which adds a delay
        // @NOTE: When we use ZeroDevEthersProvider and act like it is just an ethers signer, we will accept this delay by default
        // @NOTE: return of `const tx = await .approve()` requires txHash therefore, it is same as running `waitForUserOperationTransaction` when it is not needed
        const fundOwnerAddressOffset = 7 * 32 // 224

        const sessionKeyProvider = await SessionKeyProvider.init({
          projectId: VITE_ZERO_DEV_PROJECT_ID,
          sessionKey,
          defaultProvider: ecdsaProvider.getAccountProvider(),
          opts: {
            providerConfig: {
              rpcUrl: DEFAULT_CHAIN_URL,
              opts: {
                txRetryIntervalMs: 0,
              },
            },
          },
          sessionKeyData: {
            validAfter: 0,
            validUntil: 0,
            paymaster: '0x0000000000000000000000000000000000000000',
            // paymaster: constants.AddressZero,
            permissions: [
              // Allow sessionKey to SubmitEntry to CoinFlip (only with fundOwnerAddress being the owner address)
              {
                target: fareCoinFlipAddress as Hex,
                valueLimit: 0n,
                sig: SUContractInterface.getSighash(
                  (SUContractInterface as any).functions[
                    'submitEntry((uint256,uint256,uint256,uint256,uint32,address,address,bytes))'
                  ]
                ) as Hex,
                rules: [
                  {
                    offset: fundOwnerAddressOffset,
                    condition: ParamOperator.EQUAL,
                    param: utils.hexlify(utils.zeroPad(account, 32)) as Hex,
                  },
                ],
              },
              // Allow sessionKey to SubmitEntry to RPS (only with fundOwnerAddress being the owner address)
              {
                target: fareRPSAddress as Hex,
                valueLimit: 0n,
                sig: SUContractInterface.getSighash(
                  (SUContractInterface as any).functions[
                    'submitEntry((uint256,uint256,uint256,uint256,uint32,address,address,bytes))'
                  ]
                ) as Hex,
                rules: [
                  {
                    offset: fundOwnerAddressOffset,
                    condition: ParamOperator.EQUAL,
                    param: utils.hexlify(utils.zeroPad(account, 32)) as Hex,
                  },
                ],
              },
              // Allow sessionKey to SubmitEntry to Dice (only with fundOwnerAddress being the owner address)
              {
                target: fareDiceAddress as Hex,
                valueLimit: 0n,
                sig: SUContractInterface.getSighash(
                  (SUContractInterface as any).functions[
                    'submitEntry((uint256,uint256,uint256,uint256,uint32,address,address,bytes))'
                  ]
                ) as Hex,
                rules: [
                  {
                    offset: fundOwnerAddressOffset,
                    condition: ParamOperator.EQUAL,
                    param: utils.hexlify(utils.zeroPad(account, 32)) as Hex,
                  },
                ],
              },
              // Allow sessionKey to WithdrawEntry from CoinFlip
              {
                target: fareCoinFlipAddress as Hex,
                valueLimit: 0n,
                sig: SUContractInterface.getSighash(
                  (SUContractInterface as any).functions['withdrawEntry()']
                ) as Hex,
                rules: [],
              },
              // Allow sessionKey to WithdrawEntry from RPS
              {
                target: fareRPSAddress as Hex,
                valueLimit: 0n,
                sig: SUContractInterface.getSighash(
                  (SUContractInterface as any).functions['withdrawEntry()']
                ) as Hex,
                rules: [],
              },
              // Allow sessionKey to WithdrawEntry from Dice
              {
                target: fareDiceAddress as Hex,
                valueLimit: 0n,
                sig: SUContractInterface.getSighash(
                  (SUContractInterface as any).functions['withdrawEntry()']
                ) as Hex,
                rules: [],
              },
            ],
          },
        })
        setZeroDevSessionKeyProvider(sessionKeyProvider)
        const serializedSessionKey =
          await sessionKeyProvider.serializeSessionKeyParams(sessionKeyPrivateKey)
        localStorage.setItem(
          LOCAL_STORAGE.ZERO_DEV_SESSION_KEY,
          JSON.stringify({
            ...JSON.parse(zdSessionKeyLocalStorageString as string),
            [account]: serializedSessionKey,
          })
        )
        if (!(await getIsSubmitterAllowed(account, zdAddress))) {
          await setAllowedSubmitter(zdAddress, true)
        }
        setIsUsingAA(true, account)
        // sessionKeyProviderToApproveUSDC = sessionKeyProvider
      } else {
        const sessionKeyParams = SessionKeyProvider.deserializeSessionKeyParams(
          JSON.parse(zdSessionKeyLocalStorageString)[account]
        )
        // console.log('sessionKeyParams: ', sessionKeyParams)
        const sessionKeyProviderFromDes = await SessionKeyProvider.fromSessionKeyParams({
          projectId: VITE_ZERO_DEV_PROJECT_ID,
          sessionKeyParams: sessionKeyParams,
          opts: {
            providerConfig: {
              rpcUrl: DEFAULT_CHAIN_URL,
              opts: {
                txRetryIntervalMs: 0,
              },
            },
          },
        })
        setZeroDevSessionKeyProvider(sessionKeyProviderFromDes)

        setIsUsingAA(true, account)
      }
      setHasSetupBefore(true)
    } catch (err) {
      setZeroDevSessionKeyProvider(undefined)
      console.error(err)
      throw err
    } finally {
      setIsActivatingAA(false)
    }
  }

  const deactivateAA = (deletePrevInfo?: boolean) => {
    if (!account) return
    if (deletePrevInfo) {
      setZeroDevAddress(undefined)
      setZeroDevSessionKeyProvider(undefined)
    }
    setIsUsingAA(false, account)
  }

  useEffect(() => {
    ;(async () => {
      const zdSessionKeyLocalStorageString = localStorage.getItem(
        LOCAL_STORAGE.ZERO_DEV_SESSION_KEY
      )
      if (!account) {
        console.log('no account ')
        return
      }
      if (!zdSessionKeyLocalStorageString) {
        console.log('no session key in local storage')
        setIsUsingAA(false, account)
        return
      }
      if (!provider) {
        console.log('no provider')
        return
      }
      if (!account) {
        console.log('no account ')
        return
      }
      if (!JSON.parse(zdSessionKeyLocalStorageString)[account]) {
        setIsUsingAA(false, account)
        return console.log('no session key in storage for selected account')
      }
      const signer = provider.getSigner()

      // Create a ZeroDev ZeroDevEthersProvider passing the ethers Wallet as the signer
      const ecdsaProvider = await ZeroDevEthersProvider.init('ECDSA', {
        projectId: VITE_ZERO_DEV_PROJECT_ID,
        owner: convertEthersSignerToAccountSigner(signer),
        opts: {
          providerConfig: { rpcUrl: DEFAULT_CHAIN_URL },
        },
      })
      // Get the address of the signer
      const zdAddress = await ecdsaProvider.getAccountSigner().getAddress()
      setZeroDevAddress(zdAddress)
      const sessionKeyParams = SessionKeyProvider.deserializeSessionKeyParams(
        JSON.parse(zdSessionKeyLocalStorageString)[account]
      )
      const sessionKeyProviderFromDes = await SessionKeyProvider.fromSessionKeyParams({
        projectId: VITE_ZERO_DEV_PROJECT_ID,
        sessionKeyParams: sessionKeyParams,
        opts: {
          providerConfig: {
            rpcUrl: DEFAULT_CHAIN_URL,
            opts: {
              txRetryIntervalMs: 0,
            },
          },
        },
      })
      setZeroDevSessionKeyProvider(sessionKeyProviderFromDes)
      setHasSetupBefore(true)
    })()
  }, [provider, account, setIsUsingAA, setZeroDevAddress, setZeroDevSessionKeyProvider])

  // @NOTE: This does not revoke the previous session key. This one only creates a new valid session key for user to use
  // @NOTE: Mainly for the case where we deploy a new smart contract and they need to refresh the update sessionKey's capabilities
  const refreshSessionKey = async () => {
    if (!(provider && account)) {
      return
    }
    const ecdsaProvider = await ZeroDevEthersProvider.init('ECDSA', {
      projectId: VITE_ZERO_DEV_PROJECT_ID,
      owner: convertEthersSignerToAccountSigner(provider.getSigner()),
      opts: {
        providerConfig: { rpcUrl: DEFAULT_CHAIN_URL },
      },
    })
    // Create SessionKey
    const sessionKeyPrivateKey = Wallet.createRandom().privateKey as Hex
    const sessionKey = LocalAccountSigner.privateKeyToAccountSigner(sessionKeyPrivateKey)
    const fundOwnerAddressOffset = 7 * 32 // 224

    const sessionKeyProvider = await SessionKeyProvider.init({
      projectId: VITE_ZERO_DEV_PROJECT_ID,
      sessionKey,
      defaultProvider: ecdsaProvider.getAccountProvider(),
      opts: {
        providerConfig: {
          rpcUrl: DEFAULT_CHAIN_URL,
          opts: {
            txRetryIntervalMs: 0,
          },
        },
      },
      sessionKeyData: {
        validAfter: 0,
        validUntil: 0,
        paymaster: '0x0000000000000000000000000000000000000000',
        // paymaster: constants.AddressZero,
        permissions: [
          // Allow sessionKey to SubmitEntry to CoinFlip (only with fundOwnerAddress being the owner address)
          {
            target: fareCoinFlipAddress as Hex,
            valueLimit: 0n,
            sig: SUContractInterface.getSighash(
              (SUContractInterface as any).functions[
                'submitEntry((uint256,uint256,uint256,uint256,uint32,address,address,bytes))'
              ]
            ) as Hex,
            rules: [
              {
                offset: fundOwnerAddressOffset,
                condition: ParamOperator.EQUAL,
                param: utils.hexlify(utils.zeroPad(account, 32)) as Hex,
              },
            ],
          },
          // Allow sessionKey to SubmitEntry to RPS (only with fundOwnerAddress being the owner address)
          {
            target: fareRPSAddress as Hex,
            valueLimit: 0n,
            sig: SUContractInterface.getSighash(
              (SUContractInterface as any).functions[
                'submitEntry((uint256,uint256,uint256,uint256,uint32,address,address,bytes))'
              ]
            ) as Hex,
            rules: [
              {
                offset: fundOwnerAddressOffset,
                condition: ParamOperator.EQUAL,
                param: utils.hexlify(utils.zeroPad(account, 32)) as Hex,
              },
            ],
          },
          // Allow sessionKey to SubmitEntry to Dice (only with fundOwnerAddress being the owner address)
          {
            target: fareDiceAddress as Hex,
            valueLimit: 0n,
            sig: SUContractInterface.getSighash(
              (SUContractInterface as any).functions[
                'submitEntry((uint256,uint256,uint256,uint256,uint32,address,address,bytes))'
              ]
            ) as Hex,
            rules: [
              {
                offset: fundOwnerAddressOffset,
                condition: ParamOperator.EQUAL,
                param: utils.hexlify(utils.zeroPad(account, 32)) as Hex,
              },
            ],
          },
          // Allow sessionKey to WithdrawEntry from CoinFlip
          {
            target: fareCoinFlipAddress as Hex,
            valueLimit: 0n,
            sig: SUContractInterface.getSighash(
              (SUContractInterface as any).functions['withdrawEntry()']
            ) as Hex,
            rules: [],
          },
          // Allow sessionKey to WithdrawEntry from RPS
          {
            target: fareRPSAddress as Hex,
            valueLimit: 0n,
            sig: SUContractInterface.getSighash(
              (SUContractInterface as any).functions['withdrawEntry()']
            ) as Hex,
            rules: [],
          },
          // Allow sessionKey to WithdrawEntry from Dice
          {
            target: fareDiceAddress as Hex,
            valueLimit: 0n,
            sig: SUContractInterface.getSighash(
              (SUContractInterface as any).functions['withdrawEntry()']
            ) as Hex,
            rules: [],
          },
        ],
      },
    })
    setZeroDevSessionKeyProvider(sessionKeyProvider)

    // Get sessionKey from localStorage, if it does not exist, create a new one
    const serializedSessionKey =
      await sessionKeyProvider.serializeSessionKeyParams(sessionKeyPrivateKey)
    localStorage.setItem(
      LOCAL_STORAGE.ZERO_DEV_SESSION_KEY,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(LOCAL_STORAGE.ZERO_DEV_SESSION_KEY) as string),
        [account]: serializedSessionKey,
      })
    )
  }

  return {
    activateAA,
    deactivateAA,
    isUsingAA,
    zeroDevAddress,
    zeroDevSessionKeyProvider,
    setIsUsingAA,
    setZeroDevAddress,
    setZeroDevSessionKeyProvider,
    isActivatingAA,
    setIsActivatingAA,
    hasSetupBefore,
    refreshSessionKey,
  }
}

export default useZeroDev
