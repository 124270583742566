import { useWeb3Modal } from '@web3modal/ethers5/react'

import useWalletStore from '@/store/useWalletStore'
import { useDebouncedCallback } from 'use-debounce'
import { FARE_COLORS } from '@/design'

const SLockoutConnect = styled.button`
  all: unset;
  color: ${FARE_COLORS.aqua};
  font-size: 16px;
  text-decoration-line: underline;
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  z-index: 10;
`

const LockoutConnect = ({
  auth,
  account,
  isActive,
  isActivating,
  verify,
  provider,
  disconnect,
}: any) => {
  const { open } = useWeb3Modal()
  const { isShowingWalletModal } = useWalletStore(state => ({
    isShowingWalletModal: state.isShowingWalletModal,
    setIsShowingWalletModal: state.setIsShowingWalletModal,
  }))

  const isConnected = useMemo(() => Boolean(window.localStorage.getItem('@w3m/wallet_id')), [])

  const debouncedAuthenticate = useDebouncedCallback(
    async () => {
      try {
        await auth()
      } catch (err) {
        disconnect()
        console.log(err)
      }
      await verify()
    },
    250,
    { leading: true }
  )

  const initialConnect = useCallback(async () => {
    try {
      await open()
    } catch (err) {
      disconnect()
      console.log(err)
    }
  }, [open, disconnect])

  useEffect(() => {
    if (isActive && account && !isConnected && provider) {
      debouncedAuthenticate()
    }
  }, [isActive, account, provider])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SLockoutConnect
        type='button'
        disabled={isActivating || isShowingWalletModal}
        onClick={isActive ? () => debouncedAuthenticate() : () => initialConnect()}
      >
        connect wallet
      </SLockoutConnect>
      <span style={{ fontSize: 16, color: 'white' }}>to enter code</span>
    </div>
  )
}

export default LockoutConnect
