import { useAnimations, useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useRPSGameState } from '@/store/useGameStateStore'
import { HandAnimationName, getHandAnimName } from './handAnimations'
import { delay } from '@/utils'
import { type MeshPhysicalMaterial, Color } from 'three'
import { Bloom, EffectComposer } from '@react-three/postprocessing'
import { BloomEffect } from 'postprocessing'
import { entryEvent } from '@/events/entryEvent'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { cumulativeBinomialProbability } from '../shared/probabilityUtils'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'
// import { button, useControls } from 'leva'
import mouseOverSliderAudio from '@/assets/audio/mouse-over-slider.wav'
import error7Audio from '@/assets/audio/Error 07.wav'
import coin11Audio from '@/assets/audio/coins/Coins 11.wav'
import bombsLoseAudio from '@/assets/audio/bombs-lose.wav'
import { ErrorBoundary } from '@sentry/react'

const gltfFile = '/glb/rps-hands.glb'

interface HandModelProps {
  isFastMode: boolean
  setIsFastMode: (isFastMode: boolean) => void
  playSound: any
  loadSound: any
}

export type HandSelectionIdx = 0 | 1 | 2

export const MultiHandRefactor = ({ isFastMode, playSound, loadSound }: HandModelProps) => {
  const gltf = useGLTF(gltfFile)
  const { animations, scene, materials } = gltf
  const houseHand1 = materials['HouseHand'] as MeshPhysicalMaterial
  const playerHand1 = materials['PlayerHand'] as MeshPhysicalMaterial

  const setBorderActive = useGameOutcomeStore(state => state.setIsShowingOutcome)
  const setPlayerWon = useGameOutcomeStore(state => state.setDidPlayerWin)
  const setWinIntensity = useGameOutcomeStore(state => state.setIntensity)

  /* Refs */
  const firstRun = useRef(true)
  const bloomRef = useRef<BloomEffect>(null)
  const currentActionLeft = useRef<HandAnimationName>('Hand.ReadyIdle.L')
  const currentActionRight = useRef<HandAnimationName>('Hand.ReadyIdle.R')
  const loadedAnimationLeft = useRef<HandAnimationName>('Hand.ReadyIdle.L')
  const loadedAnimationRight = useRef<HandAnimationName>('Hand.ReadyIdle.R')

  const lastMultiHouseIdx = useRef<HandSelectionIdx>(0)
  const lastMultiPlayerIdx = useRef<HandSelectionIdx>(0)
  const wasPrevMultiRPS = useRef(false)

  const shakeDuration = useMemo(() => (isFastMode ? 1_000 : 1_650), [isFastMode])
  const holdDuration = 2_000

  // /* SFX */
  // const { loadSound, playSound } = useSound()

  useEffect(() => {
    // loadSound('RPSWin', mouseOverSliderAudio)
    // loadSound('RPSTie', error7Audio)
    // loadSound('RPSWinCoins', coin11Audio)
    // loadSound('RPSLose', bombsLoseAudio)
    // loadSound('multiDiceStart', 'src/assets/audio/Device 7 Start.wav')
    // loadSound('multiDiceLose', 'src/assets/audio/Device 7 Stop.wav')
    // return () => {
    //   // can unload sounds here if we want
    // }
  }, [loadSound])

  /* Memos */
  // const playerWinColor = useMemo(() => new Color(0.1, 1, 0.1), [])
  // const playerBaseEmissive = useMemo(() => new Color(0.1, 1, 0.1), [])
  // // const playerHandBaseColor = useMemo(() => new Color(0.1, 0.1, 1), [])
  // const playerHandBaseColor = useMemo(() => new Color(0.29, 0.961, 0.83), [])
  // const playerHand1BaseEmissiveIntensity = useMemo(() => 0.5, [])
  // // const playerHand2BaseEmissiveIntensity = useMemo(() => 0.75, [])
  // const playerHand1WinIntensity = useMemo(() => 2, [])
  // // const playerHand2WinIntensity = useMemo(() => 3, [])
  //
  // const houseWinColor = useMemo(() => new Color(1, 0.1, 0.1), [])
  // const houseBaseEmissive = useMemo(() => new Color(1, 0.1, 0.1), [])
  // const houseHandBaseColor = useMemo(() => new Color(1, 0.2, 0.2), [])
  // const houseHand1BaseEmissiveIntensity = useMemo(() => 0.75, [])
  // // const houseHand2BaseEmissiveIntensity = useMemo(() => 1.5, [])
  // const houseHand1WinIntensity = useMemo(() => 1.5, [])
  // // const houseHand2WinIntensity = useMemo(() => 2, [])

  /* New Player Colors */
  const playerHandBaseColor = useMemo(() => new Color(0.29, 0.961, 0.83), [])
  const playerBaseEmissive = useMemo(() => new Color(0.1, 1, 0.3), [])
  const playerHand1BaseEmissiveIntensity = useMemo(() => 0.0, [])
  const playerWinColor = useMemo(() => new Color(0.1, 0.961, 0.3), [])
  const playerHand1WinIntensity = useMemo(() => 1, [])
  const playerLoseColor = useMemo(() => new Color(0.2, 0.2, 0.2), [])
  const playerBaseLoseEmissive = useMemo(() => new Color(0, 0, 0), [])
  const playerHandLoseEmissiveIntensity = useMemo(() => 0, [])

  /* New House Colors */
  const houseHandBaseColor = useMemo(() => new Color(0.85, 0.0, 0.84), [])
  const houseBaseEmissive = useMemo(() => new Color(1, 0.1, 0.1), [])
  const houseHand1BaseEmissiveIntensity = useMemo(() => 0.4, [])
  const houseWinColor = useMemo(() => new Color(1, 0.1, 0.1), [])
  const houseHand1WinIntensity = useMemo(() => 1.5, [])
  const houseLoseColor = useMemo(() => new Color(0.2, 0.2, 0.2), [])
  const houseBaseLoseEmissive = useMemo(() => new Color(0, 0, 0), [])
  const houseHandLoseEmissiveIntensity = useMemo(() => 0, [])

  const handScene = useMemo(() => scene, [scene])
  const { actions } = useAnimations(animations, handScene)

  const {
    send,
    setSelectedSide,
    side,
    selectedSide,
    gameState,
    results,
    submittedAmount,
    submittedEntryCount,
  } = useRPSGameState(state => ({
    selectedSide: state.entry.side as HandSelectionIdx,
    setSelectedSide: (side: number) => state.setEntry({ side }),
    results: state.results,
    side: state.submittedEntry ? state.submittedEntry.side : state.entry.side,
    gameState: state.type,
    send: state.send,
    submittedAmount: state.submittedEntry?.entryAmount || 0,
    submittedEntryCount: state.submittedEntry?.entryCount || 1,
  }))

  const setSide = useCallback((value: number) => setSelectedSide(side), [])

  const handAnim = useCallback(
    (animName: HandAnimationName) => actions[getHandAnimName(animName)] as THREE.AnimationAction,
    [actions]
  )

  const applyInitialHandMaterials = () => {
    houseHand1.color = houseHandBaseColor
    houseHand1.emissive = houseBaseEmissive
    houseHand1.emissiveIntensity = houseHand1BaseEmissiveIntensity
    houseHand1.transparent = true
    houseHand1.opacity = 0.09

    playerHand1.color = playerHandBaseColor
    playerHand1.emissive = playerBaseEmissive
    playerHand1.emissiveIntensity = playerHand1BaseEmissiveIntensity
  }

  const turnUpHouseHand = async (duration = 1) => {
    //move the opacity from 0.05 to 1 over 1 second
    const transitionStart = Date.now()
    const transitionEnd = transitionStart + duration * 1000

    while (Date.now() < transitionEnd) {
      const opacity = THREE.MathUtils.mapLinear(Date.now(), transitionStart, transitionEnd, 0.09, 1)
      houseHand1.opacity = opacity
      await delay(0.01)
    }

    houseHand1.opacity = 1
    houseHand1.transparent = false
  }

  const fadeHouseHandToTransparent = async (duration = 1) => {
    const transitionDuration = duration
    const transitionStart = Date.now()
    const transitionEnd = transitionStart + transitionDuration * 1000

    while (Date.now() < transitionEnd) {
      const opacity = THREE.MathUtils.mapLinear(Date.now(), transitionStart, transitionEnd, 1, 0.09)
      houseHand1.opacity = opacity
      await delay(0.01)
    }
  }

  const resetHandMaterials = (duration = 0.5) => {
    houseHand1.color = houseHandBaseColor
    houseHand1.emissive = houseBaseEmissive
    houseHand1.emissiveIntensity = houseHand1BaseEmissiveIntensity
    houseHand1.transparent = false

    playerHand1.color = playerHandBaseColor
    playerHand1.emissive = playerBaseEmissive
    playerHand1.emissiveIntensity = playerHand1BaseEmissiveIntensity
    houseHand1.transparent = true

    if (bloomRef.current) {
      bloomRef.current.intensity = 0.1
      ;(bloomRef.current as any).luminanceThreshold = 0.0
    }

    fadeHouseHandToTransparent(duration)
  }

  const changeFastMode = (isFast: boolean) => {
    if (selectedSide === 0)
      loadedAnimationLeft.current = isFast ? 'Hand.PlayRockFast.L' : 'Hand.PlayRock.L'
    if (selectedSide === 1)
      loadedAnimationLeft.current = isFast ? 'Hand.PlayPaperFast.L' : 'Hand.PlayPaper.L'
    if (selectedSide === 2)
      loadedAnimationLeft.current = isFast ? 'Hand.PlayScissorsFast.L' : 'Hand.PlayScissors.L'

    if (side === 0)
      loadedAnimationRight.current = isFast ? 'Hand.PlayRockFast.R' : 'Hand.PlayRock.R'
    if (side === 1)
      loadedAnimationRight.current = isFast ? 'Hand.PlayPaperFast.R' : 'Hand.PlayPaper.R'
    if (side === 2)
      loadedAnimationRight.current = isFast ? 'Hand.PlayScissorsFast.R' : 'Hand.PlayScissors.R'
  }

  useEffect(() => {
    switch (gameState) {
      case 'IDLE':
        // returnToIdle()
        break
      case 'START':
        loadSound('RPSWin', mouseOverSliderAudio)
        loadSound('RPSTie', error7Audio)
        loadSound('RPSWinCoins', coin11Audio)
        loadSound('RPSLose', bombsLoseAudio)
        if (submittedEntryCount > 1) {
          prepMultiRPSGame()
        } else {
          startGame()
        }

        break
      case 'RESOLVE':
        if (results) {
          if (submittedEntryCount > 1) {
            multiResolveGame(results.resultSides as HandSelectionIdx[])
            wasPrevMultiRPS.current = true
          } else {
            resolveGame(results.resultSides[0], 0)
            wasPrevMultiRPS.current = false
          }
        }
        break
      case 'RESET':
        if (wasPrevMultiRPS.current) {
          resetMultiRPS(lastMultiHouseIdx.current, lastMultiPlayerIdx.current)
          setBorderActive(false)
          setWinIntensity(1)
          // postMultiRPSHands()
        } else {
          returnToIdle()
        }
        send({ type: 'IDLE', payload: {} })
        break
      case 'ERROR':
        returnToIdle()
        send({ type: 'RESET', payload: {} })
        break
      default:
        break
    }
  }, [gameState])

  const animatePlayerHandToSelection = useCallback((sideValue: number, duration = 0.25) => {
    switch (sideValue) {
      case 0:
        selectRock(duration)
        break
      case 1:
        selectPaper(duration)
        break
      case 2:
        selectScissors(duration)
        break
    }
  }, [])

  useEffect(() => {
    animatePlayerHandToSelection(selectedSide)
  }, [selectedSide])

  const startIdle = useCallback(() => {
    transitionAnimations('Hand.ReadyIdle.L', 'Hand.ReadyIdle.R')
  }, [actions])

  const returnToIdle = useCallback(async () => {
    resetHandMaterials()

    setBorderActive(false)
    setWinIntensity(1)

    //crossfade from current animation to idle
    // handAnim(playerSelectedSideAnim).crossFadeFrom(
    //   actions[getHandAnimName(currentActionLeft.current)]!,
    //   0.5,
    //   false
    // )
    // handAnim(playerSelectedSideAnim).play()
    // currentActionLeft.current = playerSelectedSideAnim

    handAnim('Hand.ReadyIdle.R').crossFadeFrom(
      actions[getHandAnimName(currentActionRight.current)]!,
      0.5,
      false
    )
    handAnim('Hand.ReadyIdle.R').play()
    currentActionRight.current = 'Hand.ReadyIdle.R'

    animatePlayerHandToSelection(selectedSide, 0.5)
    // const playerSelectedSideAnim = selectionAnimMap[selectedSide] as any
    // previewSelected(playerSelectedSideAnim)
    // await delay(1000)
    firstRun.current = true
  }, [actions, selectedSide])

  const transitionAnimations = (leftAnim: HandAnimationName, rightAnim: HandAnimationName) => {
    handAnim(currentActionLeft.current).stop()
    handAnim(currentActionRight.current).stop()
    handAnim(leftAnim).reset().play()
    handAnim(rightAnim).reset().play()
    currentActionLeft.current = leftAnim
    currentActionRight.current = rightAnim
  }

  const previewSelected = (animToPreview: HandAnimationName, duration = 0.25) => {
    if (currentActionLeft.current === 'Hand.ReadyIdle.L') {
      //@NOTE this is a hack to get the hand to transition from idle to the selected animation without the jitter from the animated crossfade
      handAnim('Hand.ReadyIdle.L').stop()
      handAnim('Hand.ReadyToPlay.L').reset().play()
      currentActionLeft.current = 'Hand.ReadyToPlay.L'
    }

    handAnim(animToPreview).crossFadeFrom(
      actions[getHandAnimName(currentActionLeft.current)]!,
      duration,
      false
    )
    handAnim(animToPreview).reset().play()
    currentActionLeft.current = animToPreview
  }

  const needsToReturnToIdle = () => {
    return (
      currentActionLeft.current !== 'Hand.ReadyIdle.L' ||
      currentActionRight.current !== 'Hand.ReadyIdle.R'
    )
  }

  const selectRock = useCallback(
    (duration = 0.25) => {
      loadedAnimationLeft.current = isFastMode ? 'Hand.PlayRockFast.L' : 'Hand.PlayRock.L'
      previewSelected('Hand.Fist.L', duration)
    },
    [isFastMode]
  )

  const selectPaper = useCallback(
    (duration = 0.25) => {
      loadedAnimationLeft.current = isFastMode ? 'Hand.PlayPaperFast.L' : 'Hand.PlayPaper.L'
      previewSelected('Hand.Paper.L', duration)
    },
    [actions, isFastMode]
  )

  const selectScissors = useCallback(
    (duration = 0.25) => {
      loadedAnimationLeft.current = isFastMode ? 'Hand.PlayScissorsFast.L' : 'Hand.PlayScissors.L'
      previewSelected('Hand.Scissors.L', duration)
    },
    [actions, isFastMode]
  )

  const handleResolve = (resultSide: number) => {
    if (selectedSide === resultSide) {
      // Tie
      playSound('RPSTie', 0.3, 1.5 + Math.random() * 0.1)
    } else if (
      // House wins
      (selectedSide === 0 && resultSide === 1) ||
      (selectedSide === 1 && resultSide === 2) ||
      (selectedSide === 2 && resultSide === 0)
    ) {
      houseHand1.emissive = houseWinColor
      houseHand1.emissiveIntensity = houseHand1WinIntensity
      playerHand1.color = playerLoseColor
      playerHand1.emissive = playerBaseLoseEmissive
      playerHand1.emissiveIntensity = playerHandLoseEmissiveIntensity

      playSound('RPSLose', 0.1, 1)
    } else {
      // Player wins
      playerHand1.emissive = playerWinColor
      playerHand1.emissiveIntensity = playerHand1WinIntensity
      houseHand1.color = houseLoseColor
      houseHand1.emissive = houseBaseLoseEmissive
      houseHand1.emissiveIntensity = houseHandLoseEmissiveIntensity

      playSound('RPSWin', 0.3, 0.8)
      playSound('RPSWinCoins', 0.3, 1)

      setBorderActive(true)
      setPlayerWon(true)
      setWinIntensity(calculateSingleWinIntensity(submittedAmount))
    }
  }

  const setRightHandAnimationRandom = useCallback(() => {
    const random = Math.floor(Math.random() * 3)
    setSide(random)
    switch (random) {
      case 0:
        loadedAnimationRight.current = isFastMode ? 'Hand.PlayRockFast.R' : 'Hand.PlayRock.R'
        return 0
      case 1:
        loadedAnimationRight.current = isFastMode ? 'Hand.PlayPaperFast.R' : 'Hand.PlayPaper.R'
        return 1
      case 2:
        loadedAnimationRight.current =
          isFastMode ? 'Hand.PlayScissorsFast.R' : 'Hand.PlayScissors.R'
        return 2
    }

    throw new Error('Not a valid choice')
  }, [actions, isFastMode])

  const setRightHandAnimation = useCallback(
    (resultSide: number) => {
      // const random = Math.floor(Math.random() * 3)
      // setSide(random)
      // console.log('fastModefastMode', isFastMode.valueOf())
      switch (resultSide) {
        case 0:
          loadedAnimationRight.current = isFastMode ? 'Hand.PlayRockFast.R' : 'Hand.PlayRock.R'
          return 0
        case 1:
          loadedAnimationRight.current = isFastMode ? 'Hand.PlayPaperFast.R' : 'Hand.PlayPaper.R'
          return 1
        case 2:
          loadedAnimationRight.current =
            isFastMode ? 'Hand.PlayScissorsFast.R' : 'Hand.PlayScissors.R'
          return 2
      }

      throw new Error('Not a valid choice')
    },
    [actions, isFastMode]
  )

  const setUp = () => {
    applyInitialHandMaterials()
    handAnim('Hand.PlayScissors.R').timeScale = 2
    handAnim('Hand.PlayScissors.L').timeScale = 2
    handAnim('Hand.PlayPaper.R').timeScale = 2
    handAnim('Hand.PlayPaper.L').timeScale = 2
    handAnim('Hand.PlayRock.R').timeScale = 2
    handAnim('Hand.PlayRock.L').timeScale = 2

    handAnim('Hand.PlayScissorsFast.R').timeScale = 4
    handAnim('Hand.PlayScissorsFast.L').timeScale = 4
    handAnim('Hand.PlayPaperFast.R').timeScale = 4
    handAnim('Hand.PlayPaperFast.L').timeScale = 4
    handAnim('Hand.PlayRockFast.R').timeScale = 4
    handAnim('Hand.PlayRockFast.L').timeScale = 4

    handAnim('Hand.PlayScissors.R').setLoop(THREE.LoopOnce, 1)
    handAnim('Hand.PlayScissors.L').setLoop(THREE.LoopOnce, 1)
    handAnim('Hand.PlayPaper.R').setLoop(THREE.LoopOnce, 1)
    handAnim('Hand.PlayPaper.L').setLoop(THREE.LoopOnce, 1)
    handAnim('Hand.PlayRock.R').setLoop(THREE.LoopOnce, 1)
    handAnim('Hand.PlayRock.L').setLoop(THREE.LoopOnce, 1)

    handAnim('Hand.PlayPaperFast.R').setLoop(THREE.LoopRepeat, 1)
    handAnim('Hand.PlayPaperFast.L').setLoop(THREE.LoopRepeat, 1)
    handAnim('Hand.PlayScissorsFast.R').setLoop(THREE.LoopRepeat, 1)
    handAnim('Hand.PlayScissorsFast.L').setLoop(THREE.LoopRepeat, 1)
    handAnim('Hand.PlayRockFast.R').setLoop(THREE.LoopRepeat, 1)
    handAnim('Hand.PlayRockFast.L').setLoop(THREE.LoopRepeat, 1)

    handAnim('Hand.FistToReady.L').setLoop(THREE.LoopRepeat, 1)
    handAnim('Hand.FistToReady.R').setLoop(THREE.LoopRepeat, 1)

    loadedAnimationLeft.current = isFastMode ? 'Hand.PlayRockFast.L' : 'Hand.PlayRock.L'
    setRightHandAnimationRandom()
    startIdle()
  }

  useEffect(() => {
    setUp()
    animatePlayerHandToSelection(selectedSide)
  }, [])

  useEffect(() => {
    changeFastMode(isFastMode)
  }, [isFastMode])

  const returnPreviewToIdle = async () => {
    handAnim('Hand.ReadyIdle.L').stop()
    const transitionDuration = 0.25
    //crossfade from current animation to idle
    handAnim('Hand.ReadyIdle.L').crossFadeFrom(
      actions[getHandAnimName(currentActionLeft.current)]!,
      transitionDuration,
      true
    )
    handAnim('Hand.ReadyIdle.L').play()
    currentActionLeft.current = 'Hand.ReadyIdle.L'
    await delay(transitionDuration * 1_000)
  }

  const startGame = async () => {
    if (needsToReturnToIdle()) {
      returnPreviewToIdle()
    }

    if (firstRun.current) {
      await turnUpHouseHand()
      firstRun.current = false
    }
  }

  const applyResolveBloom = (resultSide: number) => {
    let applyBloom = false
    if (selectedSide === resultSide) {
    } else if (
      (selectedSide === 0 && resultSide === 1) ||
      (selectedSide === 1 && resultSide === 2) ||
      (selectedSide === 2 && resultSide === 0)
    ) {
      applyBloom = true
    } else {
      applyBloom = true
    }

    if (applyBloom) {
      if (bloomRef.current) {
        bloomRef.current.intensity = 0.25
        ;(bloomRef.current as any).luminanceThreshold = 0.61
      }
    }
  }

  const resolveGame = async (resultSide: number, resolveIdx: number) => {
    //this currently determines the outcome of the game
    setRightHandAnimation(resultSide)

    if (!loadedAnimationLeft.current || !loadedAnimationRight.current) {
      console.error('no animation loaded')
      return
    }

    transitionAnimations(loadedAnimationLeft.current, loadedAnimationRight.current)

    await delay(shakeDuration)
    entryEvent.pub('entryFinished', {
      deltaAmount: Number(results?.rewards[resolveIdx]) - submittedAmount,
    })

    applyResolveBloom(resultSide)
    handleResolve(resultSide)
    entryEvent.pub('updateBalance')
    await delay(holdDuration)

    if (resolveIdx === Number(results?.playedCount) - 1) {
      entryEvent.pub('gameFinished')
    }
  }

  const getDurationFromAnim = (animAction: THREE.AnimationAction): number => {
    return (animAction as any)._clip.duration
  }

  const returnHandToIdle = async (type: 'L' | 'R' | 'both') => {
    const transitionDuration = 0.35

    if (type === 'L' || type === 'both') {
      // handAnim('Hand.ReadyIdle.L').stop()
      // handAnim(currentActionLeft.current).reset()
      //crossfade from current animation to idle
      handAnim('Hand.ReadyIdle.L').crossFadeFrom(
        handAnim(currentActionLeft.current),
        transitionDuration,
        false
      )
      handAnim('Hand.ReadyIdle.L').play()
      currentActionLeft.current = 'Hand.ReadyIdle.L'
    }

    if (type === 'R' || type === 'both') {
      // handAnim('Hand.ReadyIdle.R').stop()
      //crossfade from current animation to idle
      handAnim('Hand.ReadyIdle.R').crossFadeFrom(
        handAnim(currentActionRight.current),
        transitionDuration,
        false
      )
      handAnim('Hand.ReadyIdle.R').play()
      currentActionRight.current = 'Hand.ReadyIdle.R'
    }
    await delay(transitionDuration * 1_000)
  }

  const getPlayerHandOptionAnim = (playerOption: HandSelectionIdx) => {
    const selectionName =
      playerOption === 0 ? 'Fist'
      : playerOption === 1 ? 'Paper'
      : 'Scissors'
    return `Hand.${selectionName}.L` as HandAnimationName
  }

  const getHandSelectionAnimString = (selection: HandSelectionIdx, hand: 'L' | 'R') => {
    const selectionName =
      selection === 0 ? 'Rock'
      : selection === 1 ? 'Paper'
      : 'Scissors'
    const handAnimString = `Hand.Play${selectionName}Fast.${hand}` as HandAnimationName
    return handAnimString
  }

  const getHandSelectionAnim = (selection: HandSelectionIdx, hand: 'L' | 'R') => {
    const selectionName =
      selection === 0 ? 'Rock'
      : selection === 1 ? 'Paper'
      : 'Scissors'
    const handAnimString = `Hand.Play${selectionName}Fast.${hand}` as HandAnimationName
    return handAnim(handAnimString)
  }

  const setHandSelectionAnim = (selection: HandSelectionIdx, hand: 'L' | 'R') => {
    const selectionName =
      selection === 0 ? 'Rock'
      : selection === 1 ? 'Paper'
      : 'Scissors'
    const animString = `Hand.Play${selectionName}Fast.${hand}` as HandAnimationName

    if (hand === 'L') {
      loadedAnimationLeft.current = animString
      currentActionLeft.current = animString
    } else {
      currentActionRight.current = animString
    }

    return handAnim(animString)
  }
  // setHandAnim(0, 'R')
  // setHandAnim(2, 'L')

  const prepMultiRPSGame = async () => {
    const promiseInitializerList: Promise<void>[] = []
    if (needsToReturnToIdle()) {
      // promiseInitializerList.push(returnPreviewToIdle())
      promiseInitializerList.push(returnHandToIdle('L'))
    }
    promiseInitializerList.push(turnUpHouseHand())

    await Promise.all(promiseInitializerList)
  }

  const readyMultiRPSHands = async () => {
    const readyLeftAnim = handAnim('Hand.FistToReady.L')
    const readyRightAnim = handAnim('Hand.FistToReady.R')

    // Reverse timeScale on ready
    readyLeftAnim.timeScale = -1
    readyRightAnim.timeScale = -1

    // Get exact duration amount of fist ready animations
    const readyUpAnimation = getDurationFromAnim(readyLeftAnim)

    handAnim(currentActionLeft.current)
    handAnim(currentActionRight.current)
    // handAnim(currentActionRight.current).stopFading().stopWarping()
    // handAnim(currentActionLeft.current).stopFading().stopWarping()

    // Transition and start ready animation
    handAnim(currentActionLeft.current).stop().reset()
    readyLeftAnim.clampWhenFinished = true
    readyLeftAnim.play()
    currentActionLeft.current = 'Hand.FistToReady.L'
    handAnim(currentActionRight.current).stop().reset()
    readyRightAnim.clampWhenFinished = true
    readyRightAnim.play()
    currentActionRight.current = 'Hand.FistToReady.R'

    await delay(readyUpAnimation * 1_000) // Delay the time of the ready animation
    readyLeftAnim.stop().reset()
    readyRightAnim.stop().reset()
  }

  const handleMultiResolve = (
    playerSelection: HandSelectionIdx,
    houseSelection: HandSelectionIdx
  ) => {
    if (playerSelection === houseSelection) {
      // Tie
      playSound('RPSTie', 0.3, 1.5 + Math.random() * 0.1)
    } else if (
      // House wins
      (playerSelection === 0 && houseSelection === 1) ||
      (playerSelection === 1 && houseSelection === 2) ||
      (playerSelection === 2 && houseSelection === 0)
    ) {
      houseHand1.emissive = houseWinColor
      houseHand1.emissiveIntensity = houseHand1WinIntensity
      playerHand1.color = playerLoseColor
      playerHand1.emissive = playerBaseLoseEmissive
      playerHand1.emissiveIntensity = playerHandLoseEmissiveIntensity

      // playSound('RPSLose', 0.1, 1)
      playSound('RPSWin', 0.1, 0.2)
    } else {
      // Player Wins
      playerHand1.emissive = playerWinColor
      playerHand1.emissiveIntensity = playerHand1WinIntensity
      houseHand1.color = houseLoseColor
      houseHand1.emissive = houseBaseLoseEmissive
      houseHand1.emissiveIntensity = houseHandLoseEmissiveIntensity

      playSound('RPSWin', 0.3, 0.8)
      playSound('RPSWinCoins', 0.3, 1)
    }
  }

  const applyMultiResolveBloom = (
    playerSelection: HandSelectionIdx,
    houseSelection: HandSelectionIdx
  ) => {
    let applyBloom = false
    if (playerSelection === houseSelection) {
    } else if (
      (playerSelection === 0 && houseSelection === 1) ||
      (playerSelection === 1 && houseSelection === 2) ||
      (playerSelection === 2 && houseSelection === 0)
    ) {
      applyBloom = true
    } else {
      applyBloom = true
    }

    if (applyBloom) {
      if (bloomRef.current) {
        bloomRef.current.intensity = 0.25
        ;(bloomRef.current as any).luminanceThreshold = 0.61
      }
    }
  }

  const resetMultiHandMaterial = () => {
    houseHand1.color = houseHandBaseColor
    houseHand1.emissive = houseBaseEmissive
    houseHand1.emissiveIntensity = houseHand1BaseEmissiveIntensity
    houseHand1.transparent = false

    playerHand1.color = playerHandBaseColor
    playerHand1.emissive = playerBaseEmissive
    playerHand1.emissiveIntensity = playerHand1BaseEmissiveIntensity
    houseHand1.transparent = false

    if (bloomRef.current) {
      bloomRef.current.intensity = 0.1
      ;(bloomRef.current as any).luminanceThreshold = 0.0
    }
  }

  const calculateSingleWinIntensity = (amount: number) => {
    if (amount <= 200) return 1
    if (amount <= 400) return 2
    if (amount <= 600) return 3
    if (amount <= 800) return 4
    return 5
  }

  const calculateMultiWinIntensity = (odds: number) => {
    if (odds <= 0.13) return 5
    else if (odds <= 0.3) return 4
    else if (odds <= 0.5) return 3
    else if (odds <= 0.65) return 2
    else if (odds <= 0.99) return 1
    else return 0
  }

  const multiRPSPlayRound = async (
    houseSelection: HandSelectionIdx,
    playerSelection: HandSelectionIdx,
    isLastRound: boolean,
    resolveIdx: number,
    intensity: number
  ) => {
    if (resolveIdx === 0) {
      // handAnim('Hand.FistToReady.L').stop().reset()
      // handAnim('Hand.FistToReady.R').stop().reset()
    }
    currentActionRight.current = getHandSelectionAnimString(houseSelection, 'R')
    currentActionLeft.current = getHandSelectionAnimString(playerSelection, 'L')
    const houseAnim = handAnim(currentActionRight.current)
    const playerAnim = handAnim(currentActionLeft.current)

    // houseAnim.stop().reset()
    houseAnim.timeScale = 4
    const totalDuration = getDurationFromAnim(houseAnim) / 4
    houseAnim.play()

    // playerAnim.stop().reset()
    playerAnim.timeScale = 4
    playerAnim.play()

    houseAnim.clampWhenFinished = true
    playerAnim.clampWhenFinished = true

    await delay(totalDuration * 200)
    handleMultiResolve(playerSelection, houseSelection)
    applyMultiResolveBloom(playerSelection, houseSelection)
    if (intensity > 0) {
      setBorderActive(true)
      setPlayerWon(true)
      setWinIntensity(intensity)
    }

    const submittedEntry = useRPSGameState.getState().submittedEntry
    const results = useRPSGameState.getState().results
    if (submittedEntry && results) {
      entryEvent.pub('entryFinished', {
        deltaAmount:
          Number(results.rewards[resolveIdx]) -
          submittedEntry.entryAmount / submittedEntry.entryCount,
      })
      entryEvent.pub('updateBalance')
    }
    await delay(totalDuration * 800)
    resetMultiHandMaterial()

    if (isLastRound) {
      entryEvent.pub('gameFinished')
    } else {
      houseAnim.stop().reset()
      playerAnim.stop().reset()
      houseAnim.clampWhenFinished = true
      playerAnim.clampWhenFinished = true
    }
  }

  const postMultiRPSHands = async () => {
    const readyLeftAnim = handAnim('Hand.FistToReady.L')
    const readyRightAnim = handAnim('Hand.FistToReady.R')

    // handAnim(currentActionLeft.current).reset().stop()
    // handAnim(currentActionRight.current).reset().stop()
    // await Promise.all([fadeHouseHandToTransparent(), returnHandToIdle('both')])
    readyLeftAnim.timeScale = 1
    readyRightAnim.timeScale = 1
  }

  const resetMultiRPS = async (
    houseSelection: HandSelectionIdx,
    playerSelection: HandSelectionIdx
  ) => {
    const duration = 0.25
    resetHandMaterials(duration)

    const currentHousePlayAnim = getHandSelectionAnim(houseSelection, 'R')
    const currentPlayerPlayAnim = getHandSelectionAnim(playerSelection, 'L')

    handAnim('Hand.ReadyIdle.R').crossFadeFrom(currentHousePlayAnim, duration, true)
    handAnim('Hand.ReadyIdle.R').reset().play()

    currentActionRight.current = 'Hand.ReadyIdle.R'

    if (currentActionLeft.current === 'Hand.ReadyIdle.L') {
      //@NOTE this is a hack to get the hand to transition from idle to the selected animation without the jitter from the animated crossfade
      handAnim('Hand.ReadyIdle.L').stop()
      handAnim('Hand.ReadyToPlay.L').reset().play()
      currentActionLeft.current = 'Hand.ReadyToPlay.L'
    }

    const playerHandOption = getPlayerHandOptionAnim(playerSelection)
    handAnim(playerHandOption).crossFadeFrom(currentPlayerPlayAnim, duration, true)
    handAnim(playerHandOption).reset().play()
    currentActionLeft.current = playerHandOption

    firstRun.current = true
    await delay(duration * 1_000)
    currentHousePlayAnim.stop().reset()
    currentPlayerPlayAnim.stop().reset()
  }

  const getIntensityArray = (
    contractResults: HandSelectionIdx[],
    playerSelection: HandSelectionIdx
  ) => {
    if (contractResults.length === 0) {
      console.warn('invalid results array')
      return
    }
    const intensityArray = Array(contractResults.length).fill(0)
    const normalizedProfitArray: number[] = []
    const results: number[] = []

    // Check for breakeven
    let winCount = 0,
      lossCount = 0,
      drawCount = 0

    contractResults.forEach(result => {
      if (
        (playerSelection === 0 && result === 2) ||
        (playerSelection === 1 && result === 0) ||
        (playerSelection === 2 && result === 1)
      ) {
        winCount++
        results.push(1)
      } else if (playerSelection === result) {
        drawCount++
        results.push(-1)
      } else {
        lossCount++
        results.push(0)
      }
      normalizedProfitArray.push(0.98 * winCount - lossCount - 0.01 * drawCount)
    })

    const normalizedProfit = 0.98 * winCount - lossCount - 0.01 * drawCount
    const playerBrokeEven: boolean = normalizedProfit > 0
    const lastNegativeIndex = normalizedProfitArray.findLastIndex(profit => profit <= 0)

    if (!playerBrokeEven) {
      return intensityArray // return array of zeros
    } else {
      // calculate odds array
      let cumulativeWins = 0
      // let cumulativeLosses = 0
      const oddsArray = Array(contractResults.length).fill(0)

      results.forEach((result, index) => {
        if (result === 1) {
          cumulativeWins++
        }

        // const totalGames = contractResults.length
        const totalGames = winCount + lossCount
        if (totalGames > 0) {
          oddsArray[index] = cumulativeBinomialProbability(totalGames, cumulativeWins, 1 / 3)
        } else {
          oddsArray[index] = 0
        }
      })

      oddsArray.map((odds, index) => {
        index > lastNegativeIndex ? (intensityArray[index] = calculateMultiWinIntensity(odds)) : 0
      })
      return intensityArray
    }
  }

  const multiResolveGame = async (resultSides: HandSelectionIdx[]) => {
    // await prepMultiRPSGame() // START GAME STATE

    // await delay(1_000) // Simulating tx submission delay

    await readyMultiRPSHands()
    // handAnim('Hand.FistToReady.L').reset().stop()
    // handAnim('Hand.FistToReady.R').reset().stop()

    // const selectedNums: HandSelectionIdx[] = [0, 2, 2, 1, 0, 1, 2, 1]
    const lastRoundIdx = resultSides.length - 1
    const intensityArray = getIntensityArray(resultSides, selectedSide)
    for (let round = 0; round < resultSides.length; round++) {
      const selectedHouseSide = resultSides[round]
      const isLastRound = round === lastRoundIdx
      await multiRPSPlayRound(
        selectedHouseSide,
        selectedSide,
        isLastRound,
        round,
        intensityArray ? intensityArray[round] : 0
      )
    }

    lastMultiPlayerIdx.current = selectedSide
    lastMultiHouseIdx.current = resultSides[lastRoundIdx]

    // await postMultiRPSHands() // Reset any values if needed
    // await resetMultiRPS(resultSides[lastRoundIdx], selectedSide)
    // postMultiRPSHands()
    return
  }

  // useControls('Hand Animations', {
  //   ['Play Multi']: button(() => multiResolveGame([0, 2, 1, 0, 2])),
  // })

  // useControls(
  //   'Hand Animations',
  //   {
  //     // ['Select Idle']: button(() => startIdle()),
  //     ['Select Rock']: button(() => setSelectedSide(0), { disabled: selectedSide === 0 }),
  //     ['Select Paper']: button(() => setSelectedSide(1), { disabled: selectedSide === 1 }),
  //     ['Select Scissors']: button(() => setSelectedSide(2), { disabled: selectedSide === 2 }),
  //     ['Set Right Hand Random']: button(() => setRightHandAnimationRandom()),
  //     ['Start Game']: button(() => startGame()),
  //     ['Reset To Idle']: button(() => resetToIdle()),
  //     ['Resolve Game']: button(() => resolveGame(1)),
  //     // ['Set Left Hand Random']: button(() => setLeftHandAnimationRandom()),
  //     // ['Play Game']: button(() => send({ type: 'START', payload: {} })),
  //   },
  //   [selectedSide, setSelectedSide, startGame, resolveGame]
  // )

  return (
    <>
      <group position={[0, -0.5, 0]} rotation={[0, 0, 0]}>
        <primitive object={handScene} dispose={null} />
      </group>
      <EffectComposer disableNormalPass>
        <Bloom
          ref={bloomRef as any}
          luminanceThreshold={0}
          luminanceSmoothing={0.8}
          intensity={0.1}
        />
      </EffectComposer>
    </>
  )
}

useGLTF.preload(gltfFile)
