import { proxy } from 'valtio'

export const createBaseSpaceState = () =>
  proxy({
    isConnecting: false,
    isConnected: false,
  })

// Export SpaceState types
export * from './chat.state'
export * from './liveEntry.state'
export * from './suEntry.state'
export * from './user.state'
