import { useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

interface IRedirect {
  path: string
}

export const Redirect = ({ path }: IRedirect) => {
  const nav = useNavigate()

  useEffectOnce(() => {
    nav(path)
  })

  return null
}
