import { SAddressAvatarWrapper, SDropdown, SDropdownContent, SDropdownItem } from './style'
import { generateUserColor } from '@/utils'
import { AnimatePresence, type Variants } from 'framer-motion'
import { useOnClickOutside } from 'usehooks-ts'
import { getMockUsdcContract } from '@/lib/crypto'
import { ClickToCopy } from '../ClickToCopy'
import { useWeb3 } from '@/hooks/useWeb3'
import useUserData from '@/hooks/useUserData'
import { useUserImageToRender, useUsernameToRender } from '@/utils/userDisplay'

const dropdownVariant: Variants = {
  initial: {
    y: 20,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 20,
    opacity: 0,
  },
}

const SClickToCopy = styled(ClickToCopy)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  small {
    top: -14px;
    left: 0px;
    align-self: center;
    justify-content: center;
  }
`

export const Dropdown = () => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const nav = useNavigate()
  const { account, isActive, provider, disconnect } = useWeb3()
  const { username, avatarSeedAddress, isUsingTwitterToDisplay, twitterUsername, twitterImage } =
    useUserData()
  const userColor = useMemo(
    () => (avatarSeedAddress ? `#${generateUserColor(avatarSeedAddress || '')}` : ''),
    [avatarSeedAddress]
  )
  const [isOpen, setIsOpen] = useState(false)
  const mouseLeaveTimeoutRef = useRef<ReturnType<typeof setTimeout>>()

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false)
  })

  const deactivate = useCallback(async () => {
    if (!isActive) return

    await disconnect()
  }, [isActive, disconnect])

  const mintMockUsdc = useCallback(async () => {
    const mockUsdc = getMockUsdcContract(provider)
    if (!mockUsdc) return
    if (!account) return
    const mintTx = await mockUsdc.mint(account, 1000000000)
    await mintTx.wait()
  }, [account, provider])

  const deactiveAndLogout = useCallback(async () => {
    try {
      console.log('called disconnect')
      await deactivate()
      // navigate('/')
      window.location.reload()
    } catch (err) {
      console.error(err)
    }
  }, [deactivate])

  const goToSettings = useCallback(() => {
    nav('settings')
    setIsOpen(false)
  }, [])

  const handleMouseEnter = () => {
    clearTimeout(mouseLeaveTimeoutRef.current)
  }
  const handleMouseLeave = () => {
    mouseLeaveTimeoutRef.current = setTimeout(() => setIsOpen(false), 800)
  }

  const userImageToRender = useUserImageToRender(
    account || '0x',
    isUsingTwitterToDisplay,
    avatarSeedAddress,
    twitterImage,
    {
      marginRight: '8px',
    }
  )

  const usernameToRender = useUsernameToRender(
    account || '0x',
    isUsingTwitterToDisplay,
    username,
    twitterUsername,
    {},
    {},
    {},
    false,
    false
  )

  return (
    <SDropdown ref={dropdownRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SAddressAvatarWrapper
        $userColor={userColor}
        $isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {userImageToRender}
        {usernameToRender}
      </SAddressAvatarWrapper>
      <AnimatePresence>
        {isOpen && (
          <SDropdownContent
            variants={dropdownVariant}
            initial='initial'
            animate={'animate'}
            exit={'exit'}
            $userColor={userColor}
          >
            {/* <SDropdownItem $userColor={userColor}> */}
            {/*   <span>ENTER INVITE CODE</span> */}
            {/*   <div className='hover-bar'></div> */}
            {/* </SDropdownItem> */}
            <SClickToCopy copyText={account || ''}>
              <SDropdownItem $userColor={userColor}>
                <span>COPY ADDRESS</span>
                <div className='hover-bar'></div>
              </SDropdownItem>
            </SClickToCopy>
            <SDropdownItem $userColor={userColor}>
              <span>TRANSACTIONS</span>
              <div className='hover-bar'></div>
            </SDropdownItem>
            {/* <SDropdownItem $userColor={userColor}> */}
            {/*   <span>NEW AVATAR</span> */}
            {/*   <div className='hover-bar'></div> */}
            {/* </SDropdownItem> */}
            {/* <SDropdownItem $userColor={userColor} onClick={mintMockUsdc}> */}
            {/*   <span>MINT USDC</span> */}
            {/*   <div className='hover-bar'></div> */}
            {/* </SDropdownItem> */}
            <SDropdownItem $userColor={userColor} onClick={deactiveAndLogout}>
              <span>DISCONNECT</span>
              <div className='hover-bar'></div>
            </SDropdownItem>
          </SDropdownContent>
        )}
      </AnimatePresence>
    </SDropdown>
  )
}
