import { type HelperFunctions } from './contracts'

export enum RPSSelection {
  Rock = 0,
  Paper = 1,
  Scissors = 2,
  Random = 3,
}

export const isValidRPSSide = (side: number) => {
  return side >= 0 && side <= 2
}

export const getRPSMultiplierWithoutPPV = () => {
  return 2
}

export const getRPSMultiplierWithPPV = () => {
  return 1.98
}

// @NOTE: https://en.wikipedia.org/wiki/Kelly_criterion
export const getRPSKellyFraction = () => {
  return 0.01530571818
}

export const getRPSPotentialProfitCoefficient = () => {
  return 0.98
}

export const rpsHelperFunctions: HelperFunctions = {
  isValidSide: isValidRPSSide,
  getMultiplierWithoutPPV: getRPSMultiplierWithoutPPV,
  getMultiplierWithPPV: getRPSMultiplierWithPPV,
  getKellyFraction: getRPSKellyFraction,
  getPotentialProfitCoefficient: getRPSPotentialProfitCoefficient,
}
