import {
  type IKToMs,
  type IMToPossibleOrderingCount,
} from '@/components/shared/Singleton/useGameContractListener'
import { create } from 'zustand'

export interface ITrialData {
  k: bigint[]
  kToMs: IKToMs
  mToPossibleOrderingCount: IMToPossibleOrderingCount
  filledOriginalK: bigint[]
}

export type TrialState = {
  k?: bigint[] | null
  kToMs?: IKToMs | null
  mToPossibleOrderingCount?: IMToPossibleOrderingCount | null
  filledOriginalK?: bigint[] | null
  trialId?: string | null
}

export type TrialActions = {
  setTrialData: (trialData: ITrialData) => void
  setTrialId: (trialId: string) => void
}

export const initialTrialState: TrialState = {
  k: null,
  kToMs: null,
  mToPossibleOrderingCount: null,
  filledOriginalK: null,
  trialId: null,
}

export type TrialStore = TrialState & TrialActions

const useTrialStore = create<TrialStore>(set => ({
  ...initialTrialState,
  setTrialData: trialData =>
    set(() => ({
      k: trialData.k,
      kToMs: trialData.kToMs,
      mToPossibleOrderingCount: trialData.mToPossibleOrderingCount,
      filledOriginalK: trialData.filledOriginalK,
    })),
  setTrialId: trialId =>
    set(() => ({
      trialId,
    })),
}))

export default useTrialStore
