import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
import { arbitrumChainParams } from '../../constants/chains'
import { FARE_COLORS } from '../../design'

let isWeb3Initialized = false

export const createWeb3 = () => {
  // Guard statement to ensure this is only initialized once
  if (isWeb3Initialized) return console.log('Web3 modal is already initialized')
  isWeb3Initialized = true

  // Set chains
  const arbitrum = {
    chainId: arbitrumChainParams.chainId,
    name: arbitrumChainParams.chainName,
    currency: arbitrumChainParams.nativeCurrency.name,
    explorerUrl: arbitrumChainParams.blockExplorerUrls![0],
    rpcUrl: arbitrumChainParams.rpcUrls[0],
  }

  // Define Metadata
  const metadata = {
    name: 'FarePlay',
    description:
      'Fareplay is a decentralized crypto casino. On-chain wagers, verifiable randomness, no deposits.',
    url: 'https://fareplay.io', // origin must match your domain & subdomain
    icons: ['https://fareplay.io/images/metaimage.png'],
  }

  // Create modal
  createWeb3Modal({
    ethersConfig: defaultConfig({
      metadata,
      defaultChainId: arbitrum.chainId,
      enableEIP6963: true,
      enableInjected: true,
      // enableCoinbase: true,
    }),
    chains: [arbitrum],
    defaultChain: arbitrum,
    projectId: import.meta.env.VITE_WALLET_CONNECT_V2_PROJECT_ID,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    themeVariables: {
      '--w3m-border-radius-master': '1px',
      '--w3m-font-family': 'Gohu, monospace',
      '--w3m-accent': FARE_COLORS.aqua,
      '--w3m-color-mix': '#000',
      '--w3m-color-mix-strength': 40,
    },
  })
}
