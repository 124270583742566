import React, { useRef } from 'react'
import { SVGS } from '@/assets'
import {
  HeaderStrip,
  HeaderWrapper,
  LoginWrapper,
  MainHeader,
  RightHeader,
  UserBalanceWrapper,
} from './style'
import useCurrencyStore from '@/store/useCurrencyStore'
import CountUp from 'react-countup'
import { Bar, MenuSandwich } from '../MobileMenu/style'
import Avatar from '../Avatar'
import { useWeb3 } from '@/hooks/useWeb3'

interface IMobileHeaderProps {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
  isShowMenu: boolean
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>
  isShowProfile: boolean
}

interface ToggleableComponentProps {
  refProp: React.RefObject<HTMLDivElement>
  isShown: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
  zIndex?: number
}

interface LeftMainHeaderProps extends Omit<IMobileHeaderProps, 'setShowMenu' | 'isShowMenu'> {
  leftHeaderRef: React.RefObject<HTMLDivElement>
}

interface RightMainHeaderProps
  extends Omit<IMobileHeaderProps, 'setShowProfile' | 'isShowProfile'> {
  rightHeaderRef: React.RefObject<HTMLDivElement>
}

const ToggleableComponent: React.FC<ToggleableComponentProps> = ({
  refProp,
  isShown,
  setShow,
  children,
  zIndex = 0,
}) => (
  <div ref={refProp} onClick={() => setShow(!isShown)} style={{ zIndex: isShown ? 200 : zIndex }}>
    {children}
  </div>
)

const LeftMainHeader: React.FC<LeftMainHeaderProps> = ({
  setShowProfile,
  isShowProfile,
  leftHeaderRef,
}) => {
  const { account } = useWeb3()

  return (
    <ToggleableComponent refProp={leftHeaderRef} isShown={isShowProfile} setShow={setShowProfile}>
      <Avatar size='sm' seed={account || ''} />
    </ToggleableComponent>
  )
}

const RightMainHeader: React.FC<RightMainHeaderProps> = ({
  setShowMenu,
  isShowMenu,
  rightHeaderRef,
}) => (
  <RightHeader>
    <MenuSandwich onClick={() => setShowMenu(!isShowMenu)} ref={rightHeaderRef}>
      {[...Array(3)].map((_, idx) => (
        <Bar key={idx} isActive={isShowMenu}></Bar>
      ))}
    </MenuSandwich>
  </RightHeader>
)

const UserBalance: React.FC = () => {
  const balances = useCurrencyStore(state => state.balances)
  const selectedCurrencyAmount = balances.usdc

  return (
    <UserBalanceWrapper>
      <img src={SVGS.usdcIcon} alt='fare' />
      <CountUp
        end={Number(selectedCurrencyAmount)}
        decimals={2}
        duration={2}
        separator=','
        preserveValue
      />
    </UserBalanceWrapper>
  )
}


export const MobileHeader: React.FC<IMobileHeaderProps> = ({
  setShowMenu,
  isShowMenu,
  setShowProfile,
  isShowProfile,
}) => {
  const leftHeaderRef = useRef<HTMLDivElement>(null)
  const rightHeaderRef = useRef<HTMLDivElement>(null)
  const { account } = useWeb3()

  const handleHeaderClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (leftHeaderRef.current && !leftHeaderRef.current.contains(event.target as Node)) {
      setShowProfile(false)
    }
    if (rightHeaderRef.current && !rightHeaderRef.current.contains(event.target as Node)) {
      setShowMenu(false)
    }
  }

  return (
    <HeaderWrapper>
      <MainHeader onClick={handleHeaderClick}>
        {account ?
          <>
            <LeftMainHeader
              setShowProfile={setShowProfile}
              isShowProfile={isShowProfile}
              leftHeaderRef={leftHeaderRef}
            />
            <UserBalance />
          </>
        : <LoginWrapper href='/settings'>Login</LoginWrapper>}
        <RightMainHeader
          setShowMenu={setShowMenu}
          isShowMenu={isShowMenu}
          rightHeaderRef={rightHeaderRef}
        />
      </MainHeader>
      <HeaderStrip />
    </HeaderWrapper>
  )
}
