import type { UsdcVault } from '@/lib/crypto/typechain'
import { type BigNumber } from 'ethers'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export interface ISUContractForm {
  side: number
  entryAmount: number
  numberOfEntries: number
  stopLoss: number
  stopGain: number
}

export interface ISUInProgressEntry {
  requestId: string
  timestamp: number
}

export const initialSUContractResults = {
  requestId: '',
  userAddress: '',
  resultSides: [],
  playedCount: 0,
  rewards: [],
  totalRewards: '',
}

export type SUContractResult = {
  requestId: string
  userAddress: string
  resultSides: number[]
  playedCount: number
  rewards: string[]
  totalRewards: string
}

export type SUGameState = 'open' | 'in-progress' | 'result'

export type SUContractState = {
  usdcVault?: UsdcVault | null
  allowanceAmount: string
  submittedAmount: string
  submittedSide: number
  selectedSide: number
  entryCount: number
  results: SUContractResult
  inProgress: boolean
  gameState: SUGameState
  hasApprovedContracts: boolean | null
  isApprovingContracts: boolean
  inProgressEntry: ISUInProgressEntry | null
  isSubmitting: boolean
  isWithdrawing: boolean
}

export type SUContractActions = {
  setSUContract: (suContract: UsdcVault) => void
  setAllowanceAmount: (amount: string) => void
  setSubmittedAmount: (submittedAmount: string) => void
  setSubmittedSide: (submittedSide: number) => void
  setSelectedSide: (selectedSide: number) => void
  setEntryCount: (entryCount: number) => void
  setSUContractResults: (results: SUContractResult) => void
  setInProgress: (inProgress: boolean) => void
  setGameState: (gameState: SUGameState) => void
  setHasApprovedContracts: (hasApprovedContracts: boolean | null) => void
  setIsApprovingContracts: (isApprovingContracts: boolean) => void
  setInProgressEntry: (inProgressEntry: ISUInProgressEntry | null) => void
  setIsSubmitting: (isSubmitting: boolean) => void
  setIsWithdrawing: (isWithdrawing: boolean) => void
}

export const initialSUContractState: SUContractState = {
  usdcVault: null,
  allowanceAmount: '0',
  submittedAmount: '0',
  submittedSide: 0,
  selectedSide: 0,
  entryCount: 1,
  results: initialSUContractResults,
  inProgress: false,
  gameState: 'open',
  hasApprovedContracts: null,
  isApprovingContracts: false,
  inProgressEntry: null,
  isSubmitting: false,
  isWithdrawing: false,
}

export type SUStore = SUContractState & SUContractActions

const useSUContractStore = create<SUStore>()(
  immer(set => ({
    ...initialSUContractState,
    setInProgress: inProgress =>
      set(() => ({
        inProgress,
      })),
    setGameState: gameState =>
      set(() => ({
        gameState,
      })),
    setSUContract: suContract =>
      set(() => ({
        suContract,
      })),
    setAllowanceAmount: allowanceAmount =>
      set(() => ({
        allowanceAmount,
      })),
    setSubmittedAmount: submittedAmount =>
      set(() => ({
        submittedAmount,
      })),
    setSubmittedSide: submittedSide =>
      set(() => ({
        submittedSide,
      })),
    setSelectedSide: selectedSide =>
      set(() => ({
        selectedSide,
      })),
    setEntryCount: entryCount =>
      set(() => ({
        entryCount,
      })),
    setSUContractResults: results =>
      set(() => ({
        results,
      })),
    setHasApprovedContracts: hasApprovedContracts =>
      set(() => ({
        hasApprovedContracts,
      })),
    setIsApprovingContracts: isApprovingContracts =>
      set(() => ({
        isApprovingContracts,
      })),
    setInProgressEntry: inProgressEntry =>
      set(() => ({
        inProgressEntry,
      })),
    setIsSubmitting: isSubmitting =>
      set(() => ({
        isSubmitting,
      })),
    setIsWithdrawing: isWithdrawing =>
      set(() => ({
        isWithdrawing,
      })),
  }))
)

export default useSUContractStore
