import { getBankrollContract, fareBankrollAddress } from '@/lib/crypto'
import useBankrollStore from '@/store/useBankrollStore'
import { useWeb3 } from './useWeb3'

const useBankroll = () => {
  const { provider } = useWeb3()
  const { bankrollContract, setBankrollContract, setRiskFactor } = useBankrollStore()

  useEffect(() => {
    if (provider) {
      const contract = getBankrollContract(fareBankrollAddress, provider)
      if (contract) setBankrollContract(contract)
    }
  }, [provider, setBankrollContract])

  useEffect(() => {
    ;(async () => {
      if (provider && bankrollContract) {
        const receivedRiskFactor = await bankrollContract.riskFactor()
        setRiskFactor(receivedRiskFactor.toNumber())
      }
    })()
  }, [provider, bankrollContract, setRiskFactor])

  const setAllowedSubmitter = useCallback(
    async (submitterAddress: string, isAllowed: boolean) => {
      if (!bankrollContract) return
      const allowSubmitterTx = await bankrollContract.setAllowedSubmitter(
        submitterAddress,
        isAllowed
      )
      await allowSubmitterTx.wait()
    },
    [bankrollContract]
  )

  const getIsSubmitterAllowed = useCallback(
    async (fundOwnerAddress: string, submitterAddress: string) => {
      if (!bankrollContract) return false
      return await bankrollContract.fundOwnerAddressToSubmitterAddressToIsAllowed(
        fundOwnerAddress,
        submitterAddress
      )
    },
    [bankrollContract]
  )

  return {
    setAllowedSubmitter,
    getIsSubmitterAllowed,
  }
}

export default useBankroll
