import useAuthStore from '@/store/useAuthStore'
import { useWeb3ModalAccount } from '@web3modal/ethers5/react'
import { useShallow } from 'zustand/react/shallow'

export const useIsAuthed = () => {
  const { address, isConnected } = useWeb3ModalAccount()
  const { authPublicAddress, hasEnteredInviteCode } = useAuthStore(
    useShallow(state => ({
      authPublicAddress: state.authPublicAddress,
      hasEnteredInviteCode: state.hasEnteredInviteCode,
    }))
  )

  return useMemo(() => {
    return Boolean(address && isConnected && authPublicAddress && hasEnteredInviteCode)
  }, [address, isConnected, authPublicAddress, hasEnteredInviteCode])
}
