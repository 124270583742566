import { ensureString } from '@/utils/text'
import { ChatFState, initialChatFState } from '../state'
import type { ChatSocket } from '../types'
import { FSpaceBase, IFSpaceBaseOpts } from './FSpace'
import { MAX_CHAT_MSG_LENGTH } from '@/constants/ws'
import { addAppNoti } from '@/store/useNotiStore'
import useAuthStore from '@/store/useAuthStore'

type ChatSpaceOpts = Omit<IFSpaceBaseOpts<ChatFState>, 'name' | 'status' | 'state'>

export class ChatSpace extends FSpaceBase<ChatSocket, ChatFState> {
  constructor({ fsocketOpts, authToken }: ChatSpaceOpts) {
    super({
      name: 'chat',
      state: initialChatFState,
      fsocketOpts,
      authToken,
    })

    this.#mountListeners()

    // authToken ? this.connect() : console.log(`No auth token. Not initializing /${this.name}.`)
    this.connect()
  }

  #mountListeners() {
    if (this.hasMountedListeners) return
    this.hasMountedListeners = true
    this.io.on('initial_state', initialMsgs => {
      this.state.chatMsgs = initialMsgs
    })

    // @TODO: Ensure that the same chat message isn't added more than once
    this.io.on('new_chat', chatMsg => {
      this.state.chatMsgs.push(chatMsg)
    })
  }

  sendChatMsg(text: string) {
    if (!useAuthStore.getState().authPublicAddress) {
      return addAppNoti({
        type: 'error',
        msg: 'Please connect wallet to send messages',
      })
    }

    const msg = ensureString(text).trim()
    if (!msg || msg.length === 0 || msg.length > MAX_CHAT_MSG_LENGTH)
      return addAppNoti({
        type: 'error',
        msg: `Chat message must be less than ${MAX_CHAT_MSG_LENGTH}`,
      })

    this.io.emit('send_chat', msg)
    // @TODO: Implement optimistic UI update so client immediately sees their message
  }
}
