import useAuth from '@/hooks/useAuth'
import { Navigate } from 'react-router-dom'
// import { Redirect } from '../Routing/Redirect'
import { useEffectOnce } from 'react-use'
import { useDebouncedCallback } from 'use-debounce'
import { addAppNoti } from '@/store/useNotiStore'
import { BORDER_COLORS } from '@/design'
import { motion, type Variants } from 'framer-motion'

const LinkingTwitterAlert = styled(motion.div)`
  display: flex;
  text-align: center;
  text-wrap: nowrap;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 50%;
  height: 30%;
  margin: auto;
  background-color: black;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
`

const AlertVariant: Variants = {
  initial: {
    y: -500,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    y: -500,
    opacity: 0
  }
}

const LinkTwitter = () => {
  const { authApi } = useAuth()
  const [loading, setLoading] = useState(true)
  // use useEffect and send a request to backend with both twitter session and auth token to verify both twitter and metamask account
  // after that redirect to coin flip
  // @TODO: Works as expected if it successfully links twitter account with publicAddress. But does not handle errors yet
  useEffectOnce(() => {
    linkTwitter()
  })

  const linkTwitter = useDebouncedCallback(
    async () => {
      try {
        await authApi.linkTwitter()
        addAppNoti({
          msg: `Twitter connected`,
          type: 'success',
        })
      } catch (err) {
        if ((err as unknown as any).response.data.message) {
          addAppNoti({
            msg: `${(err as any).response.data.message}`,
            type: 'error',
          })
          throw new Error(`Error linking twitter`)
        } else {
          addAppNoti({
            msg: `Failed: twitter connection`,
            type: 'error',
          })
          throw new Error(`Error linking twitter no twitter connection`)
        }
      } finally {
        setLoading(false)
      }
    },
    250,
    { leading: false }
  )

  return loading ? <LinkingTwitterAlert>linking twitter...</LinkingTwitterAlert> : <Navigate to='/settings' replace />
}

export default LinkTwitter
