import { type PropsWithChildren } from 'react'
import { useEffect, useRef } from 'react'
import { type GameModes } from '@/components/shared/Spinner'
import { FloatingContainer, PageWrapper, ScrollContainer, ScrollItem } from './style'
import { MobileTrollBox } from '@/components/shared/MobileMenuModals/MobileTrollBox'
import { MobileLiveEntries } from '@/components/shared/MobileMenuModals/MobileLiveEntries'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'

interface IGamePageProps extends PropsWithChildren {
  mode: GameModes
}

export const GamePage = ({ children, mode: _mode }: IGamePageProps) => {
  const isMobileScreen = useIsBreakpoint('sm')
  const middleSectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (middleSectionRef.current) {
      middleSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])
  return (
    <>
      <PageWrapper>
        {isMobileScreen ?
          <ScrollContainer>
            <ScrollItem>
              <MobileTrollBox isShowChat={true} />
            </ScrollItem>
            <ScrollItem ref={middleSectionRef}>
              <FloatingContainer
                transition={{
                  duration: 0.5,
                  ease: 'easeIn',
                }}
                initial={{
                  opacity: 0,
                  // transform: 'scale(0.95)', // @TODO: Animate scaling causes issue with game canvases
                }}
                animate={{
                  opacity: 1,
                  // transform: 'scale(1)',
                }}
                exit={{
                  opacity: 0,
                  // transform: 'scale(0.95)',
                }}
              >
                {children}
              </FloatingContainer>
            </ScrollItem>
            <ScrollItem>
              <MobileLiveEntries isShowLiveEntries={true} />
            </ScrollItem>
          </ScrollContainer>
        : <FloatingContainer
            transition={{
              duration: 0.5,
              ease: 'easeIn',
            }}
            initial={{
              opacity: 0,
              // transform: 'scale(0.95)', // @TODO: Animate scaling causes issue with game canvases
            }}
            animate={{
              opacity: 1,
              // transform: 'scale(1)',
            }}
            exit={{
              opacity: 0,
              // transform: 'scale(0.95)',
            }}
          >
            {children}
          </FloatingContainer>
        }
      </PageWrapper>
    </>
  )
}
