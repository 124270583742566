import { SVGS } from '@/assets'
import { PageWrapper } from '../style'
import {
  PointsMultiplierContainer,
  PointsBodyContainer,
  PointsKillsStatsContainer,
  PointsCount,
  PointsUserAvatarInfoWrapper,
  PointsLeftContainer,
  PointsNameContainer,
  PointsPageWrapper,
  PointsRightContainer,
  PointsStatsContainer,
  PointsUpperContainer,
  RankContainer,
  TotalPoints,
  TotalPointsContainer,
  PointsInfoContainer,
  PointsAssistsStatsContainer,
  PointsDeathsStatsContainer,
  PointsPlus,
  PointsInfoCalculatedContainer,
  PointsBadge,
  PointsAvatarContainer,
  PointsBotContainer,
  SShareBtn,
  SPointsExplainerContainer,
  PointsInfoColumnWrapper,
} from './style'
import { FARE_COLORS } from '@/design'
import CountUp from 'react-countup'
import LeaderboardHeader from '@/components/LeaderboardHeader'
import QuestDetails from './QuestDetails'
import LeaderboardTabs from './LeaderboardTabs'
import LeaderboardGrid, { MIN_LEADERBOARD_POINT } from '@/components/LeaderboardGrid'
import PointsExplainer from './PointsExplainer'
import InviteCodes from './InviteCodes'
import useUserDataStore from '@/store/useUserDataStore'
import { useWeb3 } from '@/hooks/useWeb3'
import { PointsPageMounter } from './PointsPageMounter'
import useInviteCodeStore from '@/store/useInviteCodeStore'
import { useShallow } from 'zustand/react/shallow'
import AuthWallet from '@/components/shared/Wallet/AuthWallet'
import useAuthStore from '@/store/useAuthStore'
import ConnectWallet from '@/components/shared/Wallet/ConnectWallet'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { numBP } from '@/design/breakpoints'
import numeral from 'numeral'
import { useScreenshotElement } from '@/hooks/useScreenshotElement'
import { BigNumber } from 'ethers'
import { multiplyBigNumberWithFixedPointNumber } from '@/lib/crypto'
import { useUserImageToRender, useUsernameToRender } from '@/utils/userDisplay'
import { addAppNoti } from '@/store/useNotiStore'
import { useDeathmatchTab } from '@/store/useDeathmatchTab'
import { useDebouncedCallback } from 'use-debounce'

export interface IFarePointsDetail {
  deathmatchRank: number
  deathmatchMultiplier: number
  levelId: number
  levelName: string
  bonusLevelId: number
  killPoints: number
  deathPoints: number
  assistPoints: number
  totalPoints: number
  entryCount: number
  twitterImageLink: string
  discordId: string
}

export const MAX_LEVEL = 6

export const PointsPage = () => {
  const { tabOption } = useDeathmatchTab()
  const [selectedTab, setSelectedTab] = useState('dashboard')
  const { account = '', isActive } = useWeb3()
  const {
    username,
    avatarSeedAddress,
    isUsingTwitterToDisplay,
    twitterUsername,
    twitterImage,
    accWinningAmount,
    accLosingAmount,
    winThresholdForPoint,
    loseThresholdForPoint,
    bonusLevelPoints,
    levelMultiplier,
    cachedAssistPoints,
  } = useUserDataStore()
  const { authTokenObj, authPublicAddress } = useAuthStore(state => ({
    authTokenObj: state.authTokenObj,
    authPublicAddress: state.authPublicAddress,
  }))
  const authToken = useMemo(
    () => authTokenObj[authPublicAddress],
    [authTokenObj, authPublicAddress]
  )

  const isMobile = useIsBreakpoint(numBP.sm)

  const captureRef = useRef<any>(null)
  const screenshot = useScreenshotElement(captureRef)
  const [isScreenShotting, setIsScreenShotting] = useState(false)

  const details = useInviteCodeStore(useShallow(state => state.pointsDetails))

  const userImageToRender = useUserImageToRender(
    account,
    isUsingTwitterToDisplay,
    avatarSeedAddress,
    twitterImage,
    {
      width: '92px',
      height: '92px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    {
      width: '58px',
      height: '58px',
      borderRadius: '100%',
    },
    {
      width: '92px',
      height: '92px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    {
      width: '58px',
      height: '58px',
      borderRadius: '100%',
    }
  )

  const usernameToRender = useUsernameToRender(
    account,
    isUsingTwitterToDisplay,
    username,
    twitterUsername,
    {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      marginBottom: '4px',
    },
    {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      marginBottom: '4px',
    },
    {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      marginBottom: '4px',
    },
    false,
    false
  )

  const additionalUserDetails = useMemo(() => {
    const userRank =
      details?.deathmatchRank !== undefined && details.deathmatchRank !== -1 ?
        (details.deathmatchRank || 0) + 1
      : null
    const rankDisplayText =
      userRank && details && details.totalPoints >= MIN_LEADERBOARD_POINT ?
        `RANK #${userRank}`
      : 'NO RANK'
    const wagerCount = details?.entryCount ? numeral(details.entryCount).format('0,0') : 0

    if (isMobile) {
      return (
        <RankContainer>
          {wagerCount} wagers | {details?.levelName} | {rankDisplayText}
        </RankContainer>
      )
    }

    return (
      <>
        <RankContainer>
          <span>{wagerCount} wagers</span>
        </RankContainer>
        <RankContainer>
          <span>{details?.levelName}</span>
        </RankContainer>
        <RankContainer>
          <span>{rankDisplayText}</span>
        </RankContainer>
      </>
    )
  }, [details, isMobile])

  const shareOnXClick = useDebouncedCallback(
    async () => {
      if (isScreenShotting) return
      setIsScreenShotting(true)
      try {
        addAppNoti({
          msg: 'Sharing on X',
          type: 'success',
        })
        const returnedData = await screenshot()
        console.log(returnedData)
        window.open((returnedData as any).linkToRedirect, '_blank')
      } catch (err) {
        addAppNoti({
          msg: 'Failed: share on X',
          type: 'error',
        })
        throw new Error('Error while sharing on X')
      } finally {
        setIsScreenShotting(false)
      }
    },
    2_000,
    { leading: true }
  )

  return (
    <PageWrapper>
      {!isActive ?
        <ConnectWallet />
      : !authToken ?
        <AuthWallet />
      : <>
          <PointsPageWrapper ref={captureRef}>
            <LeaderboardHeader heading={selectedTab.toUpperCase()} />
            <PointsBodyContainer>
              <PointsUpperContainer>
                <PointsLeftContainer>
                  <PointsUserAvatarInfoWrapper>
                    <PointsAvatarContainer>
                      {userImageToRender}
                      <PointsBadge>
                        {details ?
                          <img
                            className='badge-img'
                            src={(SVGS as any)[`level${details.levelId || 0}Icon`]}
                            style={{ opacity: details.levelId === 0 ? 0.25 : 1 }}
                            id={String(details.levelId)}
                          />
                        : <div className='badge-img'></div>}
                      </PointsBadge>
                    </PointsAvatarContainer>
                    <PointsNameContainer>
                      {/* <span className='address'>{usernameToDisplay}</span> */}
                      {usernameToRender}
                      {additionalUserDetails}
                    </PointsNameContainer>
                  </PointsUserAvatarInfoWrapper>
                  <TotalPointsContainer>
                    <TotalPoints>YOUR POINTS</TotalPoints>
                    <PointsCount>
                      <CountUp
                        end={
                          multiplyBigNumberWithFixedPointNumber(
                            BigNumber.from(accWinningAmount || '0'),
                            String(levelMultiplier || 1)
                          )
                            .div(winThresholdForPoint || 1)
                            .toNumber() +
                            multiplyBigNumberWithFixedPointNumber(
                              BigNumber.from(accLosingAmount || '0'),
                              String(levelMultiplier || 1)
                            )
                              .div(loseThresholdForPoint || 1)
                              .toNumber() +
                            // Add Assists
                            (cachedAssistPoints || 0) +
                            // Add bonus level points
                            (bonusLevelPoints || 0) || 0
                        }
                        separator=','
                      />
                    </PointsCount>
                  </TotalPointsContainer>
                </PointsLeftContainer>
                <PointsRightContainer>
                  <PointsStatsContainer>
                    <PointsKillsStatsContainer>
                      <div className='container'>
                        <span className='heading'>KILLS</span>
                        <CountUp
                          className='count'
                          end={BigNumber.from(accWinningAmount || '0')
                            .div(winThresholdForPoint || 1)
                            .toNumber()}
                        />
                      </div>
                      <PointsPlus>
                        <img src={SVGS.plusIcon} />
                      </PointsPlus>
                    </PointsKillsStatsContainer>
                    <PointsDeathsStatsContainer>
                      <div className='container'>
                        <span className='heading'>DEATHS</span>
                        <CountUp
                          className='count'
                          end={BigNumber.from(accLosingAmount || '0')
                            .div(loseThresholdForPoint || 1)
                            .toNumber()}
                        />
                      </div>
                      <PointsPlus>
                        <img src={SVGS.plusIcon} />
                      </PointsPlus>
                    </PointsDeathsStatsContainer>
                    <PointsAssistsStatsContainer>
                      <div className='container'>
                        <span className='heading'>ASSISTS</span>
                        <CountUp className='count' end={details?.assistPoints || 0} />
                      </div>
                    </PointsAssistsStatsContainer>
                  </PointsStatsContainer>
                  <PointsInfoColumnWrapper>
                    <PointsMultiplierContainer>
                      <div className='container'>
                        <span className='heading'>MULTIPLIER</span>
                        <div className='multiplierContainer'>
                          <span className='count'>
                            {details?.levelId === MAX_LEVEL ?
                              '?'
                            : details?.deathmatchMultiplier || 1}
                            X
                          </span>
                        </div>
                      </div>
                    </PointsMultiplierContainer>
                    <SPointsExplainerContainer>
                      <PointsExplainer />
                      <PointsInfoContainer>
                        <PointsInfoCalculatedContainer>
                          <span>
                            (
                            <CountUp
                              end={
                                BigNumber.from(accWinningAmount || '0')
                                  .div(winThresholdForPoint || 1)
                                  .toNumber() || 0
                              }
                              separator=','
                            />{' '}
                            <span style={{ color: FARE_COLORS.aqua }}> K </span> +{' '}
                            <CountUp
                              end={
                                BigNumber.from(accLosingAmount || '0')
                                  .div(loseThresholdForPoint || 1)
                                  .toNumber() || 0
                              }
                              separator=','
                            />{' '}
                            <span style={{ color: FARE_COLORS.salmon }}>D</span>) x{' '}
                            {details?.levelId === MAX_LEVEL ?
                              '?'
                            : <CountUp
                                end={details?.deathmatchMultiplier || 1}
                                separator=','
                                decimals={1}
                              />
                            }
                            <span> + </span>
                            <CountUp end={details?.assistPoints || 0} separator=',' />{' '}
                            <span style={{ color: FARE_COLORS.peach }}>A</span>{' '}
                          </span>
                        </PointsInfoCalculatedContainer>
                      </PointsInfoContainer>
                    </SPointsExplainerContainer>
                  </PointsInfoColumnWrapper>
                </PointsRightContainer>
              </PointsUpperContainer>
              <PointsBotContainer>
                {/* <LeaderboardTabs onChange={setSelectedTab} selectedTab={selectedTab} /> */}
                <LeaderboardTabs selectedTab={tabOption} />
                {tabOption === 'dashboard' && (
                  <QuestDetails screenshot={screenshot} isMobile={isMobile} />
                )}
                {tabOption === 'leaderboard' && <LeaderboardGrid isMobile={isMobile} />}
                {tabOption === 'invite codes' && <InviteCodes />}
              </PointsBotContainer>
              <SShareBtn onClick={isScreenShotting ? () => {} : shareOnXClick}>
                Share on X <img src={SVGS.shareIcon} alt='Sharing' />
              </SShareBtn>
            </PointsBodyContainer>
          </PointsPageWrapper>
          <PointsPageMounter publicAddress={authPublicAddress} />
        </>
      }
    </PageWrapper>
  )
}
