import { create } from 'zustand'
import { type GameNames } from '@/lib/crypto'
import { createGameStateStore, initialGameEntryState } from '@fare/sdk/state'
import { createGameLiveEntrySlice } from '@/lib/fare/state/gameLiveEntrySlice'

export type GameStoreName = 'coin-flip' | 'dice' | 'rps'

export const useCoinFlipGameState = createGameStateStore({
  entry: {
    ...initialGameEntryState,
    side: Math.round(Math.random()), // Select heads or tails randomly on load
  },
})

export const useDiceGameState = createGameStateStore({
  entry: {
    ...initialGameEntryState,
    side: 50,
  },
})

export const useRPSGameState = createGameStateStore({
  entry: {
    ...initialGameEntryState,
    side: Math.floor(Math.random() * 3), // Select random side (0-2)
  },
})

export const useDelayedLiveEntry = create(createGameLiveEntrySlice)

export const gameStoreMap: Record<GameStoreName, ReturnType<typeof createGameStateStore>> = {
  'coin-flip': useCoinFlipGameState,
  dice: useDiceGameState,
  rps: useRPSGameState,
}

export const sendGameStoreUpdate = (gameStoreName: GameStoreName) => {
  const store = gameStoreMap[gameStoreName]
  return store.getState().send
}

export const gameStoreMapByGameName: Record<GameNames, ReturnType<typeof createGameStateStore>> = {
  coinFlip: useCoinFlipGameState,
  dice: useDiceGameState,
  rps: useRPSGameState,
}

export const sendGameStoreUpdateByAddress = (gameName: GameNames) => {
  if (!gameName) return
  console.log(`gameName: `, gameName)
  const store = gameStoreMapByGameName[gameName]
  return store.getState().send
}
