import Avatar from '@/components/shared/Avatar'
import { Button } from '@/components/shared/Button'
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COMPONENTS,
  FARE_COLORS,
  FONT_STYLES,
  SPACING,
  TEXT_COLORS,
  floatingContainer,
} from '@/design'
import { motion } from 'framer-motion'

export const SettingsWrapper = styled.div<{ isMobileScreen?: boolean }>`
  ${({ isMobileScreen }) =>
    isMobileScreen ?
      css`
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100vw;
        max-height: calc(100vh - ${COMPONENTS.header}px);
        overflow: scroll;
      `
    : css`
        display: grid;
        grid-template-columns: 200px 1fr;
        width: calc(100% - ${SPACING.md * 2}px);
        height: calc(100% - ${SPACING.md * 2}px);
      `}
  ${floatingContainer};
  /* background-color: ${BACKGROUND_COLORS.one}; */
  border-radius: 6px;
`

export const SettingsTabWrapper = styled.div`
  padding: ${SPACING.xxs}px;
  background: rgba(0, 0, 0, 0.06);
  /* background-color: ${BACKGROUND_COLORS.two}; */
  padding-left: 12px;
  padding-top: 16px;
  user-select: none;
`

export const SettingLink = styled.div<{ $isActive?: boolean }>`
  padding: 8px ${SPACING.sm}px;
  text-transform: uppercase;
  transition: 0.12s ease-in-out;
  cursor: pointer;
  color: #aaa;
  position: relative;
  padding-top: 10px;
  /* border-left: 3px solid transparent; */

  .tab-selected-bar {
    transition: 0.12s ease-in-out;
    top: 2px;
    left: 0px;
    position: absolute;
    height: 30px;
    width: 2px;
    border: 1px solid rgba(255, 255, 255, 0);
    box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0);
  }

  &:not(:last-of-type) {
    margin-bottom: ${SPACING.sm}px;
  }

  /* &:hover { */
  /*   color: white; */
  /*   .tab-selected-bar { */
  /*     border: 1px solid rgba(255, 255, 255, 0.8); */
  /*     box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.6); */
  /*   } */
  /* } */

  > span {
    line-height: 1px;
    padding-top: 3px;
  }

  ${({ $isActive }) =>
    $isActive ?
      css`
        /* border-left: 3px solid ${BORDER_COLORS.one}; */
        /* border: 1px solid ${FARE_COLORS.blue}; */
        color: white;
        .tab-selected-bar {
          border: 1px solid rgba(255, 255, 255, 0.8);
          box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.6);
        }
      `
    : css`
        &:hover {
          /* border-left: 3px solid ${BORDER_COLORS.one}; */
        }
        /* border: 1px solid transparent; */
      `};
`

export const MobileSettingsTabWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  min-height: 42px;

  > * {
    flex: 1;
    text-align: center;
  }

  padding: ${SPACING.xxs}px;
`

export const MobileSettingLink = styled.div<{ isActive?: boolean }>`
  padding: ${SPACING.sm}px;
  border-radius: 4px;
  height: fit-content;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  ${({ isActive }) =>
    isActive ?
      css`
        border: 1px solid ${FARE_COLORS.blue};
      `
    : css`
        border: 1px solid transparent;
      `};
`

const expand = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`

export const Gradient = styled.div`
  height: 3px;
  background: ${BACKGROUND_COLORS.two};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`

export const DepositsTitle = styled.div<{
  $depositType: 'eoa' | 'aa' | undefined
  $walletType: string
  $disableCheck?: boolean
}>`
  padding: ${SPACING.md}px;
  margin: auto;
  text-transform: uppercase;
  position: relative;
  display: flex;
  justify-content: space-between;
  background: ${BACKGROUND_COLORS.two};

  > div {
    display: flex;
  }

  > ${Gradient} {
    ${({ $depositType }) => {
      if ($depositType === 'eoa')
        return css`
          background-image: linear-gradient(101deg, ${FARE_COLORS.pink}, ${FARE_COLORS.blue});
          animation: ${expand} 0.2s ease-in-out;
        `
      if ($depositType === 'aa')
        return css`
          background-image: linear-gradient(101deg, ${FARE_COLORS.peach}, ${FARE_COLORS.aqua});
          animation: ${expand} 0.2s ease-in-out;
        `
      return css``
    }}
  }

  &:hover {
    ${props =>
      props.$depositType === undefined &&
      !props.$disableCheck &&
      css`
        cursor: pointer;

        > ${Gradient} {
          ${() => {
            if (props.$depositType === undefined) {
              if (props.$walletType === 'quickplay') {
                return css`
                  opacity: 0.6;
                  height: 1px;
                  background-image: linear-gradient(
                    101deg,
                    ${FARE_COLORS.peach},
                    ${FARE_COLORS.aqua}
                  );
                  animation: ${expand} 0.2s ease-in-out;
                `
              }
              return css`
                opacity: 0.6;
                height: 1px;
                background-image: linear-gradient(101deg, ${FARE_COLORS.pink}, ${FARE_COLORS.blue});
                animation: ${expand} 0.2s ease-in-out;
              `
            }
          }}
        }

        .react-toggle-track {
          box-shadow: inset 0px 0px 36px ${FARE_COLORS.blue}42;
          border: 1px solid ${FARE_COLORS.blue}60 !important;
          ${props.$walletType === 'quickplay' &&
          css`
            box-shadow: inset 0px 0px 36px ${FARE_COLORS.aqua}42;
            border: 1px solid ${FARE_COLORS.aqua}60 !important;
          `}
        }
      `}
  }
`

export const SettingsTitle = styled.div`
  ${FONT_STYLES.lg}
  text-transform: uppercase;
  font-weight: bold;
  margin-top: ${SPACING.sm}px;
  margin-bottom: ${SPACING.xs}px;
`

export const SettingsSubtitle = styled.div`
  ${FONT_STYLES.md}
  margin: ${SPACING.md}px 0;
  color: ${TEXT_COLORS.two};
`

export const SettingsContentWrapper = styled(motion.div)`
  border-left: 1px solid ${BORDER_COLORS.one};
  padding: ${SPACING.sm}px;
  background: black;
`

export const DepositWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.one};
  transition: 0.2s all ease-in-out;
  width: 100%;
  background: ${BACKGROUND_COLORS.two};
  overflow: hidden;

  > *:not(${DepositsTitle}) {
    padding: ${SPACING.sm}px;
  }

  &:hover {
    opacity: 1;
  }

  p {
    margin: 0;
    color: ${TEXT_COLORS.two};
  }

  > ${SettingsContentWrapper} {
    text-align: justify;
  }
`

export const QuickplayWrapper = styled(DepositWrapper)`
  .qp-btn-wrapper {
    display: flex;
  }
`

export const EOAWrapper = styled(DepositWrapper)`
  margin-top: ${SPACING.sm}px;
`

export const BalanceRow = styled.div`
  display: flex;

  img {
    margin: auto 0;
    height: 20px;
    width: 20px;
  }

  p,
  span,
  .balance-amount-display {
    margin: auto 0;
    margin-left: ${SPACING.sm}px;
  }

  &:not(:last-of-type) {
    margin-right: ${SPACING.md}px;
  }
`

export const EncryptionWrapper = styled.div`
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  background: ${BACKGROUND_COLORS.two};

  &:not(:last-of-type) {
    margin-bottom: ${SPACING.md}px;
  }
`

export const EncryptionTitle = styled.div<{ $isActive?: boolean; $isDisabled?: boolean }>`
  padding: ${SPACING.md}px;
  margin: auto;
  text-transform: uppercase;
  position: relative;
  display: flex;
  justify-content: space-between;
  position: relative;

  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  > ${Gradient} {
    ${({ $isActive }) =>
      $isActive ?
        css`
          background-image: linear-gradient(101deg, ${FARE_COLORS.pink}, ${FARE_COLORS.blue});
          animation: ${expand} 0.2s ease-in-out;
        `
      : css`
          background: ${BACKGROUND_COLORS.two};
        `}
  }

  &:hover {
    ${({ $isActive, $isDisabled }) =>
      !$isActive &&
      !$isDisabled &&
      css`
        cursor: pointer;

        > ${Gradient} {
          opacity: 0.6;
          height: 1px;
          background-image: linear-gradient(101deg, ${FARE_COLORS.pink}, ${FARE_COLORS.blue});
          animation: ${expand} 0.2s ease-in-out;
        }

        .react-toggle-track {
          box-shadow: inset 0px 0px 36px ${FARE_COLORS.blue}42;
          border: 1px solid ${FARE_COLORS.blue}60 !important;
        }
      `}
  }
`

export const EncryptionContent = styled.div`
  padding: ${SPACING.md}px;
  color: ${TEXT_COLORS.two};
  text-align: justify;
`

export const TitleWrapper = styled.div`
  display: flex;

  > * {
    margin: auto 0;
  }

  img {
    height: 24px;
    width: 24px;
    margin-right: ${SPACING.xs}px;
  }
`

export const AvatarWrapper = styled.div`
  display: flex;

  > * {
    margin: 0px;
    /* margin: auto 0; */
  }
`

export const ReferralsWrapper = styled.div<{ isMobileScreen?: boolean }>`
  display: grid;
  ${({ isMobileScreen }) =>
    isMobileScreen ?
      css`
        grid-template-columns: 1fr;
      `
    : css`
        grid-template-columns: max-content 1fr;
        grid-gap: ${SPACING.md}px;
      `}

  overflow: hidden;
  width: 100%;

  > * {
    margin: auto 0;
    width: inherit;

    &:nth-child(1) {
      grid-column: 1 / span 1;
    }

    &:nth-child(2) {
      grid-column: 2 / span 1;
    }
  }
`

export const NameWrapper = styled.div`
  color: ${TEXT_COLORS.two};
  ${FONT_STYLES.md};
  margin-bottom: ${SPACING.xs}px;
  &.twitter-link {
    color: ${FARE_COLORS.aqua};
  }
`

export const Bar = styled.div<{ isActive?: boolean }>`
  border-radius: 2px;
  border: 1px solid ${BORDER_COLORS.one};
  height: 4px;
  flex: 1;
  width: 80px;
  max-width: 100%;
  background: ${BACKGROUND_COLORS.two};

  ${({ isActive }) => (isActive ? 'opacity: 1' : 'opacity: 0.2')};
`

export const BarWrapper = styled.div`
  margin-bottom: auto;
  text-align: center;
  flex: 1;

  > span {
    text-transform: uppercase;
  }
`

export const LevelBars = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${SPACING.xxs}px;
  align-items: center;
  margin: auto 0;
  width: 100%;
  max-width: fit-content;

  > ${BarWrapper} {
    &:nth-of-type(1) {
      > ${Bar} {
        background: linear-gradient(to right, ${FARE_COLORS.aqua}, ${FARE_COLORS.blue});
      }
    }

    &:nth-of-type(2) {
      > ${Bar} {
        background: linear-gradient(to right, ${FARE_COLORS.blue}, ${FARE_COLORS.pink});
      }
    }

    &:nth-of-type(3) {
      > ${Bar} {
        background: linear-gradient(to right, ${FARE_COLORS.pink}, ${FARE_COLORS.peach});
      }
    }

    &:nth-of-type(4) {
      > ${Bar} {
        background: linear-gradient(to right, ${FARE_COLORS.peach}, ${FARE_COLORS.salmon});
      }
    }
  }
`

export const StatsWrapper = styled.div<{ isMobileScreen?: boolean }>`
  display: grid;

  ${({ isMobileScreen }) =>
    isMobileScreen ?
      css`
        grid-template-columns: 1fr;
      `
    : css`
        grid-template-columns: 1fr 1fr;
      `}

  margin-top: ${SPACING.md}px;
  grid-gap: ${SPACING.sm}px;
`

export const Stat = styled.div`
  display: flex;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  padding: ${SPACING.sm}px;
  background: ${BACKGROUND_COLORS.two};

  > img {
    width: 24px;
    height: 24px;
    margin: auto 0;
    margin-right: ${SPACING.sm}px;
  }
`

export const Label = styled.div`
  color: ${TEXT_COLORS.two};
`

export const Value = styled.div``

export const RefLink = styled.div`
  margin-top: ${SPACING.md}px;
  display: flex;
  gap: 12px;

  .fare-input-wrapper {
    flex: 1;
  }

  input {
    width: 100%;
    border: solid 1px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(101deg, ${FARE_COLORS.salmon}, ${FARE_COLORS.blue});
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #000 inset;
  }
`

export const Info = styled.div<{ isMobileScreen?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.xs}px;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;

  > * {
    margin: auto 0;
  }

  > div {
    display: flex;

    &:first-of-type {
      flex: 1;
    }

    > *:not(:last-child) {
      margin-right: ${SPACING.sm}px;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
    /*   border-bottom: 1px solid ${BORDER_COLORS.one}; */
  }

  /* &:first-of-type { */
  /*   padding-top: 0; */
  /* } */

  /* &:last-of-type { */
  /*   padding-bottom: 0; */
  /*   margin-bottom: 0px; */
  /* } */

  button {
    width: ${({ isMobileScreen }) => (isMobileScreen ? '80px' : '120px')};
  }

  input {
    padding: ${SPACING.xs}px !important;
  }
  .account-info-label {
    text-transform: uppercase;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &.account-info-wrapper {
    ${Info} {
    }
  }
`

export const BalanceWrapper = styled.div`
  display: flex;

  > * {
    margin: auto 0;
  }
`

export const ButtonWrapper = styled.div<{
  depositType: 'eoa' | 'aa' | undefined
  isMobileScreen?: boolean
}>`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${BORDER_COLORS.one};
  margin-top: ${SPACING.sm}px;
  padding-top: ${SPACING.sm}px;

  ${({ isMobileScreen }) =>
    isMobileScreen ?
      css``
    : css`
        grid-template-columns: 2fr 1fr 1fr;
      `}
  min-height: 42px;

  ${({ depositType }) =>
    depositType === 'aa' ?
      css`
        > ${BalanceWrapper} {
          grid-column: 1 / span 1;
        }

        > button {
          flex: 1;
          min-width: 120px;

          &:not(:last-of-type) {
            margin-right: ${SPACING.sm}px;
          }

          &:nth-of-type(1) {
            grid-column: 3 / span 1;
          }

          &:nth-of-type(2) {
            grid-column: 2 / span 1;
          }
        }
      `
    : css`
        > ${BalanceWrapper} {
          grid-column: 1 / span 1;
        }

        > button {
          flex: 1;
          min-width: 120px;

          &:not(:last-of-type) {
            margin-right: ${SPACING.sm}px;
          }

          grid-column: 2 / span 2;
        }
      `}

  .revoke-btn {
    max-width: 132px !important;
    align-self: flex-end;
  }

  .approve-btn {
    max-width: 132px !important;
    align-self: flex-end;
  }
`
