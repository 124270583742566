import { SVGS } from '@/assets'
import numeral from 'numeral'
import { useForm, Controller } from 'react-hook-form'
import {
  Form,
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  SFormSection,
} from '../shared/Form/style'
import { FareNumberInput } from '../shared/Input/FareNumberInput'
import { type HelperFunctionsWithSide, gameTypeHelperFunctionsMap, unit } from '@/lib/crypto'
import { type ISUContractForm } from '@/store/useSUContractStore'
import { ensureNumber, clamp } from '@/utils/math'
import { BORDER_COLORS } from '@/design'
import CountUp from 'react-countup'
import { useDiceGameState } from '@/store/useGameStateStore'
import { DiceSlider } from '../DiceSlider/DiceSlider'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useShallow } from 'zustand/react/shallow'
import { GameButton } from '../shared/Button/GameButton'

interface IDiceSUForm extends ISUContractForm {
  multiplier: number
  winChance: number
  rollOverNum: number
}

export const cssContainer = css`
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
`

const SForm = styled(Form)`
  ${cssContainer};
`

// @TODO: Need to get this dynamically or put this in a constant file
const HOUSE_EDGE_EV = 1.98

function Slider({ side, setSide }: { side: number; setSide: (val: number) => void }) {
  return <DiceSlider sliderValue={side} setSliderValue={setSide} />
}

const SDoubleInputRow = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  flex-direction: row;
  transition: 0.12s all ease-in-out;
  ${props =>
    props.$isDisabled &&
    css`
      filter: grayscale(100%) brightness(0.8);
      cursor: not-allowed;
      pointer-events: none;
    `}
`

const SDoubleRow = styled.div`
  display: flex;
`

const STripleRow = styled.div`
  display: flex;
  padding: 16px;
  padding-top: 0px;
  padding-right: 12px;
  padding-left: 24px;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  ${SFormSection} {
    flex: 1;
  }
`

const calculateSideFromMultiplier = (multiplier: number) => {
  // Rearrange the equation to solve for side
  const calculatedMultiplierWithoutPPV = multiplier / 0.99
  const side = 10000 - 10000 / calculatedMultiplierWithoutPPV

  return side / 100
}

const calculateMaxCount = (side: number) => {
  let i = 20n
  for (; i >= 1n; i--) {
    const q = ((10000n - BigInt(side)) * unit) / 10000n
    const minQ = q > unit - q ? unit - q : q
    if (minQ ** i / unit ** (i - 1n) > 0n) {
      break
    }
  }
  // when loop ends or breaks out, the i should be the max valid count for given prob
  return Number(i)
}

export const DiceForm = () => {
  const { selectedBalance } = useCurrencyStore(
    useShallow(state => ({
      selectedBalance: state.balances.usdc,
    }))
  )

  const balanceNumber = useMemo(() => Number(selectedBalance) || 0, [selectedBalance])

  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm<IDiceSUForm>({
    mode: 'onChange',
    defaultValues: {
      side: 50,
      entryAmount: balanceNumber < 100 ? balanceNumber : 100,
      multiplier: HOUSE_EDGE_EV,
      winChance: 50,
      numberOfEntries: 1,
      stopGain: 0,
      stopLoss: 0,
      rollOverNum: 0,
    },
  })

  const { selectedSide, setSelectedSide } = useDiceGameState(state => ({
    selectedSide: state.entry.side,
    setSelectedSide: (side: number) => state.setEntry({ side }),
  }))
  const formData = watch()
  const {
    entryAmount,
    numberOfEntries,
    stopLoss,
    // stopGain,
    // multiplier,
    // winChance,
    // rollOverNum,
    // side,
  } = formData

  const { getMultiplierWithPPVFromSide, getMultiplierWithoutPPVFromSide } = useMemo(
    () => gameTypeHelperFunctionsMap.dice as HelperFunctionsWithSide,
    []
  )

  const calculatedMultiplier = useMemo(
    () => getMultiplierWithPPVFromSide(selectedSide * 100),
    [selectedSide, getMultiplierWithPPVFromSide]
  )

  useEffect(() => {
    setValue('rollOverNum', selectedSide)
  }, [selectedSide, setValue])

  useEffect(() => {
    setValue('winChance', 100 - selectedSide)
  }, [selectedSide, setValue])

  useEffect(() => {
    setValue('multiplier', ensureNumber(calculatedMultiplier.toFixed(4)))
  }, [calculatedMultiplier, setValue])

  const [maxCountForSide, setMaxCountForSide] = useState(20)

  // @NOTE: Expects side as same as others. So if side is 53.22 it should be represented as 5322
  const handlePossibleMaxCountChange = (side: any) => {
    if (10000 - side > 0) {
      const maxCount = calculateMaxCount(Math.min(side, 10000 - side))
      // @NOTE: I could have done what I have done below with a single if but using if and else if makes it easier to understand
      if (numberOfEntries > maxCount) {
        // @NOTE: If their current count is above the new max count, I am setting their count to be the new max count
        setValue('numberOfEntries', maxCount)
      } else if (maxCountForSide === numberOfEntries && maxCount > maxCountForSide) {
        // @NOTE: If someone had fulled the slider to have max count, and if they move the slider for win chance that causes the max count to increase, I autyo increase their selected count to be new max
        setValue('numberOfEntries', maxCount)
      }
      setMaxCountForSide(maxCount)
    }
  }

  return (
    <SForm>
      <form>
        <SFormSection className='no-border'>
          <Slider
            side={selectedSide}
            setSide={val => {
              setSelectedSide(val)
              // @TODO: Could have a useDebounce or something so that we dont calculate for each little movement on slide rather at the end of slider movement
              handlePossibleMaxCountChange(val * 100)
            }}
          />
        </SFormSection>
        <STripleRow>
          <SFormSection className='no-pd'>
            <FormLabelRow className='center-label'>
              <FormLabel>
                <FormAmountLabel>Roll Over</FormAmountLabel>
              </FormLabel>
            </FormLabelRow>
            <Controller
              {...register('rollOverNum', { required: true, max: 99.9, min: 5 })}
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <>
                  <FareNumberInput
                    {...field}
                    style={{ paddingRight: 0 }}
                    onChange={event => {
                      field.onChange(event.target.value as any)
                      handlePossibleMaxCountChange(Number(event.target.value) * 100)
                    }}
                    onBlur={e => {
                      const MIN = 5
                      const MAX = 99.9
                      const val = clamp(
                        numeral(e.currentTarget.value || '0').value() || 0,
                        MIN,
                        MAX
                      )

                      setSelectedSide(val)
                    }}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator=','
                  />
                </>
              )}
            />
          </SFormSection>
          <SFormSection className='no-pd'>
            <FormLabelRow className='center-label'>
              <FormLabel>
                <FormAmountLabel>Win Chance</FormAmountLabel>
              </FormLabel>
            </FormLabelRow>
            <Controller
              {...register('winChance', { required: true, max: 99.9, min: 0 })}
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <>
                  <FareNumberInput
                    {...field}
                    style={{ paddingRight: 0 }}
                    onChange={event => {
                      field.onChange(event.target.value as any)
                      handlePossibleMaxCountChange(Number(event.target.value) * 100)
                    }}
                    onBlur={e => {
                      const MIN = 0.1
                      const MAX = 95
                      const val = clamp(
                        numeral(e.currentTarget.value || '0').value() || 0,
                        MIN,
                        MAX
                      )
                      setSelectedSide(100 - val)
                    }}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator=','
                  />
                </>
              )}
            />
          </SFormSection>
          <SFormSection className='no-pd'>
            <FormLabelRow className='center-label'>
              <FormLabel>
                <FormAmountLabel>Multiplier</FormAmountLabel>
              </FormLabel>
            </FormLabelRow>
            <Controller
              {...register('multiplier', {
                required: true,
                max: 99.9,
                min: 1.0421,
              })}
              control={control}
              defaultValue={calculatedMultiplier}
              render={({ field }) => {
                const MIN = 1.0421
                const MAX = 990
                return (
                  <>
                    <FareNumberInput
                      {...field}
                      style={{ paddingRight: 0 }}
                      onChange={event => field.onChange(ensureNumber(event.currentTarget.value))}
                      onBlur={event => {
                        const val = clamp(
                          numeral(event.currentTarget.value || '0').value() || calculatedMultiplier,
                          MIN,
                          MAX
                        )
                        const calculatedSide = calculateSideFromMultiplier(val)

                        setSelectedSide(calculatedSide)
                        field.onChange(val)
                      }}
                      allowLeadingZeros={false}
                      allowNegative={false}
                      decimalScale={4}
                      fixedDecimalScale
                      thousandSeparator=','
                    />
                  </>
                )
              }}
            />
          </SFormSection>
        </STripleRow>
        <SFormSection>
          <FormLabelRow>
            <FormLabel>
              <FormIndicator $isActive={entryAmount > 0} />
              <FormAmountLabel>Amount</FormAmountLabel>
            </FormLabel>
            <FormLabel>
              {balanceNumber > 0 && (
                <FormAmountLabel>
                  <CountUp
                    end={balanceNumber}
                    decimals={2}
                    duration={2}
                    separator={','}
                    preserveValue
                  />
                </FormAmountLabel>
              )}
            </FormLabel>
          </FormLabelRow>
          <Controller
            name='entryAmount'
            control={control}
            rules={{ required: true, max: 1_000_000_000, min: 0 }}
            defaultValue={0}
            render={({ field }) => {
              const { ref, ...props } = field
              const value = numeral(field.value).value() || 0
              const isDisabled = (numeral(selectedBalance).value() || 0) === 0

              return (
                <FareNumberInput
                  {...props}
                  getInputRef={ref}
                  onChange={event => field.onChange(numeral(event.target.value).value() as any)}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  thousandSeparator=','
                  decimalScale={2}
                  hasInputSlider
                  disabled={isDisabled}
                  inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                  inputSliderProps={{
                    value,
                    onChange: sliderValue => field.onChange(sliderValue),
                    min: 0,
                    max: balanceNumber,
                    step: 1,
                  }}
                />
              )
            }}
          />
        </SFormSection>
        <SFormSection>
          <FormLabelRow>
            <FormLabel>
              <FormIndicator $isActive={numberOfEntries > 0} />
              <FormAmountLabel>Number of Entries</FormAmountLabel>
            </FormLabel>
            <FormLabel>
              {Number(entryAmount / (numberOfEntries || 1)) > 0 && (
                <FormAmountLabel>
                  <CountUp
                    end={Number(entryAmount / (numberOfEntries || 1))}
                    decimals={2}
                    duration={0.18}
                    separator={','}
                    preserveValue
                  />
                  <span>&nbsp;/ entry</span>
                </FormAmountLabel>
              )}
            </FormLabel>
          </FormLabelRow>
          <Controller
            name='numberOfEntries'
            control={control}
            rules={{
              required: `Must be ${maxCountForSide} or less.`,
              max: { message: `Must be less than ${maxCountForSide}`, value: maxCountForSide },
              min: 1,
            }}
            render={({ field }) => {
              const MIN = 1
              const MAX = maxCountForSide
              const value = numeral(field.value).value()
              const clampedValue = clamp(ensureNumber(value), MIN, MAX)
              const { ref, ...props } = field

              return (
                <>
                  <FareNumberInput
                    {...props}
                    getInputRef={ref}
                    onChange={event => field.onChange(ensureNumber(event.currentTarget.value))}
                    onBlur={event => {
                      const val = clamp(ensureNumber(event.currentTarget.value), MIN, MAX)
                      field.onChange(val)
                    }}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    hasInputSlider={true}
                    inputSliderProps={{
                      value: clampedValue,
                      onChange: sliderValue => field.onChange(sliderValue),
                      min: 1,
                      max: maxCountForSide,
                      step: 1,
                    }}
                  />
                </>
              )
            }}
          />
        </SFormSection>
        <SDoubleInputRow $isDisabled={numberOfEntries <= 1}>
          <SFormSection key={3}>
            <FormLabelRow>
              <FormLabel>
                <FormIndicator $isActive={stopLoss > 0} />
                <FormAmountLabel>Stop Loss</FormAmountLabel>
              </FormLabel>
            </FormLabelRow>
            <Controller
              name='stopLoss'
              control={control}
              defaultValue={0}
              render={({ field: { onChange, ref, ...props } }) => (
                <FareNumberInput
                  {...props}
                  getInputRef={ref}
                  onChange={event => onChange(event.target.value as any)}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  thousandSeparator=','
                  inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                />
              )}
            />
            {/* {row.error} ERROR SHOULD BE HERE */}
          </SFormSection>
          <SFormSection key={4}>
            <FormLabelRow>
              <FormLabel>
                <FormIndicator $isActive={stopLoss > 0} />
                <FormAmountLabel>Stop Gain</FormAmountLabel>
              </FormLabel>
            </FormLabelRow>
            <Controller
              name='stopGain'
              control={control}
              defaultValue={0}
              render={({ field: { onChange, ref, ...props } }) => (
                <FareNumberInput
                  {...props}
                  getInputRef={ref}
                  onChange={event => onChange(event.target.value as any)}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  thousandSeparator=','
                  inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                />
              )}
            />
            {/* {row.error} ERROR SHOULD BE HERE */}
          </SFormSection>
        </SDoubleInputRow>
        <FormFooter>
          <div>
            <GameButton
              formData={{ ...formData, side: selectedSide * 100 }}
              entryAmountNum={entryAmount}
            />
          </div>
        </FormFooter>
      </form>
    </SForm>
  )
}
