import { InviteCodeModalWrapper } from './style'

import { BaseModal } from '../Modal'
import useAuthStore from '@/store/useAuthStore'
import { Input } from '../Input'
import { Button, ButtonEnum } from '../Button'
import { inviteCodesApi } from '@/lib/http/inviteCodes'
import { COLORS, FARE_COLORS } from '@/design'
import { useQuery } from '@/hooks/useQuery'
import { FormEvent } from 'react'

interface IInviteCodeModal {
  isCloseable?: boolean
  onSuccess?: () => void
}

export const InviteCodeModal = ({ isCloseable = true, onSuccess = () => {} }: IInviteCodeModal) => {
  const {
    setIsShowingInviteCodeModal,
    isShowingInviteCodeModal,
    authPublicAddress,
    hasEnteredInviteCode,
  } = useAuthStore(state => ({
    setIsShowingInviteCodeModal: state.setIsShowingInviteCodeModal,
    isShowingInviteCodeModal: state.isShowingInviteCodeModal,
    authPublicAddress: state.authPublicAddress,
    hasEnteredInviteCode: state.hasEnteredInviteCode,
  }))
  const query = useQuery()
  const [inviteCode, setInviteCode] = useState(query.get('inviteCode') || '')
  const [isValidating, setIsValidating] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [outOfRequests, setIsOutOfRequests] = useState(false)

  const tryInviteCode = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      try {
        setIsValidating(true)
        setErrorMsg('')
        const resp = await inviteCodesApi.tryInviteCode(inviteCode, authPublicAddress)
        if (!resp.isSuccessful) {
          if (resp.hoursLeft) setIsOutOfRequests(true)
          setErrorMsg(resp.reason || 'Error with request')
          throw new Error(resp.reason || 'Error with request')
        }

        useAuthStore.setState({ hasEnteredInviteCode: true })
        setIsShowingInviteCodeModal(false)
        onSuccess()
      } catch (err) {
        throw new Error(err as any)
      } finally {
        setIsValidating(false)
      }
    },
    [authPublicAddress, inviteCode, setIsShowingInviteCodeModal]
  )

  return (
    <BaseModal
      isCloseable={isCloseable}
      show={isShowingInviteCodeModal}
      setShow={setIsShowingInviteCodeModal}
      title={'Enter Invite Code:'}
      noPadding
      content={
        <InviteCodeModalWrapper onSubmit={tryInviteCode}>
          <span
            style={{
              color: FARE_COLORS.salmon,
              paddingBottom: 18,
            }}
          >
            {errorMsg}
          </span>
          <Input
            placeholder={'Invite Code...'}
            value={inviteCode}
            onChange={ev => setInviteCode(ev.currentTarget.value.trim())}
          />
          <Button
            style={{ marginTop: 16 }}
            buttonType={ButtonEnum.CONNECT_WALLET}
            disabled={isValidating || !inviteCode || outOfRequests}
            isLoading={isValidating}
            // onClick={tryInviteCode}
            type='submit'
          >
            Submit
          </Button>
        </InviteCodeModalWrapper>
      }
    />
  )
}
