import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  FARE_COLORS,
  FONT_STYLES,
  SPACING,
  TEXT_COLORS,
  floatingContainer,
} from '@/design'
import { Input } from '../Input'
import { motion } from 'framer-motion'

export const SBottomScroller = styled(motion.div)`
  position: absolute;
  bottom: 72px;
  right: 10px;
  cursor: pointer;
  background-color: rgba(0, 19, 48, 0.8);
  border: 1px solid #0f6fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 26px;
  width: 26px;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

  > img {
    height: 24px;
    width: 24px;
  }
`

export const STrollBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  ${floatingContainer};
  background: black;
  overflow: hidden;
  .chat-box-msg {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  em-emoji-picker {
    --font-family: GohuUppercase, monospace;
    --font-size: 12px;
    --border-radius: 6px;
    --rgb-color: 170, 170, 170;
    --rgb-background: 10, 10, 10;
    --sidebar-width: 0px;
    height: 250px;
    border: 1px solid ${BORDER_COLORS.one};
  }
`

export const SSendBtn = styled.button<{ $hasMessageText: boolean }>`
  cursor: pointer;
  border: 1px solid ${BORDER_COLORS.one};
  box-shadow: 0px 0px 0px ${FARE_COLORS.blue};
  background: black;
  border-radius: 6px;
  flex: 1;
  margin-left: 8px !important;
  color: ${TEXT_COLORS.two};
  line-height: 10px;
  font-family: 'GohuUppercase', monospace;
  transition: 0.12s all ease-in-out;

  ${props =>
    props.$hasMessageText &&
    css`
      color: ${TEXT_COLORS.one};
      border: 1px solid ${FARE_COLORS.blue};
      box-shadow: 0px 0px 3px ${FARE_COLORS.blue};
      &:hover {
        background: ${FARE_COLORS.blue}90;
        box-shadow: 0px 0px 5px ${FARE_COLORS.blue};
      }
    `}

  &:disabled {
    cursor: not-allowed;
  }
`

export const SChatList = styled(motion.div)`
  display: flex;
  overflow: scroll;
  flex-direction: column;
  padding: ${SPACING.xs}px;
  flex: 1;
`

export const SInput = styled(Input)`
  &:focus {
    border: 1px solid ${BORDER_COLORS.one};
    /* background: #101010; */
  }
`

export const SChatInputActions = styled.div`
  display: flex;
  align-items: stretch;
  border-top: 1px solid ${BORDER_COLORS.one};
  height: 42px;
  padding: ${SPACING.xs}px;
  .chat-box-input {
    width: 100%;
    height: 26px;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-right: 0px !important;
  }
`

export const SEmojiButton = styled.button`
  all: unset;
  transition: 0.2s ease-in-out;
  background: ${BACKGROUND_COLORS.two};
  border: 1px solid ${BORDER_COLORS.one};
  position: relative;

  color: ${TEXT_COLORS.two};
  ${FONT_STYLES.xs};
  /* border-radius: 6px; */
  width: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: ${SPACING.xxs}px;

  /* border: none; */
  margin: 0px !important;
  border-left: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  img {
    align-self: center;
    width: 14px;
    height: 14px;
    /* filter: invert(100); */
    cursor: pointer;
    transition: all ease-in-out 0.2s;
  }
`

export const SPickerWrapper = styled(motion.div)`
  position: absolute;
  bottom: 67px;
  right: 8px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
`
