import { create } from 'zustand'
import { LOCAL_STORAGE } from '@/constants/utils'
import { ConnectionType } from '@/constants/web3'

export type AuthState = {
  isAuthing: boolean
  isVerifying: boolean
  authTokenObj: { [publicAddress: string]: string }
  connectorKey: ConnectionType
  authPublicAddress: string
  username: string
  isShowingInviteCodeModal: boolean
  hasEnteredInviteCode: boolean
  inviteCodesRequested: number
  hasVerifiedPrivateRoute: boolean
}

export type AuthStateActions = {
  setIsAuthing: (isAuthing: boolean) => void
  setIsVerifying: (isVerifying: boolean) => void
  setAuthTokenObj: (authTokenObj: { [publicAddress: string]: string }) => void
  addAuthToken: (authToken: string, publicAddress: string) => void
  removeAuthToken: (publicAddress: string) => void
  setAuthPublicAddress: (publicAddress: string) => void
  setUsername: (username: string) => void
  setIsShowingInviteCodeModal: (isShowingInviteCodeModal: boolean) => void
  setHasVerifiedPrivateRoute: (hasVerifiedPrivateRoute: boolean) => void
}

// @TODO: Consider not having authTokenObj as state and only relay on localStorage
export const initialAuthState: AuthState = {
  isAuthing: false,
  isVerifying: false,
  authTokenObj:
    localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) ?
      JSON.parse(localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN)!)
    : {},
  connectorKey: ConnectionType.INJECTED,
  authPublicAddress: '',
  username: '',
  isShowingInviteCodeModal: false,
  hasEnteredInviteCode: false,
  hasVerifiedPrivateRoute: false,
  inviteCodesRequested: 0,
}

export type AuthStore = AuthState & AuthStateActions

const useAuthStore = create<AuthStore>(set => ({
  ...initialAuthState,
  setUsername: username => set(() => ({ username })),
  setIsAuthing: isAuthing => set(() => ({ isAuthing })),
  setIsVerifying: isVerifying => set(() => ({ isVerifying })),
  setHasVerifiedPrivateRoute: hasVerifiedPrivateRoute => set(() => ({ hasVerifiedPrivateRoute })),
  setAuthPublicAddress: authPublicAddress => set(() => ({ authPublicAddress })),
  setAuthTokenObj: authTokenObj => set(() => ({ authTokenObj })),
  addAuthToken: (authToken, publicAddress) =>
    set(state => {
      state.authTokenObj[publicAddress] = authToken
      localStorage.setItem(
        LOCAL_STORAGE.AUTH_TOKEN,
        JSON.stringify({
          ...state.authTokenObj,
        })
      )
      return state
    }),
  removeAuthToken: (publicAddress: string) =>
    set(state => {
      delete state.authTokenObj[publicAddress]
      localStorage.setItem(
        LOCAL_STORAGE.AUTH_TOKEN,
        JSON.stringify({
          ...state.authTokenObj,
        })
      )
      return state
    }),
  setIsShowingInviteCodeModal: isShowingInviteCodeModal =>
    set(() => ({
      isShowingInviteCodeModal,
    })),
}))

export default useAuthStore
