// import type { FareBankroll } from '@/lib/crypto/typechain'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type BankrollState = {
  bankrollContract?: any | null
  bankrollUsdcBalance?: string | null
  riskFactor?: number | null
}

export type BankrollActions = {
  setBankrollContract: (bankrollContract: any) => void
  setBankrollUsdcBalance: (bankrollUsdcBalance: string) => void
  setRiskFactor: (riskFactor: number) => void
}

export const initialBankrollState: BankrollState = {
  bankrollContract: null,
  bankrollUsdcBalance: null,
  // @NOTE: Inside the smart contract, risk factor is represented with 2 digits of precision (same as with basis points)
  // @NOTE: So if risk factor is 1%, to reporesent 1.00, we would have 100. So, from smart contract we will receive 100
  riskFactor: null,
}

export type BankrollStore = BankrollState & BankrollActions

const useBankrollStore = create<BankrollStore, [['zustand/immer', BankrollStore]]>(
  immer(set => ({
    ...initialBankrollState,
    setBankrollContract: bankrollContract =>
      set(() => ({
        bankrollContract,
      })),
    setBankrollUsdcBalance: bankrollUsdcBalance =>
      set(() => ({
        bankrollUsdcBalance,
      })),
    setRiskFactor: riskFactor =>
      set(() => ({
        riskFactor,
      })),
  }))
)

export default useBankrollStore
