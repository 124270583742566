import { type GameNames, routeGameTypeMap } from '@/lib/crypto'
import { useGameContractListener } from './useGameContractListener'
import { useOnGameFinsihed } from './useOnGameFinished'

export const GameContractListener = () => {
  const { pathname } = useLocation()
  useGameContractListener(routeGameTypeMap[pathname] as GameNames)
  useOnGameFinsihed(pathname)

  return null
}
