import styled from 'styled-components'

import { BORDER_COLORS } from '@/design'
import { BaseButton } from '../Button/style'

export const WalletButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;

  > * {
    grid-row: span 1;
    width: 100%;
    border: none;
    border-radius: 0;

    &:not(:last-of-type) {
      height: max-content;
    }
  }
`

export const ConnectWalletButton = styled(BaseButton)`
  bottom: 0;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  margin-bottom: 12px;
  > span {
    width: 100%;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
`

export const StyledConnectContent = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  > span {
    line-height: 1px;
    padding-top: 3px;
  }
`

export const InviteCodeModalWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 12px;
  padding-top: 0px;
  width: 300px;

  > * {
    grid-row: span 1;
    width: 100%;
    border: none;
    border-radius: 0;
    text-align: center;

    &:not(:last-of-type) {
      height: max-content;
    }
  }
`
