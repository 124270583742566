import React from 'react'

import { mobileRouteLinks } from '@/constants/links'
import useUserData from '@/hooks/useUserData'
import { TEXT_COLORS, BORDER_COLORS } from '@/design'
import Avatar from '../../Avatar'
import { useWeb3 } from '@/hooks/useWeb3'
import { TransactionHistory } from '@/pages/SettingsPage/TransactionHistory'
import { OverviewPanel } from '../../Panels/RightPanel/OverviewPanel'
import { AnimatedWrapper } from '../animatedWrapper'
import { useMobileVisibility } from '@/hooks/common/useMobileVisibility'

interface IMobileProfileProps {
  isShowProfile: boolean
  onHide?: () => void
}

const commonStyles = css`
  display: flex;
  padding: 16px;
  margin: 16px;
  align-items: center;
  border-radius: 6px;
`

const MobileHeader = styled.div`
  ${commonStyles}
  flex-direction: column;
  margin-block: 32px;
`

const ProfileButtons = styled.div`
  ${commonStyles}
  border: 1px solid ${BORDER_COLORS.one};
`

export const MobileProfile = ({ isShowProfile, onHide }: IMobileProfileProps) => {
  const { username } = useUserData()
  const { account } = useWeb3()
  const isMobileScreen = useMobileVisibility({ onHide })

  if (!isMobileScreen) return null

  return (
    <AnimatedWrapper isVisible={isShowProfile}>
      <div>
        <MobileHeader>
          <Avatar size='lg' seed={account || ''} />
          <span className='account-info-value'>{username || 'UNNAMED'}</span>
        </MobileHeader>
        <a
          href={mobileRouteLinks.find(link => link.title.toLowerCase() === 'settings')?.to}
          style={{ color: TEXT_COLORS.one, textDecoration: 'none' }}
        >
          <ProfileButtons>Edit your profile</ProfileButtons>
        </a>
        <OverviewPanel />
        <ProfileButtons>
          <TransactionHistory />
        </ProfileButtons>
      </div>
    </AnimatedWrapper>
  )
}
