import { useWeb3Modal } from '@web3modal/ethers5/react'

import { Button, ButtonEnum } from '../Button'
import useWalletStore from '@/store/useWalletStore'
import { useWeb3 } from '@/hooks/useWeb3'
import useAuth from '@/hooks/useAuth'

const ConnectWallet = ({ ...props }) => {
  const { isActivating, account, isActive } = useWeb3()
  const { open } = useWeb3Modal()
  const { isShowingWalletModal } = useWalletStore(state => ({
    isShowingWalletModal: state.isShowingWalletModal,
    setIsShowingWalletModal: state.setIsShowingWalletModal,
  }))

  const { authToken, authenticate, isVerifying, isAuthing } = useAuth()

  const isConnecting = useMemo(
    () => isActivating || isVerifying || isShowingWalletModal || isAuthing,
    [isActivating, isVerifying, isShowingWalletModal, isAuthing]
  )

  const loadingText = useMemo(() => {
    if (isActivating || isAuthing || isShowingWalletModal) return 'CONNECT WALLET'

    return 'VERIFYING'
  }, [isActivating, isVerifying, isShowingWalletModal, isAuthing])

  const onClick = useCallback(() => {
    if (!authToken && isActive) {
      return authenticate()
    }

    return open()
  }, [open, authToken, account, isActive])

  return (
    <>
      <Button
        type='button'
        buttonType={ButtonEnum.CONNECT_WALLET}
        disabled={isConnecting}
        onClick={onClick}
        isLoading={isConnecting}
        loadingText={loadingText}
        {...props}
      >
        Connect wallet
      </Button>
    </>
  )
}

export default ConnectWallet
