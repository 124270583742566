import { BORDER_COLORS, FARE_COLORS } from '@/design'
import styled, { keyframes, css } from 'styled-components'

// Define the keyframes for spinning
const spin = keyframes`
  to {
    --bg-angle: 360deg;
  }
`

const quickplayBackgroundGradient = css`
  background:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.86)) padding-box,
    conic-gradient(
        from var(--bg-angle) at 50% 50%,
        ${FARE_COLORS.aqua} 0%,
        ${FARE_COLORS.peach} 25%,
        ${FARE_COLORS.pink} 50%,
        ${FARE_COLORS.peach} 75%,
        ${FARE_COLORS.aqua} 100%
      )
      border-box;
`

// Styled component for .noti-pill-quickplay
export const QuickPlaySetupButton = styled.button`
  --bg-angle: 0deg; // Initial value for CSS variable

  &:focus-visible {
    outline: none;
  }

  color: white;
  width: auto;
  height: 42px;
  border-radius: 6px;
  cursor: pointer;

  user-select: none;
  /* animation: ${spin} 7s infinite linear; */
  background:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.86)) padding-box,
    conic-gradient(
        from var(--bg-angle) at 50% 50%,
        #410dff 0%,
        #ff5e4f 25%,
        #d900d5 50%,
        #ff5e4f 75%,
        #410dff 100%
      )
      border-box;
  ${quickplayBackgroundGradient}
  border: 1px solid transparent;
  transition: all ease-in-out 0.18s;
  > span {
    transition:
      text-shadow ease-in-out 0.18s,
      transform ease-in-out 0.08s;
    display: block;
  }

  /* Handle hover effect and pause state */
  animation: ${spin} 2.75s infinite linear;

  &:not(:disabled) {
    &:hover {
      span {
        transform: scale(1.1, 1.2);
        text-shadow:
          1px 1px 8.3px rgba(255, 255, 255, 0.5),
          1px 1px 8.3px rgba(255, 255, 255, 0.5);
      }
      animation: ${spin} 1s infinite linear;
      background:
        linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)) padding-box,
        conic-gradient(
            from var(--bg-angle) at 50% 50%,
            #410dff 0%,
            #ff5e4f 25%,
            #d900d5 50%,
            #ff5e4f 75%,
            #410dff 100%
          )
          border-box;
    }
  }

  &:disabled {
    animation: ${spin} 1.5s infinite linear;
    opacity: 0.6;
    background:
      linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)) padding-box,
      conic-gradient(
          from var(--bg-angle) at 50% 50%,
          #410dff 0%,
          #ff5e4f 25%,
          #d900d5 50%,
          #ff5e4f 75%,
          #410dff 100%
        )
        border-box;
  }
`

// Styled component for .noti-pill-quickplay
export const QuickPlayButton = styled.button<{ $isActive: boolean }>`
  --bg-angle: 0deg; // Initial value for CSS variable

  &:focus-visible {
    outline: none;
  }

  color: white;
  width: auto;
  height: 42px;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 12px;

  user-select: none;
  animation: ${spin} 10s infinite linear;
  background:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.94), rgba(0, 0, 0, 0.94)) padding-box,
    conic-gradient(
        from var(--bg-angle) at 50% 50%,
        ${FARE_COLORS.aqua} 0%,
        ${FARE_COLORS.peach} 25%,
        ${FARE_COLORS.pink} 50%,
        ${FARE_COLORS.peach} 75%,
        ${FARE_COLORS.aqua} 100%
      )
      border-box;
  border: 1px solid transparent;
  transition: all ease-in-out 0.18s;
  > span {
    transition:
      text-shadow ease-in-out 0.18s,
      transform ease-in-out 0.08s;
    display: block;
  }

  ${props =>
    props.$isActive ?
      css`
        opacity: 1;
      `
    : css`
        /* border: 1px solid rgba(255, 255, 255, 0.16) !important; */
        /* background: #101010; */
        opacity: 0.6;
        &:hover {
          span {
            transform: scale(1.1, 1.2);
            text-shadow:
              1px 1px 8.3px rgba(255, 255, 255, 0.5),
              1px 1px 8.3px rgba(255, 255, 255, 0.5);
          }
          opacity: 1;
          animation: ${spin} 2s infinite linear;
          background:
            linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)) padding-box,
            conic-gradient(
                from var(--bg-angle) at 50% 50%,
                ${FARE_COLORS.aqua} 0%,
                ${FARE_COLORS.peach} 25%,
                ${FARE_COLORS.pink} 50%,
                ${FARE_COLORS.peach} 75%,
                ${FARE_COLORS.aqua} 100%
              )
              border-box;
        }
      `}

  &:disabled {
    cursor: progress;
    opacity: 0.6;
  }
`
