import { gameStoreMapByGameName } from '@/store/useGameStateStore'
import { useBalanceListener } from './useBalanceListener'
import { GameNames, routeGameTypeMap } from '@/lib/crypto'

export const BalanceListener = () => {
  const { pathname } = useLocation()
  useBalanceListener(routeGameTypeMap[pathname] as GameNames)

  return null
}
