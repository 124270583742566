import { fareBankrollAddress, type GameNames } from '@/lib/crypto'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useDebouncedCallback } from 'use-debounce'
import { useCurrencyContracts } from './useCurrencyContracts'
import { useWeb3 } from '@/hooks/useWeb3'
import { gameStoreMapByGameName } from '@/store/useGameStateStore'
import { entryEvent } from '@/events/entryEvent'

export const useBalanceListener = (gameName: GameNames) => {
  const isListeningToUSDC = useRef(true)
  const queuedUSDCBalance = useRef('')
  const useGameStore = useMemo(
    () =>
      gameStoreMapByGameName[gameName] ?
        gameStoreMapByGameName[gameName]
      : gameStoreMapByGameName.coinFlip,
    [gameName]
  )
  const gameState = useGameStore(state => state.type)
  const setBalance = useCurrencyStore(state => state.setBalance)
  const { fetchBalance, erc20ContractsWS, provider } = useCurrencyContracts()
  const { account } = useWeb3()

  const fetchAndUpdateBalances = useDebouncedCallback(async (addr: string) => {
    setBalance('usdc', (await fetchBalance(addr, 'usdc')) ?? '0')
    setBalance('eth', (await fetchBalance(addr, 'eth')) ?? '0')
    setBalance('bankroll', (await fetchBalance(fareBankrollAddress, 'usdc')) ?? '0')
  }, 250)

  // TODO: We probably should just remove fetching ETH balances due to rate limiting
  const debounceFetchUpdateEthBalance = useDebouncedCallback(
    async (_account: string | undefined) => {
      if (_account) setBalance('eth', (await fetchBalance(_account, 'eth')) ?? '0')
    },
    250
  )

  const debounceFetchUpdateUsdcBalance = useDebouncedCallback(
    async (_account: string | undefined) => {
      if (_account) setBalance('usdc', (await fetchBalance(_account, 'usdc')) ?? '0')
    },
    250
  )

  useEffect(() => {
    if (!erc20ContractsWS.usdc || !provider || !account) return

    const usdcWS = erc20ContractsWS.usdc
    const transferFromFilter = usdcWS.filters.Transfer(account, null, null)
    const transferToFilter = usdcWS.filters.Transfer(null, account, null)

    const transferFromListener = (...args: any[]) => {
      if (isListeningToUSDC.current) {
        console.log('can run from?', isListeningToUSDC.current)
        debounceFetchUpdateUsdcBalance(account)
      }
    }

    const transferToListener = (...args: any[]) => {
      console.log('can run to?', isListeningToUSDC.current)
      if (isListeningToUSDC.current) {
        debounceFetchUpdateUsdcBalance(account)
      }
    }

    // TODO: Need to ensure this isn't running more than once per 50 blocks
    // const blockListener = (...args: any[]) => {
    // console.log(args)
    // debounceFetchUpdateEthBalance(account)
    // }

    // Mount listeners
    // wsProvider.on('block', blockListener)
    usdcWS.on(transferFromFilter, transferFromListener)
    usdcWS.on(transferToFilter, transferToListener)

    // Remove listeners
    return () => {
      // wsProvider.removeListener('block', blockListener)
      usdcWS.removeListener(transferFromFilter, transferFromListener)
      usdcWS.removeListener(transferToFilter, transferToListener)
    }
  }, [erc20ContractsWS.usdc, provider, account])

  useEffect(() => {
    if (account) fetchAndUpdateBalances(account)
  }, [account])

  useEffect(() => {
    if (gameState === 'IDLE') {
      isListeningToUSDC.current = true
    }

    if (gameState === 'START') {
      isListeningToUSDC.current = false
    }

    if (gameState === 'RESOLVE') {
      fetchBalance(account || '', 'usdc')
        .then(balance => {
          queuedUSDCBalance.current = String(balance)
        })
        .catch(console.error)
    }
  }, [gameState])

  entryEvent.useSub('updateBalance', data => {
    const delay = data.detail?.balanceUpdateDelay || 0
    // const deltaAmount = data.detail?.totalDeltaNumber || 0

    setTimeout(() => {
      if (queuedUSDCBalance.current) {
        setBalance('usdc', queuedUSDCBalance.current)
      } else {
        debounceFetchUpdateUsdcBalance(account)
      }

      isListeningToUSDC.current = true
      queuedUSDCBalance.current = ''
    }, delay)
  })
}
