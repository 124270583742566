import { useWeb3 } from '@/hooks/useWeb3'
import {
  USDCContractInterface,
  currencyDecimalsMap,
  fareBankrollAddress,
  getBankrollContract,
  getUsdcContract,
} from '@/lib/crypto'
import { wsProvider } from '@/lib/crypto/provider'
import useCurrencyStore from '@/store/useCurrencyStore'
import { addAppNoti } from '@/store/useNotiStore'
import { BigNumberish, utils } from 'ethers'
import { decodeError } from 'ethers-decode-error'

type FetchableCurrencyType = 'eth' | 'usdc'
type FetchableAllowanceCurrencyType = 'usdc'

// @NOTE: useCurrencyContracts currently only fetches eth or usdc balances.
// This will need to be changed when moving to multicurrency or FARE model
export const useCurrencyContracts = () => {
  // @NOTE: Need to add a fallback provider when provider from useWeb3React isn't available
  // @NOTE: Need to add a fallback provider when provider from useWeb3 isn't available
  const { provider, account, chainId } = useWeb3()
  const { setIsApprovingAllowance, setAllowance } = useCurrencyStore(state => ({
    setIsApprovingAllowance: state.setIsApprovingAllowance,
    setAllowance: state.setAllowance,
  }))
  const bankrollContract = useMemo(
    () => getBankrollContract(fareBankrollAddress, provider),
    [provider, chainId]
  )

  const erc20Contracts = useMemo(
    () => ({
      usdc: getUsdcContract(provider),
    }),
    [provider, chainId]
  )

  const erc20ContractsWS = useMemo(
    () => ({
      usdc: erc20Contracts.usdc && erc20Contracts.usdc.connect(wsProvider),
    }),
    [erc20Contracts]
  )

  const fetchBalance = useCallback(
    async (address: string, currencyType: FetchableCurrencyType) => {
      if (!address) return

      switch (currencyType) {
        case 'eth':
          const ethBalance = await wsProvider.getBalance(address)
          if (!ethBalance) return console.warn('There was an issue fetching ethBalance')
          return utils.formatUnits(ethBalance, currencyDecimalsMap.eth)
        case 'usdc':
          if (!erc20ContractsWS.usdc) return console.warn('PHONEY Contract not defined.')
          const usdcBalance = await erc20ContractsWS.usdc.balanceOf(address)
          if (!usdcBalance) return console.warn('There was an issue fetching usdcBalance')
          return utils.formatUnits(usdcBalance, currencyDecimalsMap.usdc)
        default:
          console.warn('Invalid currency type passed to fetchUpdateBalance')
          break
      }
    },
    [erc20Contracts, erc20ContractsWS, chainId]
  )

  const fetchAllowance = useCallback(
    async (
      ownerAddress: string,
      spenderAddress: string,
      type = 'usdc' as FetchableAllowanceCurrencyType
    ) => {
      const contractWS = erc20ContractsWS[type]
      if (!ownerAddress || !spenderAddress || !contractWS) return '0'

      const bnAllowance = await contractWS.allowance(ownerAddress, fareBankrollAddress)
      if (!bnAllowance) {
        console.warn('There was an issue fetching allowance')
        return '0'
      }

      return utils.formatUnits(bnAllowance, currencyDecimalsMap[type])
    },
    [erc20ContractsWS, chainId]
  )

  const fetchAndSetAllowance = async (...args: Parameters<typeof fetchAllowance>) => {
    try {
      const allowance = await fetchAllowance(...args)
      console.log('allowance', allowance)
      setAllowance('usdc', allowance)
    } catch (err) {
      console.warn(err)
      throw new Error(err as any)
    }
  }

  const approveAllowance = useCallback(
    async (
      spenderAddress: string,
      amount: BigNumberish,
      type = 'usdc' as FetchableAllowanceCurrencyType
    ) => {
      try {
        const contract = erc20Contracts[type]
        if (!contract) throw new Error(`ERC20 contract (${type}) is not defined`)
        if (!account) throw new Error('There was an issue getting the public address')

        setIsApprovingAllowance(true)

        const approveTx = await contract.approve(
          spenderAddress,
          utils.parseUnits(String(amount) || '1000', currencyDecimalsMap[type])
        )

        const receipt = await approveTx.wait()

        return receipt
      } catch (err) {
        // @TODO: Need to display error to user here
        console.error(err)
        // @NOTE: Package found from the following discussion: https://github.com/ethers-io/ethers.js/discussions/3027
        // @NOTE: Still problematic to decode AA related stuff (as expected, because we receive an error from our POST request rather than an error from the geth node), like it will not give the custom error but give something like: "Buffer is not defined"
        // @NOTE: Maybe it might be a good idea to divide eoa and aa error handling?
        const decodedError = decodeError(err, USDCContractInterface)
        console.log('decoded error: ', decodedError)
        addAppNoti({
          msg: decodedError.error,
          type: 'error',
        })
        throw new Error(`Error approving allowance`)
      } finally {
        setIsApprovingAllowance(false)
      }
    },
    [erc20Contracts, setIsApprovingAllowance, account]
  )

  return useMemo(
    () => ({
      provider,
      erc20Contracts,
      erc20ContractsWS,
      fetchBalance,
      fetchAllowance,
      approveAllowance,
      bankrollContract,
      fetchAndSetAllowance,
    }),
    [
      erc20Contracts,
      erc20ContractsWS,
      fetchBalance,
      fetchAllowance,
      provider,
      approveAllowance,
      bankrollContract,
      fetchAndSetAllowance,
    ]
  )
}
