import { FARE_COLORS } from '@/design'
import useNotiStore from '@/store/useNotiStore'
import { AnimatePresence, Variants, motion } from 'framer-motion'

const SAppNoti = styled(motion.div)`
  display: block;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: auto;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin-right: 12px;
`

const SAppNotiBox = styled(motion.div)<{ notiType: 'success' | 'error' }>`
  height: 42px;
  border-radius: 6px;
  border: 1px solid
    ${props => (props.notiType === 'success' ? FARE_COLORS.aqua : FARE_COLORS.salmon)};
  padding-right: 12px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* top: 0px; */
  /* right: 0px; */
  white-space: nowrap;
  span {
    color: rgba(255, 255, 255, 1);
  }
  img {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
`

const appNotiVariants: Variants = {
  initial: {
    opacity: 1,
    x: 200,
  },
  animate: {
    opacity: [1, 1, 0],
    x: 0,
    transition: {
      opacity: {
        duration: 3,
      },
      x: {
        duration: 0.25,
      },
    },
  },
  exit: {
    opacity: 0,
    y: 200,
    transition: {
      duration: 0.2,
    },
  },
}

export const AppNoti = () => {
  const { appNotis, removeAppNoti } = useNotiStore(state => ({
    removeAppNoti: state.removeAppNoti,
    appNotis: state.appNotis,
  }))
  const appNotiElems = useMemo(() => {
    const lastIdx = appNotis.length - 1
    const noti = appNotis[lastIdx]
    if (!noti) return null
    return (
      <SAppNotiBox
        key={noti.id}
        variants={appNotiVariants}
        initial='initial'
        animate='animate'
        exit='exit'
        onAnimationComplete={event => {
          if (event === 'exit') removeAppNoti(noti.id)
        }}
        notiType={noti.type}
      >
        <span>{noti.msg}</span>
        {noti.image && <img src={noti.image} alt='notiImage' />}
      </SAppNotiBox>
    )
  }, [appNotis])

  return (
    <SAppNoti>
      <AnimatePresence mode='popLayout'>{appNotiElems}</AnimatePresence>
    </SAppNoti>
  )
}
