import * as THREE from 'three'
import { useRef } from 'react'
import { Sparkles, useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

const gltfFile = '/glb/coin-animated.glb'
const lossMaterial = new THREE.MeshStandardMaterial({ color: new THREE.Color(0xa9a9a9) })
const lossTextMaterial = new THREE.MeshStandardMaterial({ color: new THREE.Color(0x555555) })
const lossHeadMaterial = new THREE.MeshStandardMaterial({ color: new THREE.Color(0x494949) })

type GLTFResult = GLTF & {
  nodes: {
    CoinGeo: THREE.Mesh
    CoinGeo_1: THREE.Mesh
    Circle045: THREE.Mesh
    Circle045_1: THREE.Mesh
    Circle045_2: THREE.Mesh
    Circle046: THREE.Mesh
    Circle046_1: THREE.Mesh
    Circle046_2: THREE.Mesh
    Circle046_3: THREE.Mesh
    TextHeads: THREE.Mesh
    TextTails: THREE.Mesh
    WinText: THREE.Mesh
    LoseText: THREE.Mesh
  }
  materials: {
    GoldFlat: THREE.MeshPhysicalMaterial
    Gold: THREE.MeshStandardMaterial
    BlackOutlineMat: THREE.MeshStandardMaterial
    AlienHeadMat: THREE.MeshStandardMaterial
    AlienEyesMat: THREE.MeshStandardMaterial
    ['Ship.White.Base']: THREE.MeshPhysicalMaterial
    ['Ship.White.LowLight']: THREE.MeshPhysicalMaterial
    ['Ship.Purple']: THREE.MeshStandardMaterial
    ['GoldFlat.001']: THREE.MeshPhysicalMaterial
    GreenFlat: THREE.MeshStandardMaterial
    RedFlat: THREE.MeshStandardMaterial
  }
}

type ActionName =
  | 'Coin.Gold'
  | 'HeadShake'
  | 'TailShake'
  | 'TextHeads'
  | 'TextTails'
  | 'WinText'
  | 'LoseText'
  | 'Circle.001'
  | 'Coin.002Action'
  | 'Heads.Alien.001'
  | 'Heads.Text.001'
  | 'Tails.Eagle.002'
  | 'Tails.Trim1.002'
  | 'Tails.Trim2.002'
  | 'Coin.001Action'
  | 'Heads.Alien'
  | 'Heads.Text'
  | 'Tails.Eagle.001Action'
  | 'Tails.Trim1.001Action'
  | 'Tails.Trim2.001Action'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export type CoinState = 'idle' | 'hasWon' | 'hasLost' | 'isOverLimit'
type CoinProps = {
  coinState: CoinState
}

export function Coin({ coinState, ...props }: JSX.IntrinsicElements['group'] & CoinProps) {
  const group = useRef<THREE.Group>(null)
  const { nodes, materials } = useGLTF(gltfFile) as GLTFResult

  const mappedMaterials = useMemo(() => {
    const hasLost = coinState === 'hasLost'
    const hasReachedLimit = coinState === 'isOverLimit'

    if (hasReachedLimit) {
      return {
        CoinGeo: lossTextMaterial,
        CoinGeo_1: lossTextMaterial,
        Circle045: lossTextMaterial,
        Circle045_1: lossTextMaterial,
        Circle045_2: lossTextMaterial,
        Circle046: lossTextMaterial,
        Circle046_1: lossTextMaterial,
        Circle046_2: lossTextMaterial,
        Circle046_3: lossTextMaterial,
        TextHeads: lossTextMaterial,
        TextTails: lossTextMaterial,
      }
    }
    return {
      CoinGeo: !hasLost ? materials.GoldFlat : lossMaterial,
      CoinGeo_1: !hasLost ? materials.Gold : lossMaterial,
      Circle045: !hasLost ? materials.BlackOutlineMat : lossHeadMaterial,
      Circle045_1: !hasLost ? materials.AlienHeadMat : lossMaterial,
      Circle045_2: !hasLost ? materials.AlienEyesMat : lossMaterial,
      Circle046: !hasLost ? materials['Ship.White.Base'] : lossMaterial,
      Circle046_1: !hasLost ? materials['Ship.White.LowLight'] : lossMaterial,
      Circle046_2: !hasLost ? materials['Ship.Purple'] : lossHeadMaterial,
      Circle046_3: !hasLost ? materials.BlackOutlineMat : lossHeadMaterial,
      TextHeads: !hasLost ? materials['GoldFlat.001'] : lossTextMaterial,
      TextTails: !hasLost ? materials['GoldFlat.001'] : lossTextMaterial,
    }
  }, [materials, coinState])

  return (
    <group ref={group} {...props} dispose={null}>
      <group
        name='CoinGold'
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[1.81145, 2.74823, 1.81145]}
      >
        <mesh
          name='CoinGeo'
          castShadow
          receiveShadow
          geometry={nodes.CoinGeo.geometry}
          material={mappedMaterials.CoinGeo}
        >
          <Sparkles
            visible={coinState === 'hasWon'}
            // visible
            color={new THREE.Color(0xfff59d)}
            noise={Math.random()}
            count={40}
            scale={1 * 1.8}
            size={16}
            speed={1.5}
          />
        </mesh>
        <mesh
          name='CoinGeo_1'
          castShadow
          receiveShadow
          geometry={nodes.CoinGeo_1.geometry}
          material={mappedMaterials.CoinGeo_1}
        />
        <group
          name='Head'
          rotation={[Math.PI / 2, 0, -Math.PI]}
          scale={[55.20436, 55.20436, 36.3871]}
          visible={coinState !== 'isOverLimit'}
        >
          <mesh
            name='Circle045'
            castShadow
            receiveShadow
            geometry={nodes.Circle045.geometry}
            material={mappedMaterials.Circle045}
          />
          <mesh
            name='Circle045_1'
            castShadow
            receiveShadow
            geometry={nodes.Circle045_1.geometry}
            material={mappedMaterials.Circle045_1}
          />
          <mesh
            name='Circle045_2'
            castShadow
            receiveShadow
            geometry={nodes.Circle045_2.geometry}
            material={mappedMaterials.Circle045_2}
          />
        </group>
        <group
          name='Tails'
          rotation={[Math.PI / 2, 0, -Math.PI]}
          scale={[55.20436, 55.20436, 36.3871]}
          visible={coinState !== 'isOverLimit'}
        >
          <mesh
            name='Circle046'
            castShadow
            receiveShadow
            geometry={nodes.Circle046.geometry}
            material={mappedMaterials.Circle046}
          />
          <mesh
            name='Circle046_1'
            castShadow
            receiveShadow
            geometry={nodes.Circle046_1.geometry}
            material={mappedMaterials.Circle046_1}
          />
          <mesh
            name='Circle046_2'
            castShadow
            receiveShadow
            geometry={nodes.Circle046_2.geometry}
            material={mappedMaterials.Circle046_2}
          />
          <mesh
            name='Circle046_3'
            castShadow
            receiveShadow
            geometry={nodes.Circle046_3.geometry}
            material={mappedMaterials.Circle046_3}
          />
        </group>
        <mesh
          name='TextHeads'
          castShadow
          receiveShadow
          geometry={nodes.TextHeads.geometry}
          material={mappedMaterials.TextHeads}
          position={[0, -0.01112, 0]}
          rotation={[Math.PI, 0.412, 0]}
          scale={[0.20418, 0.13458, 0.20418]}
        />
        <mesh
          name='TextTails'
          castShadow
          receiveShadow
          geometry={nodes.TextTails.geometry}
          material={mappedMaterials.TextTails}
          position={[0, 0.01112, 0]}
          rotation={[Math.PI, -0.412, Math.PI]}
          scale={[0.20418, 0.13458, 0.20418]}
        />
      </group>
      {/* <mesh */}
      {/*   name='WinText' */}
      {/*   castShadow */}
      {/*   receiveShadow */}
      {/*   geometry={nodes.WinText.geometry} */}
      {/*   material={materials.GreenFlat} */}
      {/*   position={[0, 0, 0.27767]} */}
      {/*   rotation={[Math.PI / 2, 0, 0]} */}
      {/* /> */}
      {/* <mesh */}
      {/*   name='LoseText' */}
      {/*   castShadow */}
      {/*   receiveShadow */}
      {/*   geometry={nodes.LoseText.geometry} */}
      {/*   material={materials.RedFlat} */}
      {/*   position={[0.19288, 0, 0.27767]} */}
      {/*   rotation={[Math.PI / 2, 0, 0]} */}
      {/* /> */}
    </group>
  )
}

useGLTF.preload(gltfFile)
