import useLeaderboardStore, { type ILeaderboardArrayData } from '@/store/useLeaderboardStore'
import { HTTP_URL } from '@/constants/http'
import axios from 'axios'
import { faxios } from '@/lib/http/auth'

const useLeaderboard = (shouldRetrieve = false) => {
  const { leaderboard, setLeaderboard } = useLeaderboardStore()

  // @NOTE: Do not request the data from backend everytime you use this hook. Only do it once, by setting `shouldRetrieve` to true in one of the hook usage
  useEffect(() => {
    if (!shouldRetrieve) return
    ;(async () => {
      const {
        data: { leaderboard: receivedLeaderboard },
      } = await faxios({
        method: 'GET',
        url: `${HTTP_URL}/fare-points/leaderboard?stopLimit=500`,
      })
      console.log('received leaderboard: ', receivedLeaderboard)
      setLeaderboard(receivedLeaderboard as ILeaderboardArrayData[])
    })()
  }, [shouldRetrieve, setLeaderboard])

  const requestLeaderboard = async () => {
    const {
      data: { leaderboard },
    } = await faxios({
      method: 'GET',
      url: `${HTTP_URL}/fare-points/leaderboard?stopLimit=500`,
    })
    console.log('received leaderboard: ', leaderboard)
    setLeaderboard(leaderboard as ILeaderboardArrayData[])
  }

  return {
    leaderboard,
    requestLeaderboard,
  }
}

export default useLeaderboard
