import { PageWrapper } from '../style'
import { RPSForm } from '@/components/RPSForm'
import { GamePage } from '..'
import RPSCanvas from '@/components/Canvases/RPSCanvas'
import { SPACING } from '@/design'
import { GameContractListener } from '@/components/shared/Singleton/GameContractListener'
import { RefactorLiveEntries } from '@/components/shared/LiveEntries/RefactorLiveEntries'

export const LeftRPSContainer = styled.div`
  display: grid;
  grid-template-rows: 485px minmax(0, 1fr);
  grid-gap: ${SPACING.md}px;
  grid-row: 1 / span 2;
`

export const RightRPSContainer = styled.div`
  display: grid;
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  grid-auto-rows: auto;
`

export const RPSPage = () => {
  return (
    <PageWrapper>
      <GamePage mode='rps'>
        <LeftRPSContainer>
          <RPSCanvas />
          <RefactorLiveEntries />
        </LeftRPSContainer>
        <RightRPSContainer>
          <RPSForm />
        </RightRPSContainer>
      </GamePage>
      <GameContractListener />
    </PageWrapper>
  )
}
