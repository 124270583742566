import { MotionConfig, type Transition } from 'framer-motion'
import { Canvas, extend } from '@react-three/fiber'
import { MotionCanvas } from 'framer-motion-3d'

import { BREAKPOINTS, BORDER_COLORS, COMPONENTS } from '@/design'
import { CoinScene } from './CoinScene'
import { fareCoinFlipAddress, GameNames } from '@/lib/crypto'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SBorderAnimator } from '../shared/BorderFlash'
import { SVolumeSliderWrapper, VolumeSlider } from '@/components/shared/SoundSystem/VolumeSlider'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'
import BeepAudio from '@/assets/audio/mouse-over-slider.wav'
import LoseAudio from '@/assets/audio/Error 07.wav'
import SingleCoinAudio from '@/assets/audio/coin-flip.wav'
import MultiCoinsAudio from '@/assets/audio/coins/Coins 03.wav'
// import CoinModeSelectClickAudio from '@/assets/audio/mouse-over-slider.wav'
// import CoinModeSelectSingleAudio from '@/assets/audio/coin-flip.wav'
// import CoinModeSelectMultiAudio from '@/assets/audio/coins/coins 03.wav'

// import { GameNoti } from '@/components/shared/GameNoti'

// @TODO: Check if this is needed or not
extend({ MotionCanvas })

const SCoinCanvas = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
  grid-column: 1 / span 5;
  position: relative;
  overflow: hidden;
  z-index: 1;
  /* Vendor prefixes */
  -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
  -moz-backdrop-filter: blur(2px); /* Firefox */
  -ms-backdrop-filter: blur(2px); /* Edge */

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    border: none;
    height: ${COMPONENTS.mobileGameView}px;

    backdrop-filter: blur(0px);
    /* Vendor prefixes */
    -webkit-backdrop-filter: blur(0px); /* Safari and Chrome */
    -moz-backdrop-filter: blur(0px); /* Firefox */
    -ms-backdrop-filter: blur(0px); /* Edge */
  }
`

const defaultTransition: Transition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.35,
}

const GAME_SOUNDS = {
  win: BeepAudio,
  lose: LoseAudio,
  startSingle: SingleCoinAudio,
  startMulti: MultiCoinsAudio,
  coinModeSelectClick: BeepAudio,
  coinModeSelectSingle: SingleCoinAudio,
  coinModeSelectMulti: MultiCoinsAudio,
}

export const CoinFlipCanvas = () => {
  const soundContext = useSound()

  useEffect(() => {
    if (soundContext && typeof soundContext.loadSound === 'function') {
      console.log('DEBUG Loading game sounds in CoinFlipCanvas')
      Object.entries(GAME_SOUNDS).forEach(([key, path]) => {
        soundContext.loadSound(key, path)
      })
    } else {
      console.warn('DEBUG Cannot load sounds: Sound context not available in CoinFlipCanvas')
    }

    // Cleanup function to unload the sounds when the component unmounts
    return () => {
      if (soundContext && typeof soundContext.unloadSound === 'function') {
        console.log('DEBUG Unloading game sounds in CoinFlipCanvas')
        Object.keys(GAME_SOUNDS).forEach(key => {
          soundContext.unloadSound(key)
        })
      }
    }
  }, [soundContext])

  const playGameSound = useCallback(
    (soundName: string, volume = 1, pitch = 1) => {
      if (soundContext && typeof soundContext.playSound === 'function') {
        console.log(`DEBUG Attempting to play sound: ${soundName} with volume: ${volume}`)
        soundContext.playSound(soundName, volume, pitch)
      } else {
        console.warn('DEBUG Cannot play sound: Sound context not available in CoinFlipCanvas')
      }
    },
    [soundContext]
  )
  // useDidIRender('Coin3D')

  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)

  return (
    <SCoinCanvas>
      <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
        <DeltaNumbersDisplay gameName={GameNames.CoinFlip} />
        <MotionConfig transition={defaultTransition}>
          <MotionCanvas
            gl={{
              antialias: true,
              alpha: true,
              stencil: false,
              depth: true,
              // toneMapping: NoToneMapping,
            }}
            dpr={[1, 2]}
            // linear
          >
            <fog attach='fog' args={[0x000000, 5, 65]} />
            {/* <OrbitControls /> */}
            <CoinScene playGameSound={playGameSound} />
          </MotionCanvas>
          <Canvas style={{ display: 'none' }}>
            <group />
          </Canvas>
          <SVolumeSliderWrapper>
            <VolumeSlider />
          </SVolumeSliderWrapper>
        </MotionConfig>
      </SBorderAnimator>
    </SCoinCanvas>
  )
}
