import useUserDataStore from '@/store/useUserDataStore'
import { UserFState, initialUserFState } from '../state'
import type { UserSocket } from '../types'
import { FSpaceBase, IFSpaceBaseOpts } from './FSpace'
import { skulls } from '@/assets/svg/deathmatch-skulls'
import { addAppNoti } from '@/store/useNotiStore'
type UserSpaceOpts = Omit<IFSpaceBaseOpts<UserFState>, 'name' | 'status' | 'state'>

export class UserSpace extends FSpaceBase<UserSocket, UserFState> {
  constructor({ fsocketOpts, authToken }: UserSpaceOpts) {
    super({
      name: 'user',
      state: initialUserFState,
      fsocketOpts,
      authToken,
    })

    this.#mountListeners()

    authToken ? this.connect() : console.log(`No auth token. Not initializing /${this.name}.`)
  }

  #mountListeners() {
    if (this.hasMountedListeners) return
    this.hasMountedListeners = true

    this.io.on('initial_state', initialUserState => {
      this.state.bankrollBalance = initialUserState.bankrollBalance
    })

    // @TODO: Ensure that the same chat message isn't added more than once
    this.io.on('bankroll_balance_updated', newBankrollBalance => {
      this.state.bankrollBalance = newBankrollBalance
    })

    this.io.on('user_level_up', levelUpPayload => {
      const { levelId, levelMultiplier } = levelUpPayload
      useUserDataStore.getState().setLevelMultiplier(levelMultiplier)
      // @TODO: We have the new level id in state.liveEntry.inProgressLiveEntry.levelId. Whenever we identify a level up with levelMultiplier change, render a notification toast as well
      addAppNoti({
        msg: `Congratulations: LEVEL ${levelId}`,
        type: 'success',
        image: (skulls as any)[`skull${levelId}Complete`],
      })
    })
  }
}
