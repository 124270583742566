import { useForm, Controller } from 'react-hook-form'
import CountUp from 'react-countup'
import numeral from 'numeral'

import {
  Form,
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormTab,
  SFormSection,
} from '@/components/shared/Form/style'
import { SVGS } from '@/assets'
import { ModeButton } from '@/components/shared/Button/style'
import { type ISUContractForm } from '@/store/useSUContractStore'
import { CoinFlipSelection } from '@/lib/crypto/coinFlip'
import { clamp, ensureNumber } from '@/utils/math'
import { FareNumberInput } from '@/components/shared/Input/FareNumberInput'
import { useCoinFlipGameState } from '@/store/useGameStateStore'
import { useShallow } from 'zustand/react/shallow'
import useCurrencyStore from '@/store/useCurrencyStore'
import { GameButton } from '@/components/shared/Button/GameButton'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'

import mouseOverSliderAudio from '@/assets/audio/mouse-over-slider.wav'
import coinFlipAudio from '@/assets/audio/coin-flip.wav'
import coins3Audio from '@/assets/audio/coins/Coins 03.wav'

const SInputWrapper = styled.div`
  position: relative;
`

const SForm = styled(Form)`
  position: relative;
  height: 100%;
`

const SDoubleInputRow = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  flex-direction: row;
  transition: 0.12s all ease-in-out;
  ${props =>
    props.$isDisabled &&
    css`
      filter: grayscale(100%) brightness(0.8);
      cursor: not-allowed;
      pointer-events: none;
    `}
`

export const CoinFlipForm = () => {
  const { entry, setEntry } = useCoinFlipGameState(
    useShallow(state => ({
      entry: state.entry,
      setEntry: state.setEntry,
    }))
  )

  const { selectedBalance } = useCurrencyStore(
    useShallow(state => ({
      selectedBalance: state.balances.usdc,
    }))
  )
  const balanceNumber = useMemo(() => Number(selectedBalance) || 0, [selectedBalance])

  const { control, handleSubmit, register, watch, formState, reset } = useForm<ISUContractForm>({
    defaultValues: {
      entryAmount: balanceNumber < 100 ? balanceNumber : 100,
      numberOfEntries: 1,
      stopLoss: 0,
      stopGain: 0,
    },
    mode: 'onChange',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  })
  const formData = watch()

  const { entryAmount, numberOfEntries, stopLoss, stopGain } = formData
  const entryAmountNum = numeral(entryAmount).value() || 0
  const stopLossNum = numeral(stopLoss).value() || 0
  const stopGainNum = numeral(stopGain).value() || 0

  const { loadSound, playSound } = useSound()

  useEffect(() => {
    // loadSound('coinFlipModeClick', 'src/assets/audio/Click 12.wav')
    loadSound('coinFlipModeClick', mouseOverSliderAudio)
    loadSound('coinFlipModeCoinSingle', coinFlipAudio)
    loadSound('coinFlipModeCoinMulti', coins3Audio)
  }, [loadSound])

  // @NOTE: This is a workaround with react-hook-form so that numberOfEntries updates the global state
  // Whenever it is change. We need to move away from react-hook-form
  useEffect(() => {
    const value = clamp(ensureNumber(numberOfEntries), 1, 20) // Clamp so entryCount is between 1 and 20
    // setEntryCount(value)
    setEntry({ entryCount: value })
  }, [numberOfEntries])

  const handleModeButtonClick = useCallback(
    (side: CoinFlipSelection) => {
      setEntry({ side })
      if (side !== entry.side) {
        playSound('coinFlipModeClick', 0.1, 0.3)
        playSound(`coinFlipModeCoin${numberOfEntries > 1 ? 'Multi' : 'Single'}`, 0.2)
      }
    },
    [entry.side, numberOfEntries, playSound, setEntry]
  )

  return (
    <SForm>
      <FormTab $tabs={2}>
        <ModeButton
          $isActive={entry.side === CoinFlipSelection.Heads}
          onClick={() => handleModeButtonClick(CoinFlipSelection.Heads)}
        >
          Heads
        </ModeButton>
        <ModeButton
          $isActive={entry.side === CoinFlipSelection.Tails}
          onClick={() => handleModeButtonClick(CoinFlipSelection.Tails)}
        >
          Tails
        </ModeButton>
      </FormTab>
      <form>
        <SFormSection>
          <FormLabelRow>
            <FormLabel>
              <FormIndicator $isActive={entryAmountNum > 0} />
              <FormAmountLabel>Amount</FormAmountLabel>
            </FormLabel>
            <FormLabel>
              {balanceNumber > 0 && (
                <FormAmountLabel>
                  <CountUp
                    end={balanceNumber}
                    decimals={2}
                    duration={2}
                    separator={','}
                    preserveValue
                  />
                </FormAmountLabel>
              )}
            </FormLabel>
          </FormLabelRow>
          <Controller
            name='entryAmount'
            control={control}
            rules={{ required: true, max: 1_000_000_000, min: 0 }}
            defaultValue={0}
            render={({ field }) => {
              const { ref, ...props } = field
              const value = numeral(field.value).value() || 0
              const isDisabled = (numeral(selectedBalance).value() || 0) === 0
              return (
                <SInputWrapper>
                  <FareNumberInput
                    {...props}
                    // getInputRef={ref}
                    onChange={event => field.onChange(numeral(event.target.value).value() as any)}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    thousandSeparator=','
                    decimalScale={2}
                    hasInputSlider
                    disabled={isDisabled}
                    inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                    inputSliderProps={{
                      value,
                      onChange: sliderValue => field.onChange(sliderValue),
                      min: 0,
                      max: balanceNumber,
                      step: 1,
                    }}
                  />
                </SInputWrapper>
              )
            }}
          />
          {/* {row.error} ERROR SHOULD BE HERE */}
        </SFormSection>
        <SFormSection>
          <FormLabelRow>
            <FormLabel>
              <FormIndicator $isActive={numberOfEntries > 0} />
              <FormAmountLabel>Number of Entries</FormAmountLabel>
            </FormLabel>
            <FormLabel>
              {Number(entryAmount / (numberOfEntries || 1)) > 0 && (
                <FormAmountLabel>
                  <CountUp
                    end={Number(entryAmount / (numberOfEntries || 1))}
                    decimals={2}
                    duration={0.18}
                    separator={','}
                    preserveValue
                  />
                  <span>&nbsp;/ entry</span>
                </FormAmountLabel>
              )}
            </FormLabel>
          </FormLabelRow>
          <Controller
            name='numberOfEntries'
            control={control}
            rules={{
              required: 'Must be 20 or less.',
              max: { message: 'Must be less than 20', value: 20 },
              min: 1,
            }}
            render={({ field }) => {
              const MIN = 1
              const MAX = 20
              const value = numeral(field.value).value()
              const clampedValue = clamp(ensureNumber(value), MIN, MAX)
              const { ref, ...props } = field
              return (
                <FareNumberInput
                  {...props}
                  // getInputRef={ref}
                  onChange={event => field.onChange(ensureNumber(event.currentTarget.value))}
                  onBlur={event => {
                    const val = clamp(ensureNumber(event.currentTarget.value), MIN, MAX)
                    field.onChange(val)
                  }}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  hasInputSlider
                  inputSliderProps={{
                    value: clampedValue,
                    onChange: sliderValue => field.onChange(sliderValue),
                    min: 1,
                    max: 20,
                    step: 1,
                  }}
                />
              )
            }}
          />
        </SFormSection>
        <SDoubleInputRow $isDisabled={numberOfEntries === 1}>
          <SFormSection>
            <FormLabelRow>
              <FormLabel>
                <FormIndicator $isActive={stopLossNum > 0} />
                <FormAmountLabel>Stop Loss</FormAmountLabel>
              </FormLabel>
            </FormLabelRow>
            <Controller
              name='stopLoss'
              control={control}
              defaultValue={0}
              render={({ field: { onChange, ref, ...props } }) => (
                <FareNumberInput
                  {...props}
                  // getInputRef={ref}
                  onChange={event => onChange(event.target.value as any)}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  thousandSeparator=','
                  inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                />
              )}
            />
          </SFormSection>
          <SFormSection>
            <FormLabelRow>
              <FormLabel>
                <FormIndicator $isActive={stopGainNum > 0} />
                <FormAmountLabel>Stop Gain</FormAmountLabel>
              </FormLabel>
            </FormLabelRow>
            <Controller
              name='stopGain'
              control={control}
              defaultValue={0}
              render={({ field: { onChange, ref, ...props } }) => (
                <FareNumberInput
                  {...props}
                  // getInputRef={ref}
                  onChange={event => onChange(event.target.value as any)}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  thousandSeparator=','
                  inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                />
              )}
            />
          </SFormSection>
        </SDoubleInputRow>
        <FormFooter>
          <GameButton
            formData={{ ...formData, side: entry.side }}
            entryAmountNum={entryAmountNum}
          />
        </FormFooter>
      </form>
    </SForm>
  )
}
