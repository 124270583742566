import { Canvas } from '@react-three/fiber'
import { BORDER_COLORS, BREAKPOINTS, COMPONENTS } from '@/design'
import { RPSScene } from './RPSScene'
import { Suspense } from 'react'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { fareRPSAddress, GameNames } from '@/lib/crypto'
import { SBorderAnimator } from '../shared/BorderFlash'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SVolumeSliderWrapper, VolumeSlider } from '@/components/shared/SoundSystem/VolumeSlider'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'

const SRPSCanvas = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
  grid-column: 1 / span 5;
  overflow: hidden;
  /* Vendor prefixes */
  -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
  -moz-backdrop-filter: blur(2px); /* Firefox */
  -ms-backdrop-filter: blur(2px); /* Edge */

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    border: none;
    height: ${COMPONENTS.mobileGameView}px;

    backdrop-filter: blur(0px);
    /* Vendor prefixes */
    -webkit-backdrop-filter: blur(0px); /* Safari and Chrome */
    -moz-backdrop-filter: blur(0px); /* Firefox */
    -ms-backdrop-filter: blur(0px); /* Edge */
  }
`

const SMounting = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  /* Vendor prefixes */
  -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
  -moz-backdrop-filter: blur(2px); /* Firefox */
  -ms-backdrop-filter: blur(2px); /* Edge */
  /* background: black; */
  user-select: none;
`

const RPSCanvas = () => {
  const [isMounted, setIsMounted] = useState(false)

  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)
  /* SFX */
  const { loadSound, playSound } = useSound()

  return (
    <>
      <SRPSCanvas>
        <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
          <DeltaNumbersDisplay gameName={GameNames.RPS} />
          <AnimatePresence>
            <Suspense
              fallback={
                !isMounted && (
                  <SMounting
                    initial={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.6 }}
                  >
                    LOADING RPS...
                  </SMounting>
                )
              }
            >
              <Canvas
                shadows
                dpr={[1, 2]}
                gl={{ antialias: true, stencil: false, preserveDrawingBuffer: false, alpha: true }}
                onCreated={_state => {
                  setTimeout(() => {
                    setIsMounted(true)
                  }, 800)
                }}
              >
                {/* <color attach="background" args={['#000']} /> */}
                <fog attach='fog' args={['black', 5, 15]} />
                <RPSScene loadSound={loadSound} playSound={playSound} />
              </Canvas>
              {/* <Canvas style={{ display: 'none' }}> */}
              {/*   <group /> */}
              {/* </Canvas> */}
            </Suspense>
          </AnimatePresence>
          <SVolumeSliderWrapper>
            <VolumeSlider />
          </SVolumeSliderWrapper>
        </SBorderAnimator>
      </SRPSCanvas>
    </>
  )
}

export default RPSCanvas
