import { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { Bloom, EffectComposer } from '@react-three/postprocessing'
import { Physics } from '@react-three/rapier'

import { DiceScene } from './DiceScene'
import { BREAKPOINTS, BORDER_COLORS, COMPONENTS } from '@/design'
import { useDidIRender } from '@/hooks/common/useDidIRender'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { fareDiceAddress, GameNames } from '@/lib/crypto'

import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SBorderAnimator } from '../shared/BorderFlash'
import { VolumeSlider, SVolumeSliderWrapper } from '@/components/shared/SoundSystem/VolumeSlider'
import { MotionCanvas } from 'framer-motion-3d'

const SDiceCanvas = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
  grid-column: 1 / span 5;
  overflow: hidden;
  position: relative;

  /* Vendor prefixes */
  -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
  -moz-backdrop-filter: blur(2px); /* Firefox */
  -ms-backdrop-filter: blur(2px); /* Edge */

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    border: none;
    height: ${COMPONENTS.mobileGameView}px;
    backdrop-filter: blur(0px);
    /* Vendor prefixes */
    -webkit-backdrop-filter: blur(0px); /* Safari and Chrome */
    -moz-backdrop-filter: blur(0px); /* Firefox */
    -ms-backdrop-filter: blur(0px); /* Edge */
  }
`

interface IDiceCanvas {
  isMounted: boolean
  setIsMounted: (isMounted: boolean) => void
}

function DiceCanvas({ isMounted, setIsMounted }: IDiceCanvas) {
  // const [isMounted, setIsMounted] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const gravityY = -30
  useDidIRender('DiceCanvas')

  const gravity = useMemo(() => [0, gravityY, 0] as [number, number, number], [gravityY])

  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)

  return (
    <SDiceCanvas ref={containerRef} style={{ opacity: isMounted ? 1 : 0 }}>
      <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
        <DeltaNumbersDisplay gameName={GameNames.Dice} />
        <MotionCanvas
          className='dice-canvas'
          ref={canvasRef}
          onCreated={_state => {
            setTimeout(() => {
              setIsMounted(true)
            }, 600)
          }}
          shadows
          dpr={[1, 2]}
          gl={{ antialias: true, stencil: false, preserveDrawingBuffer: false, alpha: true }}
        >
          <EffectComposer disableNormalPass>
            <Bloom luminanceThreshold={1.35} luminanceSmoothing={0.8} intensity={1} />
          </EffectComposer>
          <fog attach='fog' args={[0x090909, 5, 15]} />
          <Suspense fallback={null}>
            <Physics debug={false} gravity={gravity}>
              <DiceScene />
            </Physics>
          </Suspense>
        </MotionCanvas>
        <Canvas style={{ display: 'none' }}>
          <group />
        </Canvas>
        <SVolumeSliderWrapper>
          <VolumeSlider />
        </SVolumeSliderWrapper>
      </SBorderAnimator>
    </SDiceCanvas>
  )
}

export default DiceCanvas
