import { type HelperFunctions } from './contracts'

export const isValidDiceSide = (side: number) => {
  return side >= 500 || side <= 9900
}

export const getDiceMultiplierWithoutPPVFromSide = (side: number) => {
  return 10000 / (10000 - side)
}

export const getDiceMultiplierWithPPVFromSide = (side: number) => {
  return getDiceMultiplierWithoutPPVFromSide(side) * 0.99
}

// @NOTE: https://en.wikipedia.org/wiki/Kelly_criterion
export const getDiceKellyFractionFromSide = (side: number) => {
  const pWin = (10000 - side) / 10000
  const pLose = 1 - pWin
  const aLose = 1
  const aWin = getDiceMultiplierWithPPVFromSide(side) - 1
  const kellyFraction = pWin / aLose - pLose / aWin
  return kellyFraction
}

export const getDicePotentialProfitCoefficientFromSide = (side: number) => {
  return getDiceMultiplierWithPPVFromSide(side) - 1
}

export const diceHelperFunctions: HelperFunctions = {
  isValidSide: isValidDiceSide,
  getMultiplierWithoutPPVFromSide: getDiceMultiplierWithoutPPVFromSide,
  getMultiplierWithPPVFromSide: getDiceMultiplierWithPPVFromSide,
  getKellyFractionFromSide: getDiceKellyFractionFromSide,
  getPotentialProfitCoefficientFromSide: getDicePotentialProfitCoefficientFromSide,
}
