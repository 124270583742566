import { FSOCKET_URL as url, TEST_FSOCKET_AUTH_TOKEN } from './constants'

// @NOTE: Temporary leaving out class exports
// @NOTE: Whenever we create a monorepo in the frontend we will separate out
// export { FSocketInstance } from './FSocketInstance'
// export { FSpace } from './space'

// Initialize single fsocket instance
import { FSocketInstance } from './FSocketInstance'
// export const fsocket = new FSocketInstance({ url, authToken: TEST_FSOCKET_AUTH_TOKEN })
export const fsocket = new FSocketInstance({ url })

export * from './constants'
