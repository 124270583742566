import { PageWrapper } from '../style'
import { GamePage } from '..'
import { DiceForm } from '@/components/DiceForm'
import DiceCanvas from '@/components/Canvases/DiceCanvas'
import { GameContractListener } from '@/components/shared/Singleton/GameContractListener'
import { SPACING } from '@/design'
import { RefactorLiveEntries } from '@/components/shared/LiveEntries/RefactorLiveEntries'
import { LoadingChip } from '@/components/shared/LoadingChip'

export const LeftDiceContainer = styled.div`
  display: grid;
  grid-template-rows: 485px minmax(0, 1fr);
  grid-gap: ${SPACING.md}px;
  grid-row: 1 / span 2;
`

export const RightDiceContainer = styled.div`
  display: grid;
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  grid-auto-rows: auto;
`

export const DiceLoadingChip = styled(LoadingChip)`
  position: absolute;
  z-index: 999;
`

export const DicePage = () => {
  const [isMounted, setIsMounted] = useState(false)

  return (
    <PageWrapper>
      {!isMounted && <DiceLoadingChip width={48} height={48} />}
      <GamePage mode={'dice'}>
        <LeftDiceContainer>
          <DiceCanvas isMounted={isMounted} setIsMounted={setIsMounted} />
          {isMounted && <RefactorLiveEntries />}
        </LeftDiceContainer>
        <RightDiceContainer>{isMounted && <DiceForm />}</RightDiceContainer>
      </GamePage>
      <GameContractListener />
    </PageWrapper>
  )
}
