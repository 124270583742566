import { PageWrapper } from '../style'
import { SPACING } from '@/design'
import { GamePage } from '..'
import { CoinFlipForm } from './CoinFlipForm'
import { CoinFlipCanvas } from '@/components/Canvases/CoinFlipCanvas'
import { useDidIRender } from '@/hooks/common/useDidIRender'
import { GameContractListener } from '@/components/shared/Singleton/GameContractListener'
import { RefactorLiveEntries } from '@/components/shared/LiveEntries/RefactorLiveEntries'

export const LeftCoinFlipContainer = styled.div`
  display: grid;
  grid-template-rows: 485px minmax(0, 1fr);
  grid-gap: ${SPACING.md}px;
  grid-row: 1 / span 2;
`

export const RightCoinFlipContainer = styled.div`
  display: grid;
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  grid-auto-rows: auto;
`

export const CoinFlipPage = () => {
  useDidIRender('CoinFlipPage')

  return (
    <PageWrapper>
      <GamePage mode='flip'>
        <LeftCoinFlipContainer>
          <CoinFlipCanvas />
          <RefactorLiveEntries />
        </LeftCoinFlipContainer>
        <RightCoinFlipContainer>
          <CoinFlipForm />
        </RightCoinFlipContainer>
      </GamePage>
      <GameContractListener />
    </PageWrapper>
  )
}
