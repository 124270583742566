import Modal from 'react-modal'

import { BORDER_COLORS } from '@/design/colors'
import { SVGS } from '@/assets'
import { ActionColumn, ContentColumn, ModalContent, ModalFooter, ModalHeader } from './style'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'

Modal.setAppElement('#root')

export interface IBaseModalProps {
  show?: boolean
  setShow: (show: boolean) => void
  width?: number
  maxWidth?: number
  height?: number
  title?: JSX.Element | string
  content?: JSX.Element | string
  footer?: JSX.Element | string
  error?: string
  noPadding?: boolean
  isCloseable?: boolean
  hideX?: boolean
  centerHeader?: boolean
}

export const BaseModal = ({
  show,
  width,
  maxWidth,
  height,
  setShow,
  title,
  content,
  footer,
  error,
  noPadding,
  hideX,
  centerHeader,
  isCloseable = true,
}: IBaseModalProps) => {
  const modalContentRef = createRef<HTMLDivElement>()
  const isMobileScreen = useIsBreakpoint('sm')

  return (
    <Modal
      isOpen={Boolean(show)}
      onRequestClose={isCloseable ? () => setShow(false) : () => {}}
      closeTimeoutMS={200}
      style={{
        content:
          isMobileScreen ?
            {
              height: height ?? 'fit-content',
              margin: 'auto',
              background: '#00000090',
              padding: 0,
              border: `1px solid ${BORDER_COLORS.one}`,
            }
          : {
              height: height ?? 'fit-content',
              width: width ?? 'fit-content',
              minWidth: 240,
              maxWidth: maxWidth ?? 450,
              margin: 'auto',
              background: '#00000090',
              padding: 0,
              border: `1px solid ${BORDER_COLORS.one}`,
            },
        overlay:
          isCloseable ?
            {
              backgroundColor: '#00000080',
              backdropFilter: 'blur(3px)',
              zIndex: 999,
            }
          : { backgroundColor: 'transparent' },
      }}
    >
      <ModalHeader>
        <ContentColumn className={centerHeader ? 'header-center-title' : ''}>{title}</ContentColumn>
        {isCloseable && !hideX && (
          <ActionColumn>
            <button type='button' onClick={() => setShow?.(false)}>
              <img src={SVGS.xMarkIcon} />
            </button>
          </ActionColumn>
        )}
      </ModalHeader>
      <ModalContent
        style={noPadding ? { padding: 0 } : { padding: 12, paddingTop: 0 }}
        ref={modalContentRef}
      >
        {content}
      </ModalContent>
      {footer ?
        <ModalFooter>{footer}</ModalFooter>
      : null}
    </Modal>
  )
}
